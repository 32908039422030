import { set } from "lodash";
import * as React from "react";
import { FormInstance } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { CheckCircle, ChevronRight, Search, X } from "react-feather";

import { Menu } from "fetchers/menu";
import Card from "components/common/Card";
import Field from "components/form/Field";
import Button from "components/form/Button";
import Drawer from "components/common/Drawer";
import Avatar from "components/common/Avatar";
import Checkbox from "components/form/Checkbox";
import { Item as ItemType, Item } from "fetchers/item";
import { formatCurrency } from "utils/currency_utils";
import { getImage } from "utils/image_utils";
import {
  ADD_PRODUCT_DRAWER_TITLE,
  BUTTON_SAVE,
  PLACEHOLDER_SEARCH_PRODUCT,
  WARNING_MAX_NUM_OF_HIGHLIGHT_PRODUCTS,
} from "constants/strings";
import message from "components/common/message";
import { useItemsStore } from "stores/itemsStore";
import { UiState } from "services/api";
import { getItems } from "services/itemApi";

type Props = {
  visible: boolean;
  closeDrawer?: () => void;
  onItemSelect?: (item: Item) => void;
  form: FormInstance;
  menuItemPath: (string | number)[];
  maxNumOfItems?: number;
  onItemsUpdated?: () => void;
};

const AddItemDrawer: React.FC<Props> = (props) => {
  const currentMenuItems = props.form.getFieldValue(props.menuItemPath);

  const [state, actions] = useItemsStore();
  const [uiState, setUiState] = React.useState<UiState<Item>>({ isLoading: false });

  const [selectedItems, setSelectedItems] = React.useState<ItemType[]>(currentMenuItems || []);
  const [query, setQuery] = React.useState("");

  React.useEffect(() => {
    setUiState({ isLoading: true });
    getItems(props.form.getFieldValue("venueId"))
      .then((response) => {
        setUiState({ isLoading: false });
        actions.updateItems && actions.updateItems(response.data);
      })
      .catch((err) => {
        console.error(err);
        setUiState({
          isLoading: false,
          error: err.message,
        });
      });
  }, []);

  const handleSelectedItem = (e: CheckboxChangeEvent, id: number | string) => {
    if (!state.items) {
      return;
    }

    if (e.target.checked) {
      if (props.maxNumOfItems && selectedItems.length == props.maxNumOfItems) {
        message({
          type: "warning",
          content: WARNING_MAX_NUM_OF_HIGHLIGHT_PRODUCTS,
          progressBar: false,
          duration: 3,
          key: 1,
        });
      } else {
        const item = state.items.find((item) => item.id == id);
        if (item) {
          setSelectedItems((items) => [...items, { ...item }]);
        }
      }
    } else {
      const item = state.items.find((item) => item.id == id);
      if (item) {
        setSelectedItems((items) => items.filter((i) => i.id != item.id));
      }
    }
  };

  const handleAddItem = () => {
    const formFields: Menu = props.form.getFieldsValue(true);

    set(formFields, props.menuItemPath, selectedItems);

    props.form.setFieldsValue({
      ...formFields,
    });

    props.closeDrawer && props.closeDrawer();
    props.onItemsUpdated && props.onItemsUpdated();
  };

  return (
    <Drawer
      destroyOnClose
      style={{ transform: "none !important" }}
      height="100vh"
      width="35%"
      placement="right"
      title={ADD_PRODUCT_DRAWER_TITLE}
      visible={props.visible}
      onClose={() => {
        setSelectedItems(currentMenuItems || []);
        props.closeDrawer && props.closeDrawer();
      }}
      footer={
        <div className="flex justify-end mt-auto">
          <Button size="large" color="black" onClick={handleAddItem}>
            {BUTTON_SAVE}
            <CheckCircle size={24} className="ml-10" />
          </Button>
        </div>
      }
    >
      <div className="px-7 py-5 min-h-full flex flex-col">
        <div>
          <Field
            name="name"
            variant="curved"
            value={query}
            prefix={<Search />}
            suffix={<X className="cursor-pointer" onClick={() => setQuery("")} />}
            placeholder={PLACEHOLDER_SEARCH_PRODUCT}
            className="mt-0 mb-9"
            onChange={(event) => setQuery(event.target.value)}
          />
          {uiState.isLoading && <p>Loading...</p>}
          {uiState.error && <p>Error fetching items: {uiState.error}</p>}
          {!uiState.isLoading && state.items?.length
            ? state.items
                .filter((item) => item.title.toLowerCase().includes(query.toLowerCase()))
                .map((item: ItemType) => (
                  <div className="flex" key={item.id}>
                    <div className="flex items-center mr-5">
                      <Checkbox
                        id={item.id}
                        checked={!!selectedItems?.find((selectedItem) => selectedItem.id == item.id)}
                        className="leading-0"
                        onChange={(e) => handleSelectedItem(e, item.id)}
                      />
                    </div>
                    <Card
                      variant="large"
                      className="mb-2 h-20 flex overflow-hidden cursor-pointer hover:shadow-s flex-1"
                      onClick={() => props.onItemSelect && props.onItemSelect(item)}
                    >
                      <Avatar className="flex" imgClassName="object-cover" src={getImage(item.imageUri)} size="large" />
                      <div className="flex flex-1 flex-col ml-5 py-2 text-sm pr-4">
                        <span className="text-base font-bold">{item.title}</span>
                        <div className="font-bold">{formatCurrency(item.price)}</div>
                      </div>
                      <div className="w-14 h-full flex items-center justify-center">
                        <ChevronRight />
                      </div>
                    </Card>
                  </div>
                ))
            : !uiState.isLoading && !uiState.error && <p>There are no items for this venue.</p>}
        </div>
      </div>
    </Drawer>
  );
};

export default AddItemDrawer;
