import * as React from "react";
import cx from "classnames";
import { Square, Check, MinusSquare } from "react-feather";
import { Checkbox as AntCheckbox, CheckboxProps } from "antd";

type Props = CheckboxProps & {
  size?: number;
};

const Checkbox: React.FC<Props> = (props: Props) => {
  const getIcon = () => {
    if (props.indeterminate) {
      return <MinusSquare size={props.size} className="indeterminate" />;
    }

    return (
      <span className="relative inline-flex">
        <Square size={props.size} className={cx("square")} />
        <Check
          className="check-mark"
          size={props.size ? props.size - 10 : 14}
        />
      </span>
    );
  };

  return (
    <AntCheckbox className={cx(props.className)} {...props}>
      <span className="flex items-center">
        {getIcon()}
        {props.children}
      </span>
    </AntCheckbox>
  );
};

Checkbox.defaultProps = {
  size: 24,
};

export default Checkbox;
