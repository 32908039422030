import * as React from "react";
import { Progress as AntProgress } from "antd";

export type Props = {
  duration: number | null;
  onFinished?: () => void;
  pause?: boolean;
};

const Progress: React.FC<Props> = (props) => {
  const duration = props.duration != null || props.duration != undefined ? props.duration : 5000;
  const [progressBarWidth, setProgressBarWidth] = React.useState<number>(0);
  const remaining = React.useRef<number>(0);
  const timerId = React.useRef<number | null>(null);
  const interval = duration / 100; // 1 percent of duration

  React.useEffect(() => {
    if (duration < 1) {
      return;
    }

    remaining.current = duration ? duration : 5000;
    setInterval();

    return () => {
      clearInterval();
    };
  }, []);

  React.useEffect(() => {
    if (!timerId.current) {
      return;
    }

    clearInterval();

    if (!isFinished() && !props.pause) {
      setInterval();
    }
  }, [props.pause]);

  const setInterval = () => {
    timerId.current = window.setInterval(() => {
      remaining.current -= interval;
      setProgressBarWidth((state) => state + 1);

      if (isFinished()) {
        clearInterval();

        if (props.onFinished) {
          props.onFinished();
        }
      }
    }, interval);
  };

  const isFinished = () => {
    return remaining.current <= 0;
  };

  const clearInterval = () => {
    timerId.current && window.clearInterval(timerId.current);
  };

  return <AntProgress percent={progressBarWidth} showInfo={false} className="custom-progress" />;
};

export default Progress;
