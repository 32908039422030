import * as React from "react";

function SvgLogoIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" {...props}>
      <path
        d="M33.035 0h-18.07C6.692 0 0 6.692 0 14.965v18.07C0 41.308 6.692 48 14.965 48h18.07C41.308 48 48 41.308 48 33.035v-18.07C48 6.692 41.308 0 33.035 0zm.537 35.435h-4.038v-7.34c0-.82-.226-1.328-.847-1.78-.48-.338-1.13-.423-1.892-.452h-8.3v9.572H14.4v-22.87h4.066v9.6h8.301c2.202 0 3.953.65 5.082 1.694 1.13 1.073 1.666 2.513 1.666 4.348v7.228h.057zm.028-13.327c-.96-.282-1.75-.734-2.344-1.327-1.129-1.073-1.665-2.513-1.665-4.348v-3.868H33.6v9.543z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgLogoIcon;
