import cx from "classnames";
import * as React from "react";

import Icon from "../icons/Icon";
import { NavLink } from "react-router-dom";
import SIDE_BAR_LINKS from "../../data/SIDE_BAR";

type Props = {
  onToggleSidebar: () => void;
  hidden: boolean;
};

const SideBar: React.FC<Props> = (props) => {
  const [hideText, setHideText] = React.useState(false);

  // const [showUserSettings, setShowUserSettings] = React.useState<boolean>(false);

  return (
    <aside
      className={cx("relative md:h-auto", {
        "h-screen": !props.hidden,
        "hidden md:block": props.hidden,
      })}
    >
      <div
        className={cx("sidebar h-screen bg-primary-white p-4 z-40 shadow-m-10 overflow-x-scroll md:overflow-auto", {
          "w-screen md:w-auto": !props.hidden,
          "hidden md:block": props.hidden,
          "md:pr-10": !hideText,
        })}
      >
        <div className="flex items-center">
          <Icon name="LogoIcon" className="text-5xl" />
          <Icon name="LogoTextIcon" wrapper={false} className={cx("ml-5 text-2xl w-24", { "md:hidden": hideText })} />
          <Icon
            name="CrossIcon"
            className="w-8 h-8 text-black text-lg ml-auto md:hidden"
            onClick={() => props.onToggleSidebar()}
          />
        </div>

        <nav className="mt-24 flex flex-col items-start pb-21 px-2 whitespace-nowrap">
          {SIDE_BAR_LINKS.map((link, index) => {
            return (
              <NavLink
                exact
                key={index}
                to={link.to}
                className="link group"
                activeClassName="active"
                title={link.text}
                onClick={() => props.onToggleSidebar()}
              >
                <Icon name={link.icon} className="icon" />
                <span className={cx("ml-6", { "md:hidden": hideText })}>{link.text}</span>
              </NavLink>
            );
          })}
        </nav>
        <span
          onClick={() => setHideText(!hideText)}
          className={cx(
            "icon",
            "absolute bottom-0 mb-5 ml-2 hidden md:flex rounded-lg",
            "items-center justify-center cursor-pointer"
          )}
        >
          <Icon name={hideText ? "ArrowsRightIcon" : "ArrowsLeftIcon"} wrapper={false} />
        </span>
      </div>

      {/* <span
        className={cx("absolute bg-primary-white bottom-0 md:hidden", "pb-4 px-4 w-screen mobile-avatar-drawer z-50", {
          show: showUserSettings,
        })}
      >
        <DrawerUserSettings onUserDetailsClick={() => setShowUserSettings(!showUserSettings)} />
      </span> */}
    </aside>
  );
};

export default SideBar;
