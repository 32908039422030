import { AxiosResponse, Method } from "axios";
import { Menu } from "fetchers/menu";
import { VENUE_MENUS, VENUE_MENUS_DELETE } from "../constants/endpoints";
import api from "./api";

export const sortAlphabetical = (menus: Menu[]): Menu[] => {
  return menus.sort((a, b) => a.name.localeCompare(b.name));
};

export const getMenus = async (id: string | number): Promise<AxiosResponse<Menu[]>> => {
  return await api({
    url: VENUE_MENUS(id),
    method: "GET",
  }).then((response) => {
    return {
      ...response,
      data: sortAlphabetical(response.data),
    };
  });
};

export const saveMenu = async (endpoint: string, method: Method, data?: Menu): Promise<AxiosResponse<Menu[] | []>> => {
  return await api({
    url: endpoint,
    method: method,
    data: { ...data, status: "Draft" },
  });
};

export const deleteMenu = (menu: Menu): Promise<AxiosResponse> => {
  if (!menu?.venueId || !menu?.id) {
    throw new Error("Missing Venue ID or Menu Id. ");
  }

  return api({
    url: VENUE_MENUS_DELETE(menu.venueId, menu.id),
    method: "DELETE",
  });
};
