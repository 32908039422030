import * as React from "react";

import Title from "components/common/Title";
import InfoTooltip from "components/common/InfoTooltip";
import NoAppSections from "./NoAppSections";
import AppSectionItems from "./AppSectionItems";
import { AppHomeScreenSections, Section } from "fetchers/app-home-screen";
import { getImage } from "utils/image_utils";
import { Venue } from "fetchers/venue";
import Thumbnail from "components/common/Thumbnail";
import { VENUE_IMAGE } from "constants/endpoints";
import { UiState } from "services/api";
import Delayed from "components/Delayed";
import { Sections } from "../AvailableSections/AvailableSectionItems";
import { ASPECT_RATION_LANDSCAPE } from "constants/ints";
import { TOOLTIP_APP_HOMESCREEN } from "constants/strings";

type Props = {
  venue?: Venue;
  dragAndDropSectionId: string;
  state?: UiState<AppHomeScreenSections>;
  isLoading?: boolean;
  error?: string;
  sections: Sections;
  onSectionClick: (section: Section) => void;
};

const AppHomeLayout: React.FC<Props> = (props) => {
  return (
    <div>
      <Title size="large" className="mb-2.5">
        App homepage layout
        <InfoTooltip className="ml-2" title={TOOLTIP_APP_HOMESCREEN} />
      </Title>
      <div className="shadow-s-10 border-black-op-30 border-4 rounded-3xl p-4 overflow-x-auto h-65vh">
        {props.isLoading && (
          <Delayed>
            <div>Loading...</div>
          </Delayed>
        )}
        {props.error && <div>{props.error}</div>}
        {props.venue ? (
          <>
            <div className="rounded-2.5xl mb-2.5 relative  overflow-hidden">
              <Thumbnail
                aspectRatio={ASPECT_RATION_LANDSCAPE}
                imageUri={getImage(props.venue?.imageUri, "/img/image_placeholder_landscape.jpg")}
                key={props.venue?.id}
                imgClassName="venue-image"
                actionEndpoint={VENUE_IMAGE(props.venue?.id ?? " ")}
              />
            </div>

            <AppSectionItems
              dragAndDropSectionId={props.dragAndDropSectionId}
              sections={props.sections}
              sortItemClassName="mb-1"
              emptyData={<NoAppSections />}
              onSectionClick={props.onSectionClick}
            />
          </>
        ) : (
          <p>Please select a venue first</p>
        )}
      </div>
    </div>
  );
};

export default AppHomeLayout;
