import * as React from "react";
import cx from "classnames";

type Props = {
  children: React.ReactNode;
  className?: string;
  wrapperClassName?: string;
  variant?: "default" | "large";
  onClick?: () => void;
};
const Card: React.FC<Props> = (props) => {
  return (
    <div
      className={cx(
        "bg-primary-white",
        { "rounded-2lg shadow-s-10 mx-4 md:mx-0": props.variant === "large" },
        { "rounded-xl shadow-m-10 p-3 mx-4 md:mx-0": props.variant === "default" },
        props.className
      )}
      onClick={(e) => {
        e.stopPropagation();
        props.onClick && props.onClick();
      }}
    >
      {props.children}
    </div>
  );
};

Card.defaultProps = {
  variant: "default",
};

export default Card;
