import * as React from "react";

function SvgDotsIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="1em" height="1em" viewBox="0 0 4 17" fill="none" {...props}>
      <path
        d="M2 9.333a.833.833 0 100-1.666.833.833 0 000 1.666zM2 3.5a.833.833 0 100-1.667A.833.833 0 002 3.5zM2 15.167A.833.833 0 102 13.5a.833.833 0 000 1.667z"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgDotsIcon;
