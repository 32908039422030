import * as React from "react";
import { CheckCircle } from "react-feather";

import Button from "../../../components/form/Button";
import ToggleButton from "../../../components/common/ToggleButton";
import Tabs, { TabPane } from "../../../components/common/Tabs";

const Buttons: React.FC = () => {
  return (
    <>
      <section className="shadow rounded bg-primary-white my-4 py-5 px-4">
        <h1 className="font-bold text-2xl mb-3">Buttons</h1>
        <div className="flex">
          <div className="w-1/2">
            <h2 className="font-bold text-xl mb-2">Button Black Text</h2>
            <h3 className="font-bold text-l mb-4">
              Color, text and icon are always a variable
            </h3>
            <div className="flex mb-2">
              <div className="w-3/12" />
              <div className="w-3/12">Default</div>
              <div className="w-3/12">Disabled</div>
              <div className="w-3/12">Hover</div>
            </div>

            <div className="flex">
              <div className="w-3/12 inline-flex">
                <div className="w-1/3">L</div>
                <div className="w-2/3">
                  <div>Right icon</div>
                  <div>Left icon</div>
                </div>
              </div>
              <div className="w-3/12 mb-2">
                <Button size="large">
                  Publish
                  <CheckCircle size={24} className="ml-10" />
                </Button>
              </div>
              <div className="w-3/12">
                <Button size="large" disabled>
                  Publish
                  <CheckCircle size={24} className="ml-10" />
                </Button>
              </div>
              <div className="w-3/12">
                <Button size="large" className="shadow-m border-primary-white">
                  Publish
                  <CheckCircle size={24} className="ml-10" />
                </Button>
              </div>
            </div>
          </div>
        </div>

        <ToggleButton
          defaultChecked={true}
          checkedChildren={<CheckCircle size={20} />}
          unCheckedChildren={<CheckCircle size={20} />}
        />
        <ToggleButton
          type="light"
          checkedChildren={<CheckCircle size={20} />}
          unCheckedChildren={<CheckCircle size={20} />}
          disabled
        />
        <ToggleButton
          type="transparent-dark"
          checkedChildren={<CheckCircle size={20} />}
          unCheckedChildren={<CheckCircle size={20} />}
          disabled
        />
        <ToggleButton
          type="transparent-light"
          checkedChildren={<CheckCircle size={20} />}
          unCheckedChildren={<CheckCircle size={20} />}
          disabled
        />

        <div className="flex mt-4">
          <div className="w-1/2">
            <h2 className="font-bold text-xl mb-2">Tabs Black</h2>
            <h3 className="font-bold text-l mb-4">
              Text copy always a variable
            </h3>
            <Tabs defaultActiveKey="2">
              <TabPane tab="Default" key={1}>
                Default
              </TabPane>
              <TabPane tab="Selected" key={2}>
                Selected
              </TabPane>
              <TabPane tab="Hover" key={3}>
                Hover
              </TabPane>
              <TabPane disabled tab="Disabled" key={4}>
                Disabled
              </TabPane>
            </Tabs>
          </div>
          <div className="w-1/2"></div>
        </div>
      </section>
    </>
  );
};

export default Buttons;
