import * as React from "react";
import { Form as AntForm, FormInstance } from "antd";

import Settings from "./Settings";
import NoBannerTips from "../NoBannerTips";
import Structure from "./Structure";
import { Banner } from "fetchers/banner";

type Props = {
  banner?: Banner;
  isNew?: boolean;
  form: FormInstance<any>;
  venueId?: string;
  closeDrawer?: () => void;
  onDelete?: () => void;
  onSave?: (data: Banner & { venueId: string }) => void;
};

const BannerSectionForm: React.FC<Props> = (props) => {
  const { form } = props;

  const initialValues = {
    ...(props.banner as Banner),
  };

  return (
    <>
      <AntForm
        className="bg-primary-white flex-1 px-8 pt-8 flex flex-col h-full"
        form={form}
        onFinish={props.onSave}
        initialValues={initialValues}
      >
        <Settings isNew={props.isNew} venueId={props.venueId} />
        {/* TODO: hide the banner temporary for preventing eslint error */}
        {false && <NoBannerTips />}
        <Structure form={form} />
      </AntForm>
    </>
  );
};

export default BannerSectionForm;
