import * as React from "react";

function SvgArrowsRightIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="1em" height="1em" viewBox="0 0 19 17" fill="none" {...props}>
      <path
        d="M10.917 15.167L18 8.083 10.917 1M1 15.167l7.083-7.084L1 1"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgArrowsRightIcon;
