import * as React from "react";

function SvgInvisibleIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M8.25 3.533a7.6 7.6 0 011.75-.2c5.833 0 9.167 6.667 9.167 6.667a15.411 15.411 0 01-1.8 2.658m-5.6-.891a2.5 2.5 0 11-3.534-3.534m-7.4-7.4l18.334 18.334M14.95 14.95A8.391 8.391 0 0110 16.667C4.167 16.667.833 10 .833 10A15.375 15.375 0 015.05 5.05l9.9 9.9z"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgInvisibleIcon;
