import { Form } from "antd";
import * as React from "react";

import Field from "components/form/Field";
import Select from "components/form/Select";
import { EMPTY_FIELD_ERROR, LABEL_MENU_TITLE, LABEL_MENU_TYPE } from "constants/strings";
import FormItemLabel from "components/common/FormItemLabel";
import VenueSelect from "components/form/VenueSelect";

const MenuTypeOptions = [
  {
    label: "Standard",
    value: "Standard",
  },
  {
    label: (
      <>
        Course <span className="text-8px text-black">SOON</span>
      </>
    ),
    value: "Course",
  },
  {
    value: "Fixed price",
    label: (
      <>
        Fixed price <span className="text-8px text-black">SOON</span>
      </>
    ),
  },
  {
    value: "Hero",
    label: (
      <>
        Hero <span className="text-8px text-black">SOON</span>
      </>
    ),
  },
];

type Props = {
  isNew?: boolean;
  venueId?: string;
  isVenueSelectionDisabled: boolean;
};

const Settings: React.FC<Props> = (props) => {
  return (
    <div className="grid lg:grid-cols-2 xl:grid-cols-3 lg:gap-6">
      <div>
        <Form.Item
          name="name"
          rules={[{ required: true, message: EMPTY_FIELD_ERROR }]}
          label={<FormItemLabel>{LABEL_MENU_TITLE}</FormItemLabel>}
        >
          <Field className="border-0 max-w-full mb-0" type="text" placeholder="Type something..." />
        </Form.Item>
      </div>
      <div>
        <VenueSelect disabled={props.isVenueSelectionDisabled} venueId={props?.venueId} />
      </div>
      <div>
        <Form.Item
          name="type"
          initialValue={MenuTypeOptions[0].value}
          rules={[{ required: true, message: EMPTY_FIELD_ERROR }]}
          label={<FormItemLabel>{LABEL_MENU_TYPE}</FormItemLabel>}
        >
          <Select placeholder="Select a type" options={MenuTypeOptions} className="max-w-full" />
        </Form.Item>
      </div>

      {/* @NOT_MVP */}
      {/* <div>
        <Title size="large" className="mb-2">
          Availability <InfoTooltip className="ml-1" title="some text.." />
        </Title>
        <div className="mb-3 pt-0">
          <div className="rounded-3xl bg-gray-100 inline-flex overflow-hidden">
            <span className="rounded-3xl px-4 py-2 bg-teal-200">Always</span>
            <span className="rounded-3xl px-4 py-2 text-gray-400">Every day</span>
            <span className="rounded-3xl px-4 py-2 text-gray-400">Every week</span>
            <span className="rounded-3xl px-4 py-2 text-gray-400">Every month</span>
            <span className="rounded-3xl px-4 py-2 text-gray-400">Custom</span>
          </div>
        </div>
      </div> */}
    </div>
  );
};

Settings.defaultProps = {
  isNew: false,
};

export default Settings;
