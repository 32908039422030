import * as React from "react";

import Bubbles from "./Bubbles";
import Statuses from "./Statuses";

const Elements: React.FC = () => {
  return (
    <>
      <section className="shadow rounded bg-primary-white my-4 py-5 px-4">
        <h1 className="font-bold text-2xl mb-3">Elements</h1>
        <Bubbles />
        <Statuses />
      </section>
    </>
  );
};

export default Elements;
