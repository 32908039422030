import { Form as AntForm, FormInstance } from "antd";
import * as React from "react";

import ModifiersCard from "./Modifiers";
import MainDetails from "./MainDetails";
import DietaryCard from "./DietaryCard";
import SpiceManager from "./SpiceManager";
import AllergensCard from "./AllergensCard";
import { Item } from "fetchers/item";

type Props = {
  item?: Item;
  form: FormInstance<any>;
  venueId?: string | null;
  isNew?: boolean;
  onFormFinish: (item: Item) => void;
  closeDrawer?: () => void;
  onDelete?: () => void;
};

const ProductDetailsForm: React.FC<Props> = (props) => {
  const { form } = props;

  const initialValues = {
    ...(props.item as Item),
    venueId: props.venueId,
  };

  return (
    <div className="p-4 pt-0">
      <AntForm
        form={form}
        colon={false}
        onFinish={props.onFormFinish}
        initialValues={initialValues}
        className="min-h-full pb-14"
      >
        <MainDetails item={props.item} form={form} isNew={props.isNew} />
        <div className="flex flex-col">
          <div className="flex flex-col lg:flex-row space-y-6 lg:space-y-0 lg:space-x-6">
            <div className="flex flex-col lg:w-1/2 space-y-6">
              <ModifiersCard form={form} options={props.item?.options} />
            </div>
            <div className="flex flex-col lg:w-1/2">
              {/* // @NOT_MVP */}
              {/* <TagsManager items={props.item.tags} /> */}
              <AllergensCard form={form} items={props.item?.allergens} className="md:mb-6" />
              <SpiceManager item={props.item} className="md:mb-6" />
              <DietaryCard form={form} items={props.item?.dietary} className="mb-6 md:mb-0" />
            </div>
          </div>
        </div>
        {/* <div className="fixed bottom-0 w-full flex items-center justify-end pr-8 pb-4 ">
          <Button size="large" color="black" htmlType="submit">
            Save
            {isLoading ? <Loader size={24} className="ml-10" /> : <CheckCircle size={24} className="ml-10" />}
          </Button>
        </div> */}
      </AntForm>
    </div>
  );
};

export default ProductDetailsForm;
