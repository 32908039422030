import * as React from "react";
import cx from "classnames";

type Props = {
  className: string;
  name?: string;
  code?: string;
};

const ColorBox: React.FC<Props> = ({ className, name, code }) => {
  return (
    <>
      <div className="inline-flex w-40 mb-2 mr-6">
        <div
          className={cx(
            "w-12 h-12 rounded border border-primary-white",
            className
          )}
          style={{ boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)" }}
        />
        <div className="text-m ml-3 flex flex-col justify-between">
          <div>{name}</div>
          <div>{code}</div>
        </div>
      </div>
    </>
  );
};

export default ColorBox;
