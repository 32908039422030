// Tooltips
export const TEST_TOOLTIP = "TBD";

// General
export const AVAILABLE_SECTIONS = "Available Sections";

// Errors
export const EMPTY_FIELD_ERROR = "Please enter a value.";
export const EMPTY_SELECTION_ERROR = "Please select a value.";
export const ERROR_SOMETHING_WENT_WRONG =
  "Oops! Something went wrong! Help us improve your experience by sending an error report";
export const ERROR_BANNER_DELETED = "The banner doesn't exist.";
export const ERROR_PRODUCT_DELETED = "The product doesn't exist.";
export const ERROR_MODIFIER_DELETED = "The modifier doesn't exist.";
export const ERROR_MENU_DELETED = "The menu doesn't exist.";

// Success Message
export const SUCCESS_BANNER_DELETED = "Banner has been deleted successfully.";
export const SUCCESS_PRODUCT_DELETED = "Product has been deleted successfully.";
export const SUCCESS_MODIFIER_DELETED = "Modifier has been deleted successfully.";
export const SUCCESS_MENU_DELETED = "Menu has been deleted successfully.";

// Warning Message
export const WARNING_MAX_NUM_OF_HIGHLIGHT_PRODUCTS = "A Highlight Section can contain maximum of 10 Products.";

// Venue
export const SELECT_VENUE_WARNING = "Please select a venue.";
export const NO_VARIANTS_WARNING = "Please add at least one Variant";

// Banner
export const BANNER_TYPE = "Type";
export const BANNER_SECTION_DRAWER_HEADER = "Banners Section";
export const BANNER_SECTION_BANNERS = "Banners";

export const BANNER_EDITOR = "Banner editor";
export const DELETE_BANNER = "Delete Banner";
export const DUPLICATE_BANNER = "Duplicate this banner";

// Highlights
export const HIGHLIGHTS_SECTION_DRAWER_HEADER = "Highlights Section";
export const HIGHLIGHTS_SECTION_PRODUCTS = "Products";

// Menu
export const NEW_MENU = "New menu";
export const MENU_STRUCTURE = "Menu Structure";

// Product
export const PRODUCT_DETAILS_DRAWER_TITLE = "Product Details";
export const EDIT_PRODUCT_DETAILS = "Edit product details";
export const VIEW_PRODUCT_DETAILS = "View product details";
export const PRODUCT_SAVE_SUCCESS = "Product has been saved successfully.";
export const MENU_SAVE_SUCCESS = "Menu has been saved successfully.";
export const SPICINESS = "Spiciness";
export const DIETARY_REQUIREMENTS = "Dietary match";
export const ITEM_DELETED = "Item removed.";
export const TAB_PRODUCTS = "Full list";
export const ADD_PRODUCT_DRAWER_TITLE = "Add product";

// Modifiers (Options)
export const MODIFIERS = "Modifiers";
export const MODIFIERS_MANAGER = "Modifiers manager";
export const ADD_MODIFIER = "Add Modifier";
export const REMOVE_MODIFIER = "Remove modifier";
export const MODIFIER_EDITOR = "Modifier editor";
export const MODIFIER_TITLE = "Modifier title";
export const VARIANT_TITLE = "Variant title";
export const VARIANT_DELETED = "Variant deleted";
export const MODIFIER_SAVE_SUCCESS = "Modifier has been saved successfully.";
export const NO_MODIFIERS_HEADER = "You have no modifiers yet.";
export const NO_MODIFIERS_MESSAGE = "Start by creating some modifiers.";
export const NO_PRODUCT_HEADER = "You have no product yet.";
export const NO_PRODUCT_MESSAGE = "Start by creating some products.";

// Form Labels
export const LABEL_PRICE = "Price";
export const LABEL_VENUE = "Venue";
export const LABEL_PRODUCT_TITLE = "Product title";
export const LABEL_DESCRIPTION = "Description";
export const LABEL_BANNER_SECTION_TITLE = "Banners Section title";
export const LABEL_HIGHLIGHTS_SECTION_TITLE = "Highlights Section title";
export const LABEL_SIZE = "Size";
export const LABEL_BANNER_TITLE = "Banner title";
export const LABEL_BANNER_BUTTON_TYPE = "Button type";
export const LABEL_BANNER_BUTTON_LINK = "Button link";
export const LABEL_BANNER_BUTTON_TITLE = "Button title";
export const LABEL_PHONE_NUMBER = "Phone number";
export const LABEL_WEB_LINK = "Web link";
export const LABEL_EMAIL = "Email";
export const LABEL_MENU_TITLE = "Menu title";
export const LABEL_MENU_TYPE = "Menu type";

// Buttons
export const BUTTON_ADD_PRODUCT = "Add Product";
export const BUTTON_PUBLISH = "Publish";
export const BUTTON_SAVE = "Save";
export const BUTTON_REMOVE_PRODUCT = "Remove product";
export const BUTTON_ADD_BANNER = "Add Banner";
export const BUTTON_ADD_CATEGORY = "Add Category";

// export const LABEL_ ="";

// Tooltips
export const TOOLTIP_MENU_TYPE =
  'Type can be "Standard" for a normal menu, or "Highlight" to showcase your products in the app home page.';
export const TOOLTIP_MENU_CATEGORIES =
  'A category represents a sub-menu you want to display in the mobile app. Common categories could be "Drinks", "Main", "Starters", etc.';
export const TOOLTIP_PRODUCT_MODIFIERS =
  "Modifiers are variations to the product. Customers will be able to choose between variations, make mandatory choices, add/remove ingredients.";
export const TOOLTIP_APP_HOMESCREEN =
  "In this section, you can create multiple widgets for each venue and sort them as you wish to display an exciting app home page to your customers.";
export const TOOLTIP_APP_AVAILABLE_SECTIONS =
  'This is a list of all the sections you can use on the home screen of the mobile app. A section can be a Menu, a Banner, or a Highlight. Drag and drop to "App homepage layout" section to add them to app\'s home page.';
export const TOOLTIP_HIGHLIGHT_SIZE = "Display size of the Highlights Section in the mobile app.";
export const TOOLTIP_LINKED_ITEMS =
  "Modifiers are shared across products and these are the products this modifier is attached to. Any changes you make to this modifier will be reflected on other products that uses this modifier.";

// Placeholders
export const PLACEHOLDER_SEARCH_PRODUCT = "Search by product name...";
export const PLACEHOLDER_SEARCH_MODIFIER = "Search by modifier name...";
