import * as React from "react";
import { Item } from "fetchers/item";
import cx from "classnames";

import InfoTooltip from "components/common/InfoTooltip";
import { TOOLTIP_LINKED_ITEMS } from "constants/strings";
type Props = {
  items?: Item[] | [];
  className?: string;
};

const LinkedItems: React.FC<Props> = (props) => {
  return (
    <>
      <div className={cx(props.className, "bg-orchid-10 p-5 rounded-2lg")}>
        <div className="mb-2 inline-flex items-center">
          <p className="text-m font-semibold">Linked items</p>
          <InfoTooltip className="ml-2" title={TOOLTIP_LINKED_ITEMS} />
        </div>
        {props.items && props.items.length > 0 ? (
          <ul className="list-disc list-inside">
            {props.items?.map((item: Item) => {
              return (
                <li key={item.id}>
                  <span className="text-m">{item.title}</span>
                </li>
              );
            })}
          </ul>
        ) : (
          <p className="text-m">No linked items</p>
        )}
      </div>
    </>
  );
};

export default LinkedItems;
