import * as React from "react";
import { Trash2 } from "react-feather";

import Modal, { Props as ModalProps } from "./common/Modal";
import Button from "./form/Button";

type Props = Omit<ModalProps, "children">;

const ModalDelete: React.FC<Props> = (props) => {
  const { onCancel, onOk, ...restProps } = props;
  return (
    <Modal {...restProps}>
      <div className="text-center pb-5 pt-6 px-12">
        <Trash2 size={50} className="mx-auto mb-5" />
        <p className="text-xl font-bold mb-5">Are you sure you want to delete?</p>
        <p className="text-m mb-5">You will not be able to recover deleted content.</p>
        <div className="flex justify-between">
          <Button color="black" size="medium" onClick={onCancel}>
            Cancel
          </Button>
          <Button color="yellow" size="medium" onClick={onOk}>
            Delete
          </Button>
        </div>
      </div>
    </Modal>
  );
};

ModalDelete.defaultProps = {
  title: "You are about to delete",
  width: 424,
};

export default ModalDelete;
