import * as React from "react";

const Context = React.createContext({ state: null });

export const ContextProvider = <State extends unknown, Action extends unknown>({
  children,
  reducer,
  initialState,
}: {
  children: React.ReactNode;
  initialState: State;
  reducer: (state: State, action: Action) => any;
}): JSX.Element => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const value = { state, dispatch };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useContext = <State extends any, Action extends string>(): {
  state: State;
  dispatch: React.Dispatch<{ type: Action; payload?: any }>;
} => {
  const context = React.useContext(Context);
  if (context === undefined) {
    throw new Error("context must be used within a CountProvider");
  }

  return context as { state: State; dispatch: React.Dispatch<{ type: Action; payload?: any }> };
};

// usage
/* 
import { useContext } from "../../../contexts/global-context";

const {
    dispatch,
    state,
  } = useContext<{count: number}, string>();

  <span onClick={() => dispatch({type:"increment", payload: 3})}>{state.count}</span>
*/
