import * as React from "react";
import cx from "classnames";
import { Switch, SwitchProps } from "antd";

type Props = SwitchProps & {
  type?: "dark" | "light" | "transparent-dark" | "transparent-light";
};

const ToggleButton: React.FC<Props> = (props: Props) => {
  const { className, type, defaultChecked, ...restProps } = props;
  return (
    <Switch
      className={cx(`type-${type}`, "toggle-button-wrapper", className)}
      defaultChecked={defaultChecked}
      {...restProps}
    />
  );
};

ToggleButton.defaultProps = {
  type: "dark",
};

export default ToggleButton;
