import { AxiosResponse, Method } from "axios";
import { Item } from "fetchers/item";
import { VENUE_ITEMS, VENUE_ITEM_DELETE } from "../constants/endpoints";
import api from "./api";

export const sortAlphabetical = (items: Item[]): Item[] => {
  return items.sort((a, b) => a.title.localeCompare(b.title));
};

export const getItems = async (id: string | number): Promise<AxiosResponse<Item[]>> => {
  return await api({
    url: VENUE_ITEMS(id),
    method: "GET",
  }).then((response) => {
    return {
      ...response,
      data: sortAlphabetical(response.data),
    };
  });
};

export const saveItem = async (
  endpoint: string,
  method: Method,
  data?: Item
): Promise<AxiosResponse<Item[] | Item>> => {
  return await api({
    url: endpoint,
    method: method,
    data: data,
  });
};

export const deleteItem = (item: Item): Promise<AxiosResponse> => {
  if (!item?.venueId || !item?.id) {
    throw new Error("Missing Venue ID or Item Id. ");
  }

  return api({
    url: VENUE_ITEM_DELETE(item.venueId, item.id),
    method: "DELETE",
  });
};
