import * as React from "react";
import { Form as AntForm, FormInstance } from "antd";
import { MoreVertical, Trash2 } from "react-feather";

import removeMessage from "components/common/removeMessage";
import DropdownMenu, { DropdownRef } from "components/common/DropdownMenu";
import Sort, { SortableAccordionItem } from "components/common/Sort";
import { FormListFieldData, FormListOperation } from "antd/lib/form/FormList";
import { Variant } from "fetchers/variant";
import { REMOVE_MODIFIER } from "constants/strings";
import { formatCurrency } from "utils/currency_utils";

type Props = {
  name: string;
  form: FormInstance;
};

const OptionAccordion: React.FC<Props> = (props) => {
  const menuItemsRef = React.createRef<DropdownRef>();

  const getOptionPath = (index: number | string) => {
    return [props.name, index];
  };

  return (
    <AntForm.List name={props.name}>
      {(fields: FormListFieldData[], { add, remove, move }: FormListOperation) => {
        return (
          <Sort.Container
            items={fields.map((_, index) => ({ id: `option-${index}` }))}
            onSortEnd={(oldIndex: number, newIndex: number) => move(oldIndex, newIndex)}
          >
            {fields.length > 0 ? (
              fields?.map((_, index) => {
                return (
                  <SortableAccordionItem
                    id={`option-${index}`}
                    key={index}
                    index={index}
                    accordionIndex={index}
                    headerClassName="rounded-2lg px-1"
                    buttonClassName="m-0"
                    header={
                      <div className="w-full flex items-center justify-between">
                        <p>
                          {props.form.getFieldValue([...getOptionPath(index), "title"]) ||
                            props.form.getFieldValue([...getOptionPath(index), "name"])}
                        </p>
                        <DropdownMenu
                          content={[
                            <div
                              key={2}
                              onClick={() => {
                                menuItemsRef.current?.close();
                                removeMessage(add, remove, index, props.form.getFieldValue([]));
                              }}
                              className="flex items-center text-fire cursor-pointer w-full"
                            >
                              <Trash2 size={20} className="mr-1" /> {REMOVE_MODIFIER}
                            </div>,
                          ]}
                          withIcon
                          ref={menuItemsRef}
                        >
                          <MoreVertical size={20} />
                        </DropdownMenu>
                      </div>
                    }
                  >
                    {props.form.getFieldValue(props.name)
                      ? props.form
                          .getFieldValue([...getOptionPath(index)])
                          .variants?.map((variant: Variant, variantIndex: number) => {
                            return (
                              <div key={variantIndex} className="w-full flex justify-between pl-12 pr-4 py-1">
                                <span>{variant.title}</span>
                                <span className="font-bold">{formatCurrency(variant.price)}</span>
                              </div>
                            );
                          })
                      : null}
                  </SortableAccordionItem>
                );
              })
            ) : (
              <div className="flex flex-col items-center rounded-2lg bg-orchid-10 p-7 w-full">
                <p className="font-bold">Add modifiers</p>
                <p>Use the plus button in this section to add modifiers</p>
              </div>
            )}
          </Sort.Container>
        );
      }}
    </AntForm.List>
  );
};

export default OptionAccordion;
