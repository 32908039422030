import * as React from "react";
import cx from "classnames";
import { ArrowDownCircle, ArrowLeftCircle, Menu } from "react-feather";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { useSortable, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

type SortableAccordionItemProps = {
  id: string | number;
  className?: string;
  headerClassName?: string;
  handleClassName?: string;
  buttonClassName?: string;
  header: any;
  index: number;
  accordionIndex: number;
  children: React.ReactNode;
};

export const SortableAccordionItem: React.FC<SortableAccordionItemProps> = (props) => {
  const { listeners, setNodeRef, transform, transition, isOver } = useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: isOver ? transition : undefined,
  };

  const [hideChildren, setHideChildren] = React.useState(false);

  return (
    <li ref={setNodeRef} className={cx("w-full flex flex-col items-start list-none", props.className)} style={style}>
      <div className="w-full flex items-center my-1">
        <Menu {...listeners} size={20} className={cx("mx-2.5 cursor-pointer")} />
        <div
          className={cx("w-full flex items-center bg-ice-100 pr-8 justify-between rounded-2lg", props.headerClassName)}
        >
          {props.header}
          <button
            type="button"
            className={cx("focus:outline-none", props.buttonClassName)}
            aria-haspopup={true}
            onClick={() => setHideChildren(!hideChildren)}
          >
            {hideChildren ? <ArrowLeftCircle /> : <ArrowDownCircle />}
          </button>
        </div>
      </div>
      {!hideChildren && props.children}
    </li>
  );
};

export type SortContainerProps = {
  items: {
    id: string | number;
    [x: string]: any;
  }[];
  children?: React.ReactNode;
  onSortEnd?: (oldIndex: number, newIndex: number) => void;
};

export const Container: React.FC<SortContainerProps> = (props) => {
  const sensors = useSensors(
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(PointerSensor, {
      activationConstraint: {
        // Require pointer to move by 5 pixels before activating draggable
        // Allows nested onClicks/buttons/interactions to be accessed
        distance: 5,
      },
    })
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={(e: DragEndEvent) => {
        if (e.over != null && e.over.id != null && e.active.id !== e.over.id) {
          const oldIndex = props.items
            .map(function (x) {
              return x.id;
            })
            .indexOf(e.active.id);
          const newIndex = props.items
            .map(function (x) {
              return x.id;
            })
            .indexOf(e.over.id);

          props.onSortEnd && props.onSortEnd(oldIndex, newIndex);
        }
      }}
    >
      <SortableContext items={props.items} strategy={verticalListSortingStrategy}>
        <ul className="w-full">{props.children}</ul>
      </SortableContext>
    </DndContext>
  );
};

export type SortItemProps = {
  id?: any;
  value?: any;
  className?: string;
  handleClassName?: string;
  useDragHandle?: any;
  index?: any;
};

export const Item: React.FC<SortItemProps> = (props) => {
  const { listeners, setNodeRef, transform, transition, isOver } = useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: isOver ? transition : undefined,
  };

  return (
    <li ref={setNodeRef} className={cx("w-full flex items-center list-none", props.className)} style={style}>
      {props.useDragHandle && <Menu {...listeners} size={20} className={cx("mx-2.5 cursor-pointer")} />}
      {props.value}
    </li>
  );
};

export default {
  Item,
  Container,
};
