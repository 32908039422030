import React from "react";
import { Plus } from "react-feather";

import Card from "./Card";
import Button from "components/form/Button";
import { FormInstance } from "antd/es/form/Form";
import AddModifiersDrawer from "./Modifiers/AddModifiersDrawer";
import { Option } from "fetchers/option";

type Props = {
  title: React.ReactNode;
  form: FormInstance;
  options: Option[] | undefined;
  className?: string;
  children: React.ReactNode;
};

const ModifiersCardWithDrawer: React.FC<Props> = (props) => {
  const [showDrawer, setShowDrawer] = React.useState(false);

  const handleClose = () => {
    setShowDrawer(false);
  };

  const handleAddModifiersDrawer = () => {
    props.form.validateFields(["venueId"]).then(() => {
      setShowDrawer(true);
    });
  };

  const CardTitle = (
    <div className="flex">
      <span className="flex items-center">{props.title}</span>
      <span onClick={handleAddModifiersDrawer}>
        <Button className="absolute top-7 right-7" type="icon-only" color="green" size="small">
          <Plus size={21} />
        </Button>
      </span>
      {showDrawer && (
        <AddModifiersDrawer
          form={props.form}
          venueId={props.form.getFieldValue("venueId")}
          options={props.options}
          visible={showDrawer}
          onClose={handleClose}
        />
      )}
    </div>
  );

  return (
    <Card title={CardTitle}>
      <div className="flex space-x-6 w-full">{props.children}</div>
    </Card>
  );
};

export default ModifiersCardWithDrawer;
