import * as React from "react";

import { Item } from "fetchers/item";
import ItemAccordionCard from "./ItemAccordionCard";
import { NO_PRODUCT_HEADER, NO_PRODUCT_MESSAGE } from "constants/strings";
import { Meh } from "react-feather";
import { useItemsStore } from "stores/itemsStore";
import { UiState } from "services/api";

type Props = {
  uiState: UiState<Item>;
  query: string;
};

const Items: React.FC<Props> = (props) => {
  const { uiState } = props;

  const [state] = useItemsStore();

  return (
    <>
      {uiState.isLoading && <div>Loading...</div>}
      {uiState.error && <div>{uiState.error}</div>}
      {state.items.length
        ? state.items
            .filter((item) => item.title.toLowerCase().includes(props.query.toLowerCase()))
            .map((item: Item, index: number) => <ItemAccordionCard key={index} item={item} />)
        : !uiState.isLoading &&
          !uiState.error && (
            <div className="bg-orchid-10 text-center p-5 rounded-2lg w-1/3 mx-auto">
              <Meh size="70" className="mb-2.5 mx-auto" />
              <p className="mb-2.5 text-m font-bold">{NO_PRODUCT_HEADER}</p>
              <p className="text-m mb-2.5">{NO_PRODUCT_MESSAGE}</p>
            </div>
          )}
    </>
  );
};

export default Items;
