import * as React from "react";
import cx from "classnames";
import { Radio, Form } from "antd";

import Card from "./Card";
import { Item } from "fetchers/item";
import { SPICINESS } from "constants/strings";

type Props = {
  className?: string;
  item?: Item;
};

const items = ["None", "Mild", "Medium", "Hot"];

const SpiceManager: React.FC<Props> = (props) => {
  return (
    <Card className={props.className} title={<span className="flex items-center">{SPICINESS}</span>}>
      <Form.Item
        className="flex flex-row text-center text-m p-0 mb-0 font-semibold text-charcoal rounded-3xl bg-ice-100"
        name="spiciness"
        initialValue={props.item?.spiciness || 0}
        rules={[
          {
            required: true,
            message: "Please select spiciness!",
          },
        ]}
      >
        <Radio.Group defaultValue={props.item?.spiciness || 0} className="flex w-full spiciness">
          {items.map((item, index) => (
            <Radio.Button key={index} value={index} className={cx("py-1 rounded-3xl flex-1 cursor-pointer")}>
              {item}
            </Radio.Button>
          ))}
        </Radio.Group>
      </Form.Item>
    </Card>
  );
};

export default SpiceManager;
