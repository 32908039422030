import * as React from "react";

function SvgHeartIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="1em" height="1em" viewBox="0 0 22 20" fill="none" {...props}>
      <path
        d="M19.103 3.226a5.042 5.042 0 00-7.131 0L11 4.197l-.972-.971a5.043 5.043 0 10-7.131 7.131l.971.972L11 18.461l7.132-7.132.971-.972a5.042 5.042 0 000-7.131v0z"
        stroke="#E94C3D"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgHeartIcon;
