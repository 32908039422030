import * as React from "react";

import MenuAccordionContent from "./Cards/MenuAccordionContent";
import { Venue } from "fetchers/venue";
import { useContext } from "hooks/contextProvider";
import VenuesAccordion from "components/common/VenuesAccordion";
import Button from "components/form/Button";
import { Plus } from "react-feather";

const Menus: React.FC = () => {
  const { state } = useContext<{ selectedVenues: Venue[] }, any>();
  const [isMenuDrawerVisible, setIsMenuDrawerVisible] = React.useState(false);

  return (
    <>
      <VenuesAccordion
        venues={state.selectedVenues}
        contentComponent={(venue) => (
          <MenuAccordionContent
            venue={venue}
            isMenuDrawerVisible={isMenuDrawerVisible}
            onCloseMenuDrawer={() => setIsMenuDrawerVisible(false)}
          />
        )}
      />
      <Button
        className="fixed bottom-0 right-0 mr-6 mb-6"
        type="icon-only"
        color="green"
        size="large"
        raised
        onClick={() => setIsMenuDrawerVisible(true)}
      >
        <Plus size={42} />
      </Button>
    </>
  );
};

export default Menus;
