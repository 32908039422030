import React from "react";
import cx from "classnames";

type Props = {
  children: React.ReactNode;
  className?: string;
};

const FormItemLabel: React.FC<Props> = (props) => {
  return <span className={cx("inline-flex items-center text-m mb-2", props.className)}>{props.children}</span>;
};

export default FormItemLabel;
