import * as React from "react";
import Alert from "../../../components/common/Alert";
import message from "../../../components/common/message";
import Button from "../../../components/form/Button";

const text = (
  <>
    <div className="font-bold text-m mb-1">Changes published</div>
    All good! Your changes have been published and saved correctly. See you later!
  </>
);

const Dialogues: React.FC = () => {
  const test = () => {
    message({
      type: "success",
      content: text,
      duration: 20,
      key: 1,
    });
  };

  return (
    <>
      <section className="shadow rounded bg-primary-white my-4 py-5 px-4">
        <h1 className="font-bold text-2xl mb-3">Dialogues</h1>
        <Button className="mb-2" size="small" onClick={() => test()}>
          Show Dialogue
        </Button>
        <Alert
          closable
          progressBar
          duration={5000}
          message="Your content is always auto-saved and kept Hidden as “Draft”. Publish it to maket visible in Haffinity app."
        />
      </section>
    </>
  );
};

export default Dialogues;
