export const setToken = ({ access, refresh }: { access: string; refresh?: string }): void => {
  if (access) {
    localStorage.setItem("access_token", access);
  }
  if (refresh) {
    localStorage.setItem("refresh_token", refresh);
  }
};

export const getAccessToken = (): string | null => {
  try {
    return localStorage.getItem("access_token");
  } catch (e) {
    alert("localStorage access is denied! Please check your browser settings.");
  }

  return null;
};

export const getRefreshToken = (): string | null => {
  return localStorage.getItem("refresh_token");
};

export const clearToken = (): void => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
};
