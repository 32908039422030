import cx from "classnames";
import * as React from "react";
import { Info } from "react-feather";
import Tooltip, { Props as TooltipProps } from "./Tooltip";

type Props = Omit<TooltipProps, "children"> & {
  IconSize?: string | number;
  IconClassName?: string;
};

const InfoTooltip: React.FC<Props> = (props: Props) => {
  const { IconSize, IconClassName, title, ...resetProps } = props;
  return (
    <Tooltip title={title} {...resetProps}>
      <div>
        <Info
          size={IconSize}
          className={cx(
            {
              "text-black-op-50": IconClassName == undefined,
            },
            "cursor-pointer",
            IconClassName
          )}
        />
      </div>
    </Tooltip>
  );
};

InfoTooltip.defaultProps = {
  IconSize: 15,
};

export default InfoTooltip;
