import * as React from "react";

function SvgBarsIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 14" fill="none" {...props}>
      <path
        d="M1 7h18M1 1h18M1 13h18"
        stroke="#fff"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgBarsIcon;
