import { Form } from "antd";
import * as React from "react";

import Field from "components/form/Field";
import Select from "components/form/Select";
import InfoTooltip from "components/common/InfoTooltip";
import {
  EMPTY_FIELD_ERROR,
  EMPTY_SELECTION_ERROR,
  LABEL_HIGHLIGHTS_SECTION_TITLE,
  LABEL_SIZE,
  TOOLTIP_HIGHLIGHT_SIZE,
} from "constants/strings";
import VenueSelect from "components/form/VenueSelect";
import FormItemLabel from "components/common/FormItemLabel";

const MenuTypeOptions = [
  {
    label: "Standard",
    value: "Standard",
  },
  {
    label: "Large",
    value: "Large",
  },
  {
    value: "Extra Large",
    label: "Extra Large",
  },
];

type Props = {
  isNew?: boolean;
  isVisible?: boolean;
  venueId?: string;
  isVenueSelectionDisabled: boolean;
};

const Settings: React.FC<Props> = (props) => {
  return (
    <>
      <div className="grid grid-cols-3 gap-6">
        <div>
          <Form.Item
            name="title"
            label={<FormItemLabel>{LABEL_HIGHLIGHTS_SECTION_TITLE}</FormItemLabel>}
            rules={[{ required: true, message: EMPTY_FIELD_ERROR }]}
          >
            <Field className="border-0 max-w-full mb-0" type="text" placeholder="Type something..." />
          </Form.Item>
        </div>
        <VenueSelect disabled={props.isVenueSelectionDisabled} venueId={props.venueId} />
        <div>
          <Form.Item
            name="size"
            label={
              <FormItemLabel>
                {LABEL_SIZE} <InfoTooltip className="ml-1" title={TOOLTIP_HIGHLIGHT_SIZE} />
              </FormItemLabel>
            }
            rules={[{ required: true, message: EMPTY_SELECTION_ERROR }]}
            initialValue={MenuTypeOptions[0].value}
          >
            <Select
              placeholder="Select a type"
              options={MenuTypeOptions}
              defaultValue="Standard"
              className="max-w-full"
            />
          </Form.Item>
        </div>
      </div>
    </>
  );
};

Settings.defaultProps = {
  isNew: false,
  isVisible: false,
};

export default Settings;
