import { Form, FormInstance } from "antd";
import * as React from "react";

import Field from "components/form/Field";
import { Item } from "fetchers/item";
import TextArea from "components/form/TextArea";
import Thumbnail from "components/common/Thumbnail";
import VenueSelect from "components/form/VenueSelect";
import { VENUE_ITEM_IMAGE } from "constants/endpoints";
import { getImage } from "utils/image_utils";
import FormItemLabel from "components/common/FormItemLabel";
import { LABEL_DESCRIPTION, LABEL_PRODUCT_TITLE, LABEL_PRICE } from "constants/strings";
import { ASPECT_RATIO_PORTRAIT } from "constants/ints";

type Props = {
  item?: Item;
  isNew?: boolean;
  form: FormInstance;
};

const MainDetails: React.FC<Props> = (props) => {
  return (
    <div className="mt-6 mb-6">
      <div className="flex flex-col md:flex-row">
        <div className="flex flex-row mr-4 items-center">
          <Thumbnail
            aspectRatio={ASPECT_RATIO_PORTRAIT}
            // Todo: Show error message for null values here
            imageUri={getImage(props.item?.imageUri)}
            size="xx-large"
            actionEndpoint={VENUE_ITEM_IMAGE(props.item?.venueId ?? " ", props.item?.id ?? " ")}
            form={props.form}
          />
        </div>
        <div className="flex flex-col flex-1 mt-6 md:mt-0">
          <div className="flex">
            <VenueSelect disabled={!props.isNew} venueId={props?.item?.venueId} />
          </div>
          <div className="grid grid-cols-2 gap-2 mt-1">
            <Form.Item
              className="mb-0"
              label={<FormItemLabel>{LABEL_PRODUCT_TITLE}</FormItemLabel>}
              name="title"
              rules={[
                {
                  required: true,
                  message: "Please enter title!",
                },
              ]}
            >
              <Field className="border-0" placeholder="Type Something..." type="text" />
            </Form.Item>
            <Form.Item
              className="mb-0"
              label={<FormItemLabel>{LABEL_PRICE}</FormItemLabel>}
              name="price"
              rules={[
                {
                  required: true,
                  message: "Please set the price.",
                },
              ]}
            >
              <Field className="border-0" placeholder="0.00" type="number" prefix={"$"} prefixCls={"ant-input"} />
            </Form.Item>
          </div>
          <Form.Item
            label={<FormItemLabel>{LABEL_DESCRIPTION}</FormItemLabel>}
            style={{ marginBottom: "0px" }}
            name="description"
          >
            <TextArea autoSize={false} placeholder="Type Something..." className="border-0 min-h-28" />
          </Form.Item>
        </div>
      </div>
    </div>
  );
};

export default MainDetails;
