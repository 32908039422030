import cx from "classnames";
import * as React from "react";

import { default as CommonCard } from "components/common/Card";
import Title from "components/common/Title";

type Props = {
  title: React.ReactNode;
  className?: string;
  children: React.ReactNode;
};

const Card: React.FC<Props> = (props) => {
  return (
    <CommonCard
      variant="large"
      className={cx("flex flex-1 flex-col relative p-7 rounded-3xl border border-ice-100 shadow-none", props.className)}
    >
      <Title className="text-xl mb-10 h-7">{props.title}</Title>
      {props.children}
    </CommonCard>
  );
};

export default Card;
