import cx from "classnames";
import * as React from "react";
import { Sticky } from "react-sticky";
import { useLocation } from "react-router-dom";

import Venues from "./Venues";
import Icon from "../../icons/Icon";

type Props = {
  onToggleSidebar: () => void;
};

const TopBar: React.FC<Props> = (props) => {
  const location = useLocation();

  const getPageTitle = () => {
    switch (location.pathname) {
      case "/":
        return "Menus";
      case "/app":
        return "App";
      case "/customers":
        return "Customers";
      case "/products":
        return "Products";
      case "/settings":
        return "Settings";
      case "/live-activity":
        return "Live activity";
      case "/help":
        return "Help";

      default:
        return null;
    }
  };

  return (
    <div className="relative z-30">
      <Sticky relative topOffset={12}>
        {({ style, isSticky }) => (
          <div
            className={cx("z-30 -mb-3 pb-0", {
              "md:mt-0": isSticky,
              "md:mt-3": !isSticky,
            })}
            style={style}
          >
            <div
              className={cx(
                "bg-primary-white px-5 py-2 w-full h-18",
                "flex items-center z-10 relative rounded-none shadow-m-10",
                {
                  "md:rounded-2.5xl": !isSticky,
                  "md:rounded-b-2.5xl": isSticky,
                }
              )}
            >
              <Icon
                name="BarsIcon"
                extraClassName="flex md:hidden"
                onClick={() => props.onToggleSidebar()}
                className="bg-black min-w-10 h-10"
              />
              <div className="flex-1 flex flex-col justify-between pl-5 md:pl-0">
                <p className="text-xl leading-none mb-3">{getPageTitle()}</p>
                <Venues />
              </div>
              {/* <span className="hidden md:flex">
                <UserAvatar size="medium" />
              </span> */}
            </div>
            <div className="bg-gradient-to-b from-gray-50 h-8 -mt-4 z-0 relative" />
          </div>
        )}
      </Sticky>
    </div>
  );
};

export default TopBar;
