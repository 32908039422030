import cx from "classnames";
import * as React from "react";
import { X } from "react-feather";
import { Modal as AntModal, ModalProps } from "antd";

export type Props = ModalProps & {
  children: React.ReactNode;
  onClose?: () => void;
};

const Modal: React.FC<Props> = (props) => {
  const { title, onClose, ...modalProps } = props;

  return (
    <AntModal
      title={
        <div className={cx("flex items-center bg-primary-black", "px-7 py-1.5 text-primary-white text-m")}>
          <X size={24} className="cursor-pointer mr-5" onClick={() => onClose && onClose()} />
          {title}
        </div>
      }
      closeIcon={<div></div>}
      closable={false}
      {...modalProps}
    />
  );
};

Modal.defaultProps = {
  closable: true,
  footer: null,
  width: "630px",
};

export default Modal;
