import * as React from "react";
import { Form, Input } from "antd";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

import api from "../../services/api";
import { LOGIN } from "../../constants/endpoints";
import Button from "../../components/form/Button";
import Alert from "../../components/common/Alert";
import Checkbox from "../../components/form/Checkbox";
import AuthLayout from "../../components/Layout/AuthLayout";
import { getAccessToken, setToken } from "../../services/localStorage";

const SignIn: React.FC = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [, forceUpdate] = React.useState({});
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  // To disable submit button at the beginning.
  React.useEffect(() => {
    forceUpdate({});
    if (getAccessToken()) {
      history.push("/");
    }
  }, []);

  const onFinish = (data: any) => {
    setLoading(true);
    api({
      handleAuth: false,
      url: LOGIN,
      method: "POST",
      data: { email: data.email, password: data.password },
    })
      .then((response) => {
        setLoading(false);
        setToken(response.data.tokens);
        history.push("/");
      })
      .catch((error) => {
        console.log("error :>> ", { ...error });
        setError(error.response?.data?.detail);
        setLoading(false);
      });
  };

  return (
    <AuthLayout>
      <div className="text-center">
        <h1 className="text-4xl font-bold mb-5">Sign In</h1>
        <p className="mb-10 text-lynch">Sign in to your account using your email and password</p>
      </div>
      <Form form={form} onFinish={onFinish} className="mb-7" size="large">
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Please enter your email!",
            },
            {
              type: "email",
              message: "Please enter a valid email address!",
            },
          ]}
        >
          <Input type="email" id="email" />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[
            { required: true, message: "Please enter your password!" },
            { min: 8, message: "Password must be more than 7 characters" },
          ]}
        >
          <Input.Password className="input-custom-password" />
        </Form.Item>

        <div className="flex my-4">
          <div className="w-1/2">
            <Form.Item name="remember" valuePropName="checked">
              <Checkbox className="text-lynch w-auto" defaultChecked={false}>
                Remember me
              </Checkbox>
            </Form.Item>
          </div>
          <div className="w-1/2 text-right">
            <Link to="/forget-password" className="hover:underline">
              Forgot Password?
            </Link>
          </div>
        </div>

        <Form.Item shouldUpdate>
          {() => (
            <Button
              htmlType="submit"
              className="w-full"
              size="large"
              loading={loading}
              disabled={!!form.getFieldsError().filter(({ errors }) => errors.length).length || loading}
            >
              Sign In
            </Button>
          )}
        </Form.Item>
        {error && <Alert className="mt-4" message={error} duration={2} />}
      </Form>
    </AuthLayout>
  );
};

export default SignIn;
