import cx from "classnames";
import * as React from "react";

import Bubble, { OptionalProps as BubbleProps } from "components/common/Bubble";

export type ExtendedBubbleProps = BubbleProps & {
  text: React.ReactNode | string;
};

type Props = {
  bubbles?: ExtendedBubbleProps[];
  bubblesClassName?: string;
};

const MenuCardsBubbles: React.FC<Props> = (props) => (
  <div className={cx("flex justify-items-auto flex-wrap -mx-2 -mb-2")}>
    {props.bubbles?.map(({ text, className, ...restBubbleProps }, index) => (
      <Bubble
        key={index}
        className={cx("mb-2 mx-2", [className ? className : props.bubblesClassName])}
        {...restBubbleProps}
      >
        <span>{text}</span>
      </Bubble>
    ))}
  </div>
);

export default MenuCardsBubbles;
