import React, { useState, useEffect } from "react";

type Props = {
  children: React.ReactNode;
  waitBeforeShow?: number;
};

const Delayed: React.FC<Props> = (props) => {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShown(true);
    }, props.waitBeforeShow);
    return () => clearTimeout(timer);
  }, [props.waitBeforeShow]);

  return <>{isShown ? props.children : null}</>;
};

Delayed.defaultProps = {
  waitBeforeShow: 500,
};
export default Delayed;
