import * as React from "react";
import BubbleMedium from "./Medium";
import BubbleSmall from "./Small";

const Bubbles: React.FC = () => {
  return (
    <>
      <div className="flex">
        <BubbleMedium />
        <BubbleSmall />
      </div>
    </>
  );
};

export default Bubbles;
