import CreateAppSection from "components/CreateAppSection";
import * as React from "react";
import { Meh } from "react-feather";

const NoAppSections: React.FC = () => (
  <>
    <div className="bg-orchid-10 text-center p-5 rounded-2lg">
      <Meh size="70" className="mb-2.5 mx-auto" />
      <p className="mb-2.5 text-m font-bold">Your App homepage looks empty</p>
      <p className="text-m mb-2.5">
        Begin by creating some sections or drop them from Available Sections. You can use menus, highlights and banners
      </p>
      <CreateAppSection />
    </div>
  </>
);

export default NoAppSections;
