import * as React from "react";
import { Collapse } from "antd";

import Card from "components/common/Card";
import Title from "components/common/Title";
import Status from "components/form/Status";
import Avatar from "components/common/Avatar";
import Bubble from "components/common/Bubble";
import { Item } from "fetchers/item";
import Bubbles from "components/MenuCardsBubbles";
import Accordion from "components/common/Accordion";
import { getSpicinessText } from "services/mappings";
import { DIETARY_REQUIREMENTS, SPICINESS } from "constants/strings";
import { getImage } from "utils/image_utils";
import { formatCurrency } from "utils/currency_utils";

const getAccordionItems = (item: Item) => [
  {
    header: (
      <Title>
        <span className="mr-2 text-primary-black text-xl">Description</span>
      </Title>
    ),
    content: <div className="ml-2">{item.description}</div>,
  },
  // Not in Scope

  // {
  //   header: (
  //     <Title>
  //       <span className="mr-2 text-primary-black text-xl">Tags</span>
  //     </Title>
  //   ),
  //   content: (
  //     <div className="ml-2">
  //       <Bubbles
  //         bubbles={item.tags?.map((tag) => ({ text: tag, size: "medium", border: true, shadow: true }))}
  //         bubblesClassName="bg-orchid-10 border-primary-white"
  //       />
  //     </div>
  //   ),
  // },
  {
    header: (
      <Title>
        <span className="mr-2 text-primary-black text-xl">{SPICINESS}</span>
      </Title>
    ),
    content: (
      <div className="ml-2">
        <Bubble size="medium" className="bg-red-10" border shadow>
          {getSpicinessText(item.spiciness)}
        </Bubble>
      </div>
    ),
  },
  {
    header: (
      <Title>
        <span className="mr-2 text-primary-black text-xl">Allergens</span>
      </Title>
    ),
    content: (
      <div className="ml-2">
        <Bubbles
          bubbles={item.allergens?.map((allergen) => ({ text: allergen, size: "medium" }))}
          bubblesClassName="bg-grey-10"
        />
      </div>
    ),
  },
  {
    header: (
      <Title>
        <span className="mr-2 text-primary-black text-xl">{DIETARY_REQUIREMENTS}</span>
      </Title>
    ),
    content: (
      <div className="ml-2">
        <Bubbles
          bubbles={item.dietary?.map((dietary) => ({ text: dietary, size: "medium" }))}
          bubblesClassName="bg-grey-10"
        />
      </div>
    ),
  },
  {
    header: (
      <Title>
        <span className="mr-2 text-primary-black text-xl">Modifiers</span>
      </Title>
    ),
    content: (
      <div className="ml-2">
        <Collapse className="bg-transparent" expandIconPosition="left" ghost>
          {item.options?.map((option, index) => (
            <Collapse.Panel key={index} header={<b>{option.title}</b>}>
              {option.variants.map((variant, index) => (
                <div key={index} className="w-full flex justify-between px-2">
                  <span>{variant.title}</span>
                  <b>{formatCurrency(variant.price)}</b>
                </div>
              ))}
            </Collapse.Panel>
          ))}
        </Collapse>
      </div>
    ),
  },
];

type Props = {
  item?: Item;
};

const ProductDetails: React.FC<Props> = (props) => {
  return (
    (props.item && (
      <div className="p-7">
        <Card variant="large" className="mb-2 h-32 flex overflow-hidden">
          <Avatar className="flex" imgClassName="object-cover" size="x-large" src={getImage(props.item.imageUri)} />
          <div className="flex flex-1 flex-col ml-5 py-2 text-sm pr-4">
            <span className="text-base font-bold">{props.item.title}</span>
            <div className="font-bold">
              {/* <span className="text-fire line-through mr-1"></span> */}
              {formatCurrency(props.item.price)}
            </div>
            <Status className="-ml-3" isVisible={props.item.visible} />
          </div>
        </Card>
        {/* <div className="flex items-center mt-4.5">
          <Bubble className="bg-primary-black text-primary-white">POS Discount</Bubble>
          <span className="font-bold mx-2.5">15% OFF</span>
          <InfoTooltip title="some text" />
        </div> */}

        {/* <div className="flex flex-col items-start mt-4.5">
          <Title className="mb-2.5">
            <span className="mr-2 text-black-op-60">Haffinity feedback</span>
          </Title>

          <Bubble className="bg-green-20">
            <span className="flex items-center">
              <ThumbsUp className="mr-3" size={12} />
              Looks good
            </span>
          </Bubble>
        </div> */}
        <Accordion
          className="bg-transparent mt-5"
          items={getAccordionItems(props.item)}
          expandAll={true}
          accordion={false}
        />
      </div>
    )) ??
    null
  );
};

export default ProductDetails;
