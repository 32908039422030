import * as React from "react";
import { Award } from "react-feather";

import Bubble from "../../../../components/common/Bubble";

const BubbleSmall: React.FC = () => {
  return (
    <div className="w-1/2">
      <h2 className="font-bold text-xl mb-2">Bubble S</h2>
      <h3 className="font-bold text-l mb-4">
        Coulor, text, icons are always a variable
      </h3>
      <div className="flex mb-4">
        <div className="w-2/6" />
        <div className="flex w-4/6 text-m text-center">
          <div className="w-1/2">+ right icon</div>
          <div className="w-1/2">- right icon</div>
        </div>
      </div>

      <div className="flex mb-4">
        <div className="w-2/6" />
        <div className="flex w-4/6 text-m text-center">
          <div className="w-1/2">
            <Bubble onClose={() => 0}>Bubble</Bubble>
          </div>
          <div className="w-1/2">
            <Bubble>Bubble</Bubble>
          </div>
        </div>
      </div>

      <div className="flex mb-4">
        <div className="w-2/6 text-right px-4">+ Border</div>
        <div className="flex w-4/6 text-m text-center bg-ice-150 py-2">
          <div className="w-1/2">
            <Bubble onClose={() => 0} border>
              Bubble
            </Bubble>
          </div>
          <div className="w-1/2">
            <Bubble border>Bubble</Bubble>
          </div>
        </div>
      </div>

      <div className="flex mb-4">
        <div className="w-2/6 text-right px-4">+ shadow</div>
        <div className="flex w-4/6 text-m text-center py-2">
          <div className="w-1/2">
            <Bubble onClose={() => 0} border shadow>
              Bubble
            </Bubble>
          </div>
          <div className="w-1/2">
            <Bubble border shadow>
              Bubble
            </Bubble>
          </div>
        </div>
      </div>

      <div className="flex mb-4">
        <div className="w-2/6 text-right px-4">+ Front icon</div>
        <div className="flex w-4/6 text-m text-center py-2">
          <div className="w-1/2">
            <Bubble onClose={() => 0} border shadow>
              <Award size={12} className="mr-2" />
              Bubble
            </Bubble>
          </div>
          <div className="w-1/2">
            <Bubble border shadow>
              <Award size={12} className="mr-2" />
              Bubble
            </Bubble>
          </div>
        </div>
      </div>

      <div className="flex mb-4">
        <div className="w-2/6 text-right px-4">+ External front icon</div>
        <div className="flex w-4/6 text-m text-center py-2">
          <div className="w-1/2">
            <Award size={16} className="inline-flex mr-1" />
            <Bubble onClose={() => 0} border shadow>
              Bubble
            </Bubble>
          </div>
          <div className="w-1/2">
            <Award size={16} className="inline-flex mr-1" />
            <Bubble border shadow>
              Bubble
            </Bubble>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BubbleSmall;
