import { DrawerProps } from "antd";
import { Form as AntForm } from "antd";
import Drawer from "components/common/Drawer";
import { BANNER_SECTION_DRAWER_HEADER, SELECT_VENUE_WARNING } from "constants/strings";
import { Banner } from "fetchers/banner";
import DrawerHeader from "pages/Menus/DrawerHeader";
import * as React from "react";
import BannerSectionForm from "./BannerSectionForm";
import { VENUE_BANNERS_POST, VENUE_BANNERS_PATCH } from "constants/endpoints";
import { SUCCESS_BANNER_DELETED, ERROR_SOMETHING_WENT_WRONG, ERROR_BANNER_DELETED } from "constants/strings";
import message from "components/common/message";
import ModalDelete from "components/ModalDelete";
import Button from "components/form/Button";
import { CheckCircle, Trash2 } from "react-feather";
import { deleteBanner, saveBanner } from "services/bannerApi";

type Props = DrawerProps & {
  banner?: Banner;
  isNew?: boolean;
  venueId?: string;
  isVisible: boolean;
  onClose?: () => void;
  onUpdate?: () => void;
};

const BannerSectionDrawer: React.FC<Props> = (props) => {
  const { banner, isNew, isVisible, venueId, onClose, ...restProps } = props;
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isBannerVisible, setIsBannerVisible] = React.useState(props.banner?.visibility ?? true);
  const [form] = AntForm.useForm();

  const handleDelete = () => {
    setShowDeleteModal(false);

    if (props.isNew) {
      return;
    }

    if (!props?.venueId || !props?.banner?.id) {
      return;
    }

    setIsLoading(true);

    deleteBanner(props.venueId, props.banner)
      .then(() => {
        message({
          type: "success",
          content: SUCCESS_BANNER_DELETED,
          progressBar: false,
          duration: 3,
          key: 1,
        });
        props.onUpdate && props.onUpdate();
      })
      .catch(() => {
        message({
          type: "warning",
          content: ERROR_BANNER_DELETED,
          progressBar: false,
          duration: 6,
          key: 1,
        });
      })
      .finally(() => setIsLoading(false));
  };

  const handleSave = (data: Banner & { venueId: string }) => {
    let endpoint;

    if (isLoading) {
      setIsLoading(false);
    }

    if (!data?.venueId || !data?.bannerItems) {
      message({
        type: "warning",
        content: !data?.bannerItems ? "Please add some Banner Items" : SELECT_VENUE_WARNING,
        progressBar: false,
        duration: 3,
        key: 1,
      });
      return;
    }

    if (props.isNew) {
      endpoint = VENUE_BANNERS_POST(data.venueId);
    } else if (props.banner?.id) {
      endpoint = VENUE_BANNERS_PATCH(data.venueId, props.banner.id);
    } else {
      message({
        type: "warning",
        content: "Banner doesn't exist id: " + props.banner?.id,
        progressBar: false,
        duration: 3,
        key: 1,
      });
      return;
    }

    setIsLoading(true);

    data?.bannerItems.forEach((bannerItem, index) => {
      bannerItem.displaySequence = index;
    });

    data.visibility = isBannerVisible;

    saveBanner(endpoint, props.isNew ? "POST" : "PATCH", data)
      .then(() => {
        message({
          type: "success",
          content: "Banner has been saved successfully.",
          progressBar: false,
          duration: 3,
          key: 1,
        });
        props.onUpdate && props.onUpdate();
      })
      .catch(() => {
        message({
          type: "warning",
          content: ERROR_SOMETHING_WENT_WRONG,
          progressBar: false,
          duration: 6,
          key: 1,
        });
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <Drawer
        onClose={onClose}
        visible={isVisible}
        key={banner?.id}
        title={
          <DrawerHeader
            isNew={isNew}
            isVisible={isBannerVisible}
            title={BANNER_SECTION_DRAWER_HEADER}
            onVisibilityChange={(isVisible) => setIsBannerVisible(isVisible)}
          />
        }
        footer={
          <div className="flex gap-6 justify-between">
            <Button
              size="large"
              color="red"
              htmlType="button"
              onClick={() => setShowDeleteModal(true)}
              disabled={props.isNew}
            >
              Delete
              <Trash2 size={24} className="ml-10" />
            </Button>

            <Button size="large" color="black" onClick={() => form.submit()} htmlType="submit">
              Publish
              <CheckCircle size={24} className="ml-10" />
            </Button>
          </div>
        }
        {...restProps}
      >
        <BannerSectionForm
          isNew={isNew}
          banner={banner}
          form={form}
          venueId={venueId}
          closeDrawer={() => onClose && onClose()}
          onDelete={() => setShowDeleteModal(true)}
          onSave={handleSave}
        />
      </Drawer>
      <ModalDelete
        visible={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onClose={() => setShowDeleteModal(false)}
        onOk={handleDelete}
      />
    </>
  );
};

BannerSectionDrawer.displayName = "BannerSectionDrawer";

export default BannerSectionDrawer;
