import * as React from "react";

import { Venue } from "fetchers/venue";
import Items from "./Items";
import EditCreateProductDrawer from "components/ProductDetails/EditCreateProduct";
import { useItemsStore } from "stores/itemsStore";
import { getItems } from "services/itemApi";
import { UiState } from "services/api";
import { Item } from "fetchers/item";

type Props = {
  venue: Venue;
  query: string;
  isProductDrawerVisible: boolean;
  onCloseProductDrawer: () => void;
};

const ItemAccordionContent: React.FC<Props> = (props) => {
  const [, actions] = useItemsStore();
  const [uiState, setUiState] = React.useState<UiState<Item>>({ isLoading: false });

  React.useEffect(() => {
    setUiState({ isLoading: true });
    getItems(props.venue.id)
      .then((response) => {
        setUiState({ isLoading: false });
        actions.updateItems && actions.updateItems(response.data);
      })
      .catch((err) => {
        console.error(err);
        setUiState({
          isLoading: false,
          error: err.message,
        });
      });
  }, []);

  return (
    <>
      <Items uiState={uiState} query={props.query} />
      {props.isProductDrawerVisible && (
        <EditCreateProductDrawer
          destroyOnClose
          style={{ transform: "none !important" }}
          isNew={true}
          isVisible={props.isProductDrawerVisible}
          onClose={props.onCloseProductDrawer}
        />
      )}
    </>
  );
};

export default ItemAccordionContent;
