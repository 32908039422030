import * as React from "react";
import { Collapse, CollapseProps, CollapsePanelProps } from "antd";
import { ArrowDownCircle, ArrowLeftCircle } from "react-feather";
import cx from "classnames";

type Item = Omit<CollapsePanelProps, "key"> & {
  key?: string | number;
  content: string | React.ReactNode;
};

type Props = CollapseProps & {
  openIcon?: React.ReactNode;
  closeIcon?: React.ReactNode;
  items: Item[];
  expandAll?: boolean;
};

const Accordion: React.FC<Props> = (props) => {
  const { openIcon, closeIcon, items, ...collapseProps } = props;

  return (
    <Collapse
      {...collapseProps}
      className={cx("mx-1 md:mx-0", props.className)}
      defaultActiveKey={props.expandAll ? props.items.map((_, i) => i) : undefined}
      expandIcon={({ isActive }) => (
        <button type="button" className="focus:outline-none" aria-haspopup={true}>
          {isActive ? openIcon : closeIcon}
        </button>
      )}
    >
      {items &&
        items.map(({ content, header, ...panelProps }, index) => {
          return (
            <Collapse.Panel key={index} header={<div className="text-xl m-1">{header}</div>} {...panelProps}>
              {content}
            </Collapse.Panel>
          );
        })}
    </Collapse>
  );
};

Accordion.defaultProps = {
  accordion: true,
  expandIconPosition: "right",
  openIcon: <ArrowDownCircle />,
  closeIcon: <ArrowLeftCircle />,
};

export default Accordion;
