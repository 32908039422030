import * as React from "react";
import pattern from "../../assets/haffinity-pattern.jpg";
import Icon from "../icons/Icon";

type Props = {
  children: React.ReactNode;
};

const AuthLayout: React.FC<Props> = ({ children }: Props) => (
  <div className="flex w-full min-h-screen">
    <div className="w-1/2 hidden lg:flex relative">
      <img src={pattern} className="w-full object-cover h-full absolute" />
      <div className="flex flex-col relative z-10 py-7 pl-14 left-0">
        <div className="flex items-center">
          <Icon name="LogoIcon" className="text-7xl text-primary-white" />
          <Icon
            name="LogoTextIcon"
            wrapper={false}
            className="ml-5 text-2xl w-24 text-primary-white"
          />
        </div>
        <p className="text-primary-white mt-auto">© All Rights Reserved 2021</p>
      </div>
    </div>
    <div className="w-full lg:w-1/2 flex p-4 flex-col items-center justify-center">
      <div className="flex items-center lg:hidden mt-6">
        <Icon name="LogoIcon" className="text-7xl" />
        <Icon
          name="LogoTextIcon"
          wrapper={false}
          className="ml-5 text-2xl w-24"
        />
      </div>
      <div className="w-480px max-w-full my-auto py-7">{children}</div>
      <p className="text-primary-white lg:hidden">© All Rights Reserved 2021</p>
    </div>
  </div>
);

export default AuthLayout;
