/**
 * Converts Spiciness value to text.
 *
 * @param {(0 | 1 | 2 | 3)} value
 * @return {*}  {("None" | "Mild" | "Medium" | "Hot")}
 */
export const getSpicinessText = (value: 0 | 1 | 2 | 3): "None" | "Mild" | "Medium" | "Hot" => {
  switch (value) {
    case 1:
      return "Mild";
    case 2:
      return "Medium";
    case 3:
      return "Hot";

    default:
      return "None";
  }
};
