import useSWR, { ConfigInterface, responseInterface } from "swr";
import { User } from "./user";
import { Menu } from "./menu";
import fetchHandler from "./handler";
import { VENUE, VENUES } from "../constants/endpoints";

export type Venue = {
  id: string;
  name: string;
  address_line_1: string;
  address_line_2: string;
  city: string;
  state: string;
  country: string;
  postcode: string;
  email: string;
  phone_number: string;
  image: string;
  imageUri: string;
  publishedAt: string | null;
  createdAt: string;
  updatedAt: string;
  user?: User; // TODO: visit this later
  menus?: Menu[]; // TODO: visit this later
};

export type VenuesResponse = Venue[] | [];

const defaultConfig: ConfigInterface = {
  revalidateOnFocus: false,
  // initialData: [
  //   {
  //     id: "1",
  //     name: "Venue 1",
  //     phone: "0423123123",
  //     address: "Address 1",
  //     image: "",
  //     createdAt: "string",
  //     updatedAt: "string",
  //   },
  //   {
  //     id: "2",
  //     name: "Venue 2",
  //     phone: "0423123123",
  //     address: "Address 1",
  //     image: "",
  //     createdAt: "string",
  //     updatedAt: "string",
  //   },
  // ],
};

export const useFetchVenue = (
  id: string,
  config: ConfigInterface = defaultConfig
): responseInterface<VenuesResponse, any> => useSWR(VENUE(id), fetchHandler, config);

export const useFetchVenues = (config: ConfigInterface = defaultConfig): responseInterface<VenuesResponse, any> =>
  useSWR(VENUES, fetchHandler, config);
