import * as React from "react";

function SvgMenusIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 18" fill="none" {...props}>
      <path
        d="M10 4.833A3.333 3.333 0 006.667 1.5h-5V14H7.5a2.5 2.5 0 012.5 2.5m0-11.667V16.5m0-11.667A3.333 3.333 0 0113.333 1.5h5V14H12.5a2.5 2.5 0 00-2.5 2.5"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgMenusIcon;
