import { Form } from "antd";
import * as React from "react";

import Field from "components/form/Field";
import Select from "components/form/Select";
import VenueSelect from "components/form/VenueSelect";
import { LABEL_BANNER_SECTION_TITLE, BANNER_TYPE, EMPTY_FIELD_ERROR } from "constants/strings";
import FormItemLabel from "components/common/FormItemLabel";

const BannerTypeOptions = [
  {
    label: "Standard",
    value: "Standard",
  },
  {
    label: "More coming soon...",
    value: "",
    disabled: true,
  },
];

type Props = {
  isNew?: boolean;
  isVisible?: boolean;
  venueId?: string;
};

const Settings: React.FC<Props> = (props) => {
  return (
    <>
      <div className="grid grid-cols-3 gap-6">
        <div>
          <Form.Item
            name="title"
            label={<FormItemLabel>{LABEL_BANNER_SECTION_TITLE}</FormItemLabel>}
            rules={[{ required: true, message: EMPTY_FIELD_ERROR }]}
          >
            <Field className="border-0 max-w-full mb-0" type="text" placeholder="Type something..." />
          </Form.Item>
        </div>
        <VenueSelect disabled={!props.isNew} venueId={props.venueId} />
        <div>
          <Form.Item
            name="type"
            label={<FormItemLabel>{BANNER_TYPE}</FormItemLabel>}
            rules={[{ required: true, message: EMPTY_FIELD_ERROR }]}
            initialValue={BannerTypeOptions[0].value}
          >
            <Select placeholder="Select a type" options={BannerTypeOptions} className="max-w-full" />
          </Form.Item>
        </div>
      </div>
    </>
  );
};

Settings.defaultProps = {
  isNew: false,
  isVisible: false,
};

export default Settings;
