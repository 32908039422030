import * as React from "react";

import { Venue } from "fetchers/venue";
import AvailableSections from "./AvailableSections";
import { Section } from "fetchers/app-home-screen";
import DragAndDrop from "components/common/DragAndDrop";
import AppHomeLayout from "./AppSections";
import { Sections } from "./AvailableSections/AvailableSectionItems";
import { DragOverEvent, DragOverlay, DragStartEvent, DragEndEvent } from "@dnd-kit/core";
import { APP_SECTIONS_ID, AVAILABLE_SECTIONS_ID } from "constants/ids";
import AppSectionItem from "./AppSectionItem";
import { arrayMove } from "@dnd-kit/sortable";
import message from "components/common/message";
import { ERROR_SOMETHING_WENT_WRONG } from "constants/strings";
import AppHomepageFooter from "pages/App/AppHomepageFooter";
import CreateAppSection from "components/CreateAppSection";
import { saveAppHomeScreenSections } from "services/appHomeScreenApi";
import { AppSectionsContext } from "hooks/AppSectionsContextProvider";
import BannerSectionDrawer from "components/CreateAppSection/BannerSection/BannerSectionDrawer";
import HighlightsSectionDrawer from "components/CreateAppSection/Highlights/HighlightsSectionDrawer";
import EditCreateMenuDrawer from "components/EditCreateMenu";
import { Banner } from "fetchers/banner";
import { Menu } from "fetchers/menu";
import { Highlights } from "fetchers/highlights";

type Props = {
  venue?: Venue;
};

const AppHomeScreenSettings: React.FC<Props> = (props) => {
  const context = React.useContext(AppSectionsContext);

  const [isLoading, setIsLoading] = React.useState(false);
  const [showSaveButton, setShowSaveButton] = React.useState(false);
  const [cachedSectionIds, setCachedSectionIds] = React.useState<string[]>([]); // used to determine unsaved changes
  const [sectionItems, setSectionItems] = React.useState<{ [key: string]: Sections }>({
    appSections: [], // Key should match APP_SECTIONS_ID
    availableSections: [], // Key should match AVAILABLE_SECTIONS_ID
  });

  const [activeItem, setActiveItem] = React.useState<Section | null>();
  const [activeId, setActiveId] = React.useState<string | null>();

  const [selectedSection, setSelectedSection] = React.useState<Section | null>(null);
  const [isSectionVisible, setSectionVisible] = React.useState(false);

  React.useEffect(() => {
    const venueId = props.venue?.id;

    if (venueId == undefined) {
      return;
    }

    setSectionItems((items) => {
      const cachedSectionIds: string[] = [];

      const appSections = context?.state?.data?.get(venueId)?.items?.map((section: Section) => {
        const dragDropId = getDragAndDropId(APP_SECTIONS_ID, section);

        cachedSectionIds.push(dragDropId);

        return {
          ...section,
          visibility: getVisibility(section),
          dragDropId: dragDropId,
        } as Section;
      }) as Sections;

      const availableSections = context?.state?.data?.get(venueId)?.availableItems?.map((section: Section) => {
        return {
          ...section,
          visibility: getVisibility(section),
          dragDropId: getDragAndDropId(AVAILABLE_SECTIONS_ID, section),
        } as Section;
      }) as Sections;

      setCachedSectionIds(cachedSectionIds);

      return {
        ...items,
        appSections: appSections ?? [],
        availableSections: availableSections ?? [],
      };
    });
  }, [context.state?.data]);

  React.useEffect(() => {
    if (!props.venue) {
      return;
    }
    context.loadSections && context.loadSections(props.venue.id);
  }, [props.venue]);

  React.useEffect(() => {
    checkUnsavedChanges();
  }, [sectionItems]);

  const checkUnsavedChanges = () => {
    const currentSectionsIds = sectionItems.appSections.map((section: Section) => section.dragDropId);
    const hasUnsavedChanges = JSON.stringify(cachedSectionIds) !== JSON.stringify(currentSectionsIds);
    setShowSaveButton(hasUnsavedChanges);
  };

  const getDragAndDropId = (sectionId: string, section: Section) => {
    return `${sectionId}-${section.sectionType}-${section.sectionItem?.id}`;
  };

  const getVisibility = (item: Section) => {
    return item.sectionItem?.visibility;
  };

  const getId = (section: Section) => {
    if (section.sectionType === "Banner") {
      return { bannerId: section.sectionItem?.id };
    } else if (section.sectionType === "Highlight") {
      return { highlightId: section.sectionItem?.id };
    } else {
      return { menuId: section.sectionItem?.id };
    }
  };

  const findContainer = (id: string) => {
    if (id in sectionItems) {
      return id;
    }

    return Object.keys(sectionItems).find((key) => {
      return sectionItems[key].some((item) => item.dragDropId == id);
    });
  };

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;
    const { id } = active;

    const appSectionItem = sectionItems.appSections.find((item) => item.dragDropId == id);
    if (appSectionItem) {
      setActiveItem(appSectionItem);
    }

    const availableSectionsItem = sectionItems.availableSections.find((item) => item.dragDropId == id);
    if (availableSectionsItem) {
      setActiveItem(availableSectionsItem);
    }

    setActiveId(id as string);
  };

  const handleDragOver = (event: DragOverEvent) => {
    const { active, over } = event;

    if (over == null) {
      return;
    }

    const { id } = active;
    const { id: overId } = over;
    // Find the containers
    const activeContainer = findContainer(id as string);
    const overContainer = findContainer(overId as string);

    if (!activeContainer || !overContainer || activeContainer === overContainer) {
      return;
    }

    setSectionItems((prev) => {
      const activeItems = prev[activeContainer];
      const overItems = prev[overContainer];

      // Find the indexes for the items
      const activeIndex = activeItems.findIndex((item) => item.dragDropId === id);
      const overIndex = overItems.findIndex((item) => item.dragDropId === overId);

      let newIndex;

      if (overId in prev) {
        // We're at the root droppable of a container
        newIndex = overItems.length + 1;
      } else {
        const isBelowLastItem =
          over &&
          overIndex === overItems.length - 1 &&
          active.rect.current.translated?.top &&
          active?.rect?.current?.translated?.top > over.rect?.top + over.rect.height;

        const modifier = isBelowLastItem ? 1 : 0;

        newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
      }

      return {
        ...prev,
        [activeContainer]: [...prev[activeContainer].filter((item) => item.dragDropId !== id)],
        [overContainer]: [
          ...prev[overContainer].slice(0, newIndex),
          sectionItems[activeContainer][activeIndex],
          ...prev[overContainer].slice(newIndex, prev[overContainer].length),
        ],
      };
    });
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (over == null) {
      return;
    }

    const { id } = active;
    const { id: overId } = over;

    const activeContainer = findContainer(id as string);
    const overContainer = findContainer(overId as string);

    if (!activeContainer || !overContainer || activeContainer !== overContainer) {
      return;
    }

    const activeIndex = sectionItems[activeContainer].findIndex((item) => item.dragDropId === id);
    const overIndex = sectionItems[overContainer].findIndex((item) => item.dragDropId === overId);

    setActiveId(null);
    setActiveItem(null);

    if (activeIndex !== overIndex) {
      setSectionItems((items) => ({
        ...items,
        [overContainer]: arrayMove(items[overContainer], activeIndex, overIndex),
      }));
    }
  };

  const handleSave = () => {
    const venueId = props.venue?.id;

    if (venueId == undefined) {
      return;
    }

    if (isLoading) {
      setIsLoading(false);
    }

    if (sectionItems.appSections.length == 0) {
      message({
        type: "warning",
        content: "Please add some sections",
        progressBar: false,
        duration: 3,
        key: 1,
      });
      return;
    }

    if (venueId == undefined) {
      message({
        type: "warning",
        content: "Venue doesn't exist: " + props.venue?.id,
        progressBar: false,
        duration: 3,
        key: 1,
      });
      return;
    }

    const data = {
      items: sectionItems.appSections.map((item: Section, index: number) => {
        return {
          itemType: item.sectionType,
          displaySequence: index,
          visibility: getVisibility(item),
          ...getId(item),
        };
      }),
    };

    setIsLoading(true);

    saveAppHomeScreenSections(venueId, data)
      .then((response) => {
        setCachedSectionIds([]);
        context.updateSections && context.updateSections(venueId, response.data);
        message({
          type: "success",
          content: "App Home Screen Sections has been saved successfully.",
          progressBar: false,
          duration: 3,
          key: 1,
        });
      })
      .catch(() => {
        message({
          type: "warning",
          content: ERROR_SOMETHING_WENT_WRONG,
          progressBar: false,
          duration: 6,
          key: 1,
        });
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <DragAndDrop.Container onDragOver={handleDragOver} onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
        <AppHomeLayout
          venue={props.venue}
          dragAndDropSectionId={APP_SECTIONS_ID}
          isLoading={context.state?.isLoading}
          error={context.state?.error}
          sections={sectionItems.appSections}
          onSectionClick={(section: Section) => {
            setSelectedSection(section);
            setSectionVisible(true);
          }}
        />
        <AvailableSections
          dragAndDropSectionId={AVAILABLE_SECTIONS_ID}
          sections={sectionItems.availableSections}
          isLoading={context.state?.isLoading}
          error={context.state?.error}
          venue={props.venue}
          onSectionClick={(section: Section) => {
            setSelectedSection(section);
            setSectionVisible(true);
          }}
        />
        {activeId &&
          activeItem && ( // this is a hack for the bug that doesn't remove the item when the states are nullified
            <DragOverlay>
              {activeId && activeItem && (
                <AppSectionItem id={activeId} key={activeId} useDragHandle={false} section={activeItem} />
              )}
            </DragOverlay>
          )}
      </DragAndDrop.Container>
      {selectedSection && selectedSection.sectionType === "Banner" && isSectionVisible && (
        <BannerSectionDrawer
          destroyOnClose
          style={{ transform: "none !important" }}
          banner={selectedSection.sectionItem as Banner}
          isNew={false}
          venueId={props.venue?.id}
          isVisible={isSectionVisible}
          onClose={() => setSectionVisible(false)}
          onUpdate={() => {
            context.loadSections && props.venue?.id && context.loadSections(props.venue?.id, true);
            setSectionVisible(false);
          }}
        />
      )}
      {selectedSection && selectedSection.sectionType === "Highlight" && isSectionVisible && (
        <HighlightsSectionDrawer
          destroyOnClose
          style={{ transform: "none !important" }}
          highlights={selectedSection.sectionItem as Highlights}
          isNew={false}
          venueId={props.venue?.id}
          isVisible={isSectionVisible}
          onClose={() => setSectionVisible(false)}
          onUpdate={() => {
            context.loadSections && props.venue?.id && context.loadSections(props.venue?.id, true);
          }}
        />
      )}
      {selectedSection && selectedSection.sectionType === "Menu" && isSectionVisible && (
        <EditCreateMenuDrawer
          destroyOnClose
          style={{ transform: "none !important" }}
          menu={selectedSection.sectionItem as Menu}
          isNew={false}
          isVisible={isSectionVisible}
          onClose={() => setSectionVisible(false)}
          onUpdate={() => {
            context.loadSections && props.venue?.id && context.loadSections(props.venue?.id, true);
          }}
        />
      )}
      {!showSaveButton && <CreateAppSection buttonSize="large" />}
      {showSaveButton && <AppHomepageFooter isLoading={isLoading} onSave={handleSave} />}
    </>
  );
};

export default AppHomeScreenSettings;
