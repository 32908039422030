import * as React from "react";

import Modal, { Props as ModalProps } from "./Modal";

export type Props = ModalProps & {
  defaultVisible?: boolean;
  button?: React.ReactNode;
  footer?: ({ handleClose }: any) => React.ReactNode;
  onShow?: () => void;
};

const ModalWithButton: React.FC<Props> = (props) => {
  const { button, defaultVisible, visible, onClose, onShow, footer, ...modalProps } = props;
  const [isModalVisible, setVisible] = React.useState(defaultVisible === true || visible === true ? true : false);

  const handleClose = () => {
    setVisible(false);
    onClose && onClose();
  };

  const showDrawer = () => {
    setVisible(true);
    onShow && onShow();
  };

  return (
    <>
      <span onClick={showDrawer}>{button}</span>

      <Modal
        visible={isModalVisible}
        onClose={handleClose}
        onCancel={handleClose}
        footer={footer ? footer({ handleClose }) : null}
        {...modalProps}
      />
    </>
  );
};

export default ModalWithButton;
