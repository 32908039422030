import * as React from "react";
import { Form as AntForm, Form } from "antd";
import { CheckCircle, Trash2 } from "react-feather";

import Field from "components/form/Field";
import Button from "components/form/Button";
import Select from "components/form/Select";
import Alert from "components/common/Alert";
import TextArea from "components/form/TextArea";
import ModalDelete from "components/ModalDelete";
import {
  LABEL_BANNER_BUTTON_TITLE,
  LABEL_BANNER_BUTTON_TYPE,
  LABEL_BANNER_TITLE,
  EMPTY_FIELD_ERROR,
  LABEL_PHONE_NUMBER,
  LABEL_EMAIL,
  LABEL_WEB_LINK,
} from "constants/strings";
import { BannerItem } from "fetchers/banner";
import ThumbnailBase64 from "components/common/ThumbnailBase64";
import { getImage } from "utils/image_utils";
import FormItemLabel from "components/common/FormItemLabel";
import { ASPECT_RATIO_PORTRAIT, MAX_COUNT_BANNER_TITLE } from "constants/ints";

type Props = {
  bannerItem?: BannerItem;
  onSave: (banner: BannerItem) => void;
  onDelete: (banner: BannerItem) => void;
};

const buttonType = [
  {
    label: "Web link",
    value: "Link",
  },
  {
    label: "Phone number",
    value: "Phone Number",
  },
  {
    label: "Email",
    value: "Email",
  },
  {
    label: "None",
    value: "None",
  },
];

const BannerDetailsForm: React.FC<Props> = (props) => {
  const [form] = AntForm.useForm();
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [selectedButtonType, setSelectedButtonType] = React.useState(props.bannerItem?.type ?? buttonType[0].value);
  const [titleLength, setTitleLength] = React.useState(props.bannerItem?.title.length ?? 0);

  const handleChange = (value: any) => {
    setSelectedButtonType(value);
  };

  const handleDelete = () => {
    setShowDeleteModal(false);
    props.bannerItem && props.onDelete(props.bannerItem);
  };

  const handleSave = (banner: BannerItem) => {
    if (props.bannerItem?.id) {
      banner.id = props.bannerItem?.id;
    }
    props.onSave && props.onSave(banner);
  };

  return (
    <AntForm
      form={form}
      className="px-7 py-5 min-h-full flex flex-col"
      initialValues={{ ...props.bannerItem }}
      onFinish={handleSave}
    >
      <div className="mt-4 pb-1">
        <div className="flex flex-col md:flex-row">
          <div className="flex flex-row mr-4">
            <ThumbnailBase64
              aspectRatio={ASPECT_RATIO_PORTRAIT}
              inputName="image"
              size="x-large"
              form={form}
              imageUri={props.bannerItem?.image ?? getImage(props.bannerItem?.imageURI)}
            />
          </div>
          <div className="flex flex-col flex-1 mt-6 md:mt-0 mb-5 relative">
            <Form.Item
              label={<FormItemLabel>{LABEL_BANNER_TITLE}</FormItemLabel>}
              name="title"
              rules={[
                {
                  required: true,
                  message: "Please enter banner title!",
                },
                {
                  max: MAX_COUNT_BANNER_TITLE,
                  message: "Banner title cannot be longer than " + MAX_COUNT_BANNER_TITLE + " characters",
                },
              ]}
              className="mb-5"
            >
              <TextArea
                autoSize={false}
                placeholder="Type Something..."
                className="border-0 h-24 max-h-24"
                onChange={(e) => {
                  setTitleLength(e.target.value.length);
                }}
              />
            </Form.Item>

            <span className="text-black absolute right-0 bottom-0">
              <i className="text-black-op-50">Character count</i> {titleLength} of 70
            </span>
          </div>
        </div>
        <Alert
          duration={null}
          className="mb-6"
          message={
            <>
              Use the fields below if you wish to add an external link in your banner. Your link must have:
              <ol>
                <li>1- A button title to encourage your users to click onto</li>
                <li>2- A link url to the page you want them to visit</li>
              </ol>
            </>
          }
        />

        <Form.Item
          name="type"
          rules={[
            {
              required: true,
              message: "Please select a button type!",
            },
          ]}
          label={<FormItemLabel>{LABEL_BANNER_BUTTON_TYPE}</FormItemLabel>}
          initialValue="Link"
        >
          <Select
            showSearch
            placeholder="Select a venue"
            options={buttonType}
            className="max-w-full"
            onChange={handleChange}
          />
        </Form.Item>

        {selectedButtonType != "None" && (
          <Form.Item
            label={<FormItemLabel>{LABEL_BANNER_BUTTON_TITLE}</FormItemLabel>}
            name="buttonText"
            rules={[{ required: true, message: EMPTY_FIELD_ERROR }]}
          >
            <Field className="border-0 max-w-full mb-0" type="text" placeholder="Type something..." />
          </Form.Item>
        )}

        {selectedButtonType === "Link" && (
          <Form.Item
            label={<FormItemLabel>{LABEL_WEB_LINK}</FormItemLabel>}
            name="text"
            rules={[{ required: true, message: "Please input a button link." }]}
          >
            <Field className="border-0 max-w-full mb-0" type="text" placeholder="Type url http://..." />
          </Form.Item>
        )}

        {selectedButtonType === "Phone Number" && (
          <Form.Item
            label={<FormItemLabel>{LABEL_PHONE_NUMBER}</FormItemLabel>}
            name="text"
            rules={[{ required: true, message: "Please input a phone number." }]}
          >
            <Field className="border-0 max-w-full mb-0" type="text" placeholder="Type something..." />
          </Form.Item>
        )}

        {selectedButtonType === "Email" && (
          <Form.Item
            label={<FormItemLabel>{LABEL_EMAIL}</FormItemLabel>}
            name="text"
            rules={[{ required: true, message: "Please input a email." }]}
          >
            <Field className="border-0 max-w-full mb-0" type="text" placeholder="Type something..." />
          </Form.Item>
        )}
      </div>
      <div className="flex items-center justify-between pb-4 mt-auto">
        <Button
          type="icon-only"
          color="yellow"
          size="extra-small"
          className="flex items-center justify-center"
          onClick={() => setShowDeleteModal(true)}
          disabled={props.bannerItem == null}
        >
          <Trash2 size={22} />
        </Button>

        <ModalDelete
          visible={showDeleteModal}
          onCancel={() => setShowDeleteModal(false)}
          onClose={() => setShowDeleteModal(false)}
          onOk={handleDelete}
        />

        <Button size="large" color="black" htmlType="submit">
          Save
          <CheckCircle size={24} className="ml-10" />
        </Button>
      </div>
    </AntForm>
  );
};

export default BannerDetailsForm;
