import api from "../services/api";

const fetchHandler = (...urls: string[]): Promise<any> => {
  if (urls.length > 1) {
    return Promise.all(urls.map((url) => api({ url }).then(({ data }) => data)));
  }
  7;

  return api({ url: urls[0] }).then(({ data }) => data);
};
export default fetchHandler;
