import * as React from "react";
import cx from "classnames";

type Props = {
  className: string;
  size?: string;
};

const Font: React.FC<Props> = ({ className, size }) => {
  return (
    <>
      <div className="mb-8">
        <p className="text-black-op-60 mb-3">
          {size} - spacing: 0 - line-height: 1.25
        </p>
        <div className={cx("flex primary-black", className)}>
          <p className="mr-6">The quick brown fox jumps over the lazy dog.</p>
          <p className="mr-6">Regular</p>
          <p className="mr-6 italic">Italic</p>
          <p className="mr-6 font-semibold">SemiBold</p>
          <p className="mr-6 font-bold">Bold</p>
          <p className="mr-6 font-extrabold">ExtraBold</p>
        </div>
      </div>
    </>
  );
};

export default Font;
