import * as React from "react";

import VenuesList from "./VenuesList";
import { Venue } from "fetchers/venue";
import { useContext } from "hooks/contextProvider";
import AppHomeScreenSettings from "./AppHomeScreenSettings";
import { AppSectionsContext } from "hooks/AppSectionsContextProvider";

const Manage: React.FC = () => {
  const { state } = useContext<{ selectedVenues: Venue[] }, any>();
  const appSectionsContext = React.useContext(AppSectionsContext);

  return (
    <>
      <VenuesList
        selectedVenue={appSectionsContext.selectedVenue}
        venues={state.selectedVenues}
        onVenueSelect={(venue) => appSectionsContext.setSelectedVenue && appSectionsContext.setSelectedVenue(venue)}
      />
      <AppHomeScreenSettings key={appSectionsContext.selectedVenue?.id} venue={appSectionsContext.selectedVenue} />
    </>
  );
};

export default Manage;
