import * as React from "react";
import cx from "classnames";
import { Info, XCircle } from "react-feather";
import { default as Progress } from "./Progress";
import { Alert as AntAlert, AlertProps } from "antd";

type OmitTypes = "showIcon" | "type" | "closeText" | "banner" | "action";

export type Props = Omit<AlertProps, OmitTypes> & {
  duration: number | null;
  progressBar?: boolean;
};

const Alert: React.FC<Props> = (props: Props) => {
  const { className, closable, message, progressBar, duration, ...resetProps } = props;

  const [show, setShow] = React.useState(true);
  const [pause, setPause] = React.useState<boolean>(false);

  const handleTogglePause = () => {
    if (progressBar) {
      setPause((pause) => !pause);
    }
  };

  const handleOnFinished = () => {
    setShow(false);
  };

  const handleClose = () => {
    setShow(false);
  };

  if (!show) {
    return null;
  }

  const content = (
    <>
      <div className="flex items-center">
        <Info size={18} className="mr-3 text-primary-white filter-drop-shadow min-w-4.5" />
        <span className="text-sm">{message}</span>
        {closable && (
          <XCircle size={15} className="ml-3 min-w-4 cursor-pointer hover:text-black-op-40" onClick={handleClose} />
        )}
      </div>
      {progressBar && <Progress duration={duration} onFinished={handleOnFinished} pause={pause} />}
    </>
  );

  return (
    <AntAlert
      message={content}
      className={cx("rounded-2lg bg-orchid-10 border-orchid-10 px-3 max-w-md py-1.5", className)}
      onMouseEnter={() => {
        handleTogglePause();
      }}
      onMouseLeave={() => {
        handleTogglePause();
      }}
      {...resetProps}
    />
  );
};

Alert.defaultProps = {
  closable: false,
  duration: 5000,
  progressBar: false,
};

export default Alert;
