import * as React from "react";
import { Inbox } from "react-feather";

const NoSectionsTips: React.FC = () => (
  <div className="bg-orchid-10 text-center p-5 rounded-2lg">
    <Inbox size="70" className="mb-2.5 mx-auto" />
    <p className="mb-2.5 text-m font-bold">No stored sections in this space</p>
    <p className="text-m mb-2.5">
      Menus, highlights and banners not in use in the app homepage will be stored in this space. You can move sections
      in and out from your App homepage layout as needed.
    </p>
  </div>
);

export default NoSectionsTips;
