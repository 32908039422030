import * as React from "react";
import { ChevronRight } from "react-feather";

import Card from "components/common/Card";
import Title from "components/common/Title";
import Avatar from "components/common/Avatar";
import { Venue } from "fetchers/venue";
import { getImage } from "utils/image_utils";
import cx from "classnames";

type Props = {
  venues: Venue[] | undefined;
  selectedVenue?: Venue;
  onVenueSelect: (venue: Venue) => void;
};

const YourVenues: React.FC<Props> = (props) => {
  const handleVenueSelection = (venue: Venue) => {
    props.onVenueSelect(venue);
  };

  return (
    <div>
      <Title size="large" className="mb-2.5">
        Your venues
      </Title>
      {props.venues?.length
        ? props.venues.map((venue: Venue, index: number) => (
            <Card
              key={index}
              variant="large"
              className="mb-1 md:mb-2 h-15 flex items-center overflow-hidden cursor-pointer"
              onClick={() => handleVenueSelection(venue)}
            >
              <Avatar
                src={getImage(venue.imageUri)}
                className="w-20 h-15"
                imgClassName="object-cover venue-image-card rounded-2lg"
              />
              <div
                className={cx(
                  {
                    "font-bold": props.selectedVenue?.id == venue.id,
                  },
                  "ml-5 truncate flex-1"
                )}
                title="Restaurant name eclipsize"
              >
                {venue.name}
              </div>
              <div className="ml-auto w-10 h-16 flex items-center justify-center cursor-pointer hover:bg-gray-50">
                <ChevronRight />
              </div>
            </Card>
          ))
        : "No venues"}
    </div>
  );
};

export default YourVenues;
