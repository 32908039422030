import { AxiosResponse } from "axios";
import { AppHomeScreenSections, SectionItemRequest } from "fetchers/app-home-screen";
import { VENUE_APP_SECTIONS_GET_PATCH } from "../constants/endpoints";
import api from "./api";

// export const sortByDisplaySequence = (items: SectionItem[]): SectionItem[] => {
//   return items.sort((a, b) => a.displaySequence - b.displaySequence);
// };

export const getAppHomeScreenSections = async (
  venueId: string | number
): Promise<AxiosResponse<AppHomeScreenSections>> => {
  return await api({
    url: VENUE_APP_SECTIONS_GET_PATCH(venueId),
    method: "GET",
  });
};

export const saveAppHomeScreenSections = async (
  venueId: string | number,
  data?: SectionItemRequest
): Promise<AxiosResponse<AppHomeScreenSections>> => {
  return await api({
    url: VENUE_APP_SECTIONS_GET_PATCH(venueId),
    method: "PATCH",
    data: data,
  });
};
