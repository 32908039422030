import cx from "classnames";
import * as React from "react";
import { Form, FormInstance } from "antd";
import { MoreVertical, Trash2 } from "react-feather";
import { FormListFieldData, FormListOperation } from "antd/lib/form/FormList";

import Sort from "components/common/Sort";
import Avatar from "components/common/Avatar";
import removeMessage from "components/common/removeMessage";
import DropdownMenu, { DropdownRef } from "components/common/DropdownMenu";
import { DELETE_BANNER, DUPLICATE_BANNER } from "constants/strings";
import { duplicateBanner } from "utils/banner_utils";
import { getImage } from "utils/image_utils";

type Props = {
  key?: string | number;
  className?: string;
  sortItemClassName?: string;
  form: FormInstance;
  bannerItemPath: (string | number)[];
  name: (string | number)[] | string;
  emptyData?: React.ReactNode;
  onBannerItemClick: (index: number) => void;
};

const BannerItems: React.FC<Props> = (props) => {
  const dropDownRef = React.createRef<DropdownRef>();

  const getBannerItemPath = (index: number | string) => {
    return [...props.bannerItemPath, index];
  };

  const handleBannerItemClick = (index: number) => {
    props.onBannerItemClick(index);
  };

  return (
    <Form.List name={props.name}>
      {(fields: FormListFieldData[], { remove, move, add }: FormListOperation) => {
        return (
          <Sort.Container
            items={fields.map((_, index) => ({ id: `banner-item${props.key ? "-" + props.key : ""}-${index}` }))}
            onSortEnd={(oldIndex: number, newIndex: number) => {
              move(oldIndex, newIndex);
            }}
          >
            {fields.length > 0
              ? fields?.map((_, index) => {
                  return (
                    <Sort.Item
                      index={index}
                      useDragHandle
                      handleClassName="mt-2"
                      key={`banner-item${props.key ? "-" + props.key : ""}-${index}`}
                      id={`banner-item${props.key ? "-" + props.key : ""}-${index}`}
                      className={cx(props.sortItemClassName, { hidden: false }, "h-10")}
                      value={
                        <div
                          className={cx("flex w-full items-center cursor-pointer h-10", props.className)}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleBannerItemClick(index);
                          }}
                        >
                          <div className="flex flex-1 items-center bg-primary-white rounded-2lg overflow-hidden shadow h-10">
                            {/* @TODO: get banner image */}
                            <Avatar
                              size="small"
                              className="flex mr-4"
                              src={
                                props.form.getFieldValue([...getBannerItemPath(index), "image"]) ??
                                getImage(props.form.getFieldValue([...getBannerItemPath(index), "imageURI"]))
                              }
                            />
                            <div className="text-m font-bold mr-4">
                              {props.form.getFieldValue([...getBannerItemPath(index), "title"])}
                            </div>

                            <div className="ml-auto flex items-center">
                              <DropdownMenu
                                content={[
                                  <div
                                    key={1}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      dropDownRef.current?.close();
                                      const bannerItems = props.form.getFieldValue("bannerItems") || [];
                                      const banner = bannerItems[index];
                                      if (banner) {
                                        bannerItems.push(duplicateBanner(banner));
                                        props.form.setFieldsValue({
                                          bannerItems: [...bannerItems],
                                        });
                                      }
                                    }}
                                    className="flex items-center cursor-pointer w-full"
                                  >
                                    {DUPLICATE_BANNER}
                                  </div>,
                                  <div
                                    key={2}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      dropDownRef.current?.close();
                                      removeMessage(
                                        add,
                                        remove,
                                        index,
                                        props.form.getFieldValue([...getBannerItemPath(index)])
                                      );
                                    }}
                                    className="flex items-center text-fire cursor-pointer w-full"
                                  >
                                    <Trash2 size={20} className="mr-1" />
                                    {DELETE_BANNER}
                                  </div>,
                                ]}
                                withIcon
                                ref={dropDownRef}
                              >
                                <MoreVertical size={20} />
                              </DropdownMenu>
                            </div>
                          </div>
                        </div>
                      }
                    />
                  );
                })
              : props.emptyData
              ? props.emptyData
              : null}
          </Sort.Container>
        );
      }}
    </Form.List>
  );
};

BannerItems.defaultProps = {
  sortItemClassName: "ml-8 mb-1",
};

export default BannerItems;
