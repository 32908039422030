import * as React from "react";
import { Plus } from "react-feather";
import { FormInstance } from "antd";
import { Item as ItemType, Item } from "fetchers/item";
import ProductDetailsDrawer from "components/ProductDetails/ProductDetailsDrawer";
import AddItemDrawer from "./AddItemDrawer";
import { BUTTON_ADD_PRODUCT } from "constants/strings";

type Props = {
  form: FormInstance;
  closeDropdown?: () => void;
  menuItemPath: (string | number)[];
  openDrawerElement?: React.ReactNode;
  maxNumOfItems?: number;
  onItemsUpdated?: () => void;
};

const AddItem: React.FC<Props> = (props) => {
  const [showAddItemDrawer, setShowAddItemDrawer] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState<ItemType | null>(null);
  const [showViewProductDetails, setShowViewProductDetails] = React.useState(false);

  const handleProductDrawerToggle = () => {
    props.form.validateFields(["venueId"]).then(() => {
      setShowAddItemDrawer(true);
      props.closeDropdown && props.closeDropdown();
    });
  };

  return (
    <div className="flex items-center text-sky" key={1}>
      <div className="flex" onClick={handleProductDrawerToggle}>
        {props.openDrawerElement}
      </div>
      {showAddItemDrawer && (
        <AddItemDrawer
          visible={showAddItemDrawer}
          closeDrawer={() => setShowAddItemDrawer(false)}
          onItemSelect={(item: Item) => {
            setSelectedItem(item);
            setShowViewProductDetails(true);
          }}
          form={props.form}
          menuItemPath={props.menuItemPath}
          maxNumOfItems={props.maxNumOfItems}
          onItemsUpdated={props.onItemsUpdated}
        />
      )}
      {selectedItem && (
        <ProductDetailsDrawer
          destroyOnClose
          style={{ transform: "none !important" }}
          item={selectedItem}
          isVisible={showViewProductDetails}
          onClose={() => setShowViewProductDetails(false)}
        />
      )}
    </div>
  );
};

AddItem.defaultProps = {
  openDrawerElement: (
    <>
      <Plus size={20} className="mr-1" /> <span>{BUTTON_ADD_PRODUCT}</span>
    </>
  ),
};

export default AddItem;
