import * as React from "react";
import { Card } from "antd";
import Icon from "components/icons/Icon";

const cardStyle = {
  width: 424,
  height: 320,
  margin: "auto",
  borderRadius: 20,
  backgroundColor: "white",
  overflow: "hidden",
};

const titleStyle = {
  display: "flex",
  backgroundColor: "black",
  color: "white",
  alignItems: "center",
};

const Help: React.FC = () => {
  return (
    <>
      <div className="mt-6">
        <Card style={cardStyle} className="rounded-xl shadow-m-10">
          <div style={titleStyle} className="text-m text-bold h-8 pl-8">
            Customer Support
          </div>
          <Icon name="SupportIcon" wrapper={false} className="icon1 w-12 h-12 mx-auto mt-5" />
          <div className="text-center mt-5">
            <p className="mb-2.5 text-l font-bold">Here to Help</p>
            <p className="text-m">If you have any general inquiries or feedback regarding</p>
            <p className="text-m">the Haffinity service, please send us an email at</p>
            <p className="text-m">
              <a href="mailto:info@haffinity.com">
                <u>info@haffinity.com</u>
              </a>
              .
            </p>
            <br></br>
            <p className="text-m">If you want to talk to us please contact</p>
            <p className="text-m">Julio Presti (+61) 0499 701 176</p>
            <p className="text-m">
              <a href="mailto:julio.p@haffinity.com">
                <u>julio.p@haffinity.com</u>
              </a>
            </p>
          </div>
        </Card>
      </div>
    </>
  );
};

export default Help;
