import * as React from "react";

function SvgArrowLeftIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="1em" height="1em" viewBox="0 0 8 12" fill="none" {...props}>
      <path
        d="M6.5 11l-5-5 5-5"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgArrowLeftIcon;
