import * as React from "react";
import cx from "classnames";
import { Tabs as AntTabs, TabsProps } from "antd";

type Props = Omit<TabsProps, "size" | "type"> & {
  type?: "black" | "white";
  size?: "medium" | "large";
};

const Tabs: React.FC<Props> = (props: Props) => {
  const { children, className, size, type, ...restProps } = props;
  return (
    <AntTabs className={cx(`size-${size}`, `type-${type}`, className)} {...restProps}>
      {children}
    </AntTabs>
  );
};

Tabs.defaultProps = {
  type: "black",
  size: "medium",
};

export default Tabs;

export const TabPane = AntTabs.TabPane;
