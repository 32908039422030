import * as React from "react";

function SvgVisibleIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width="21"
      height="16"
      viewBox="0 0 21 16"
      fill="currentColor"
      {...props}
    >
      <path
        d="M10.17,15.33c-6.38,0-9.91-6.92-10.06-7.22c-0.14-0.28-0.14-0.61,0-0.89C0.25,6.92,3.79,0,10.17,0
	c6.38,0,9.91,6.92,10.06,7.22c0.14,0.28,0.14,0.61,0,0.89C20.08,8.41,16.54,15.33,10.17,15.33z M2.14,7.67
	c0.84,1.41,3.72,5.67,8.02,5.67c4.3,0,7.18-4.26,8.02-5.67C17.35,6.25,14.47,2,10.17,2C5.84,2,2.98,6.25,2.14,7.67z M10.17,11.17
	c-1.93,0-3.5-1.57-3.5-3.5s1.57-3.5,3.5-3.5s3.5,1.57,3.5,3.5S12.1,11.17,10.17,11.17z M10.17,6.17c-0.83,0-1.5,0.67-1.5,1.5
	s0.67,1.5,1.5,1.5s1.5-0.67,1.5-1.5S10.99,6.17,10.17,6.17z"
      />
    </svg>
  );
}

export default SvgVisibleIcon;
