import axios, { AxiosPromise, Method } from "axios";
import { LOGIN, REFRESH_TOKEN } from "../constants/endpoints";
import { setToken, getAccessToken, getRefreshToken, clearToken } from "./localStorage";

type Params = {
  url: string;
  method?: Method;
  data?: any;
  headers?: any;
  handleAuth?: boolean;
};

export type UiState<T> = {
  isLoading?: boolean;
  error?: string;
  data?: T;
};

const api = (params: Params): AxiosPromise => {
  // axios expects params for get parameters
  let dataKey = "data";
  if (params.method === "GET") {
    dataKey = "params";
  }

  const options = {
    method: params.method || "GET",
    [dataKey]: params.data,
    url: params.url.includes("http") ? params.url : `${process.env.REACT_APP_API_HOST}${params.url}`,
    headers: params.headers || { "Content-Type": "application/json" },
  };

  if (params.handleAuth === undefined || (params.handleAuth !== undefined && params.handleAuth)) {
    axios.interceptors.request.use(
      (config) => {
        const token = getAccessToken();
        if (token) {
          config.headers["Authorization"] = "Bearer " + token;
        }

        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const originalRequest = error.config;
        if (originalRequest.url === LOGIN) {
          return;
        }

        if (error.response.status === 401 && originalRequest.url.includes(REFRESH_TOKEN)) {
          clearToken();
          window.location.href = "/login";
          return Promise.reject(error);
        }

        if (error.response.status === 401 && !originalRequest._retry) {
          const refreshTokenURL = `${process.env.REACT_APP_API_HOST}${REFRESH_TOKEN}`;
          originalRequest._retry = true;
          const res = await axios.post(refreshTokenURL, {
            refresh: getRefreshToken(),
          });
          if (res.status === 200) {
            setToken({ access: res.data.access });
            axios.defaults.headers.common["Authorization"] = "Bearer " + res.data.access;
            return axios(originalRequest);
          }
        }
        return Promise.reject(error);
      }
    );
  }

  return axios(options);
};

export default api;
