import * as React from "react";

import Title from "components/common/Title";
import InfoTooltip from "components/common/InfoTooltip";
import NoSectionsTips from "./NoSectionsTips";
import { Venue } from "fetchers/venue";
import AvailableSectionItems, { Sections } from "./AvailableSectionItems";
import { AVAILABLE_SECTIONS, TOOLTIP_APP_AVAILABLE_SECTIONS } from "constants/strings";
import { Section } from "fetchers/app-home-screen";
import Delayed from "components/Delayed";

type Props = {
  venue?: Venue;
  dragAndDropSectionId: string;
  sections: Sections;
  isLoading?: boolean;
  error?: string;
  onSectionClick: (section: Section) => void;
};

const AvailableSections: React.FC<Props> = (props) => {
  return (
    <div>
      <Title size="large" className="mb-2.5">
        {AVAILABLE_SECTIONS} <InfoTooltip className="ml-2" title={TOOLTIP_APP_AVAILABLE_SECTIONS} />
      </Title>
      <div className="border-orchid-30 border-dashed border-4 rounded-3xl p-4 overflow-x-auto h-65vh">
        {props.isLoading && (
          <Delayed>
            <div>Loading...</div>
          </Delayed>
        )}
        {props.error && <div>{props.error}</div>}
        {props.venue && !props.isLoading ? (
          <AvailableSectionItems
            dragAndDropSectionId={props.dragAndDropSectionId}
            sections={props.sections}
            sortItemClassName="mb-1"
            emptyData={<NoSectionsTips />}
            onSectionClick={props.onSectionClick}
          />
        ) : (
          <p>Please select a venue first</p>
        )}
      </div>
    </div>
  );
};

export default AvailableSections;
