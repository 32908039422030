import { AxiosResponse, Method } from "axios";
import { MODIFIER_DELETE, VENUE_OPTIONS } from "constants/endpoints";
import { Option } from "fetchers/option";
import api from "./api";

export const sortAlphabetical = (items: Option[]): Option[] => {
  return items.sort((a, b) => a.title.localeCompare(b.title));
};

export const getOptions = async (id: string | number): Promise<AxiosResponse<Option[]>> => {
  return await api({
    url: VENUE_OPTIONS(id),
    method: "GET",
  }).then((response) => {
    return {
      ...response,
      data: sortAlphabetical(response.data),
    };
  });
};

export const saveOption = async (
  endpoint: string,
  method: Method,
  data?: Option
): Promise<AxiosResponse<Option[] | Option>> => {
  return await api({
    url: endpoint,
    method: method,
    data: data,
  });
};

export const deleteOption = (option: Option): Promise<AxiosResponse> => {
  if (!option?.venueId || !option?.id) {
    throw new Error("Missing Venue ID or Option Id. ");
  }

  return api({
    url: MODIFIER_DELETE(option.venueId, option.id),
    method: "DELETE",
  });
};
