import EditCreateMenuDrawer from "components/EditCreateMenu";
import Button from "components/form/Button";
import { AppSectionsContext } from "hooks/AppSectionsContextProvider";
import * as React from "react";
import { ArrowRight } from "react-feather";

type Props = {
  onClose: () => void;
};

const Menus: React.FC<Props> = (props) => {
  const context = React.useContext(AppSectionsContext);
  const [visible, setVisible] = React.useState(false);

  return (
    <div className="bg-orchid-10 text-center p-5 rounded-2lg">
      <p className="mb-2.5 text-lg font-bold">Menus</p>
      <p className="text-m mb-2.5">
        Menus are core elements of your App. <br /> Menus help you organise your products in categories.
      </p>
      <img src="/img/menu-in-app.png" className="mx-auto mb-2.5" />
      <Button size="large" color="green" onClick={() => setVisible(true)}>
        Continue
        <ArrowRight size={24} className="ml-10" />
      </Button>
      {visible && (
        <EditCreateMenuDrawer
          destroyOnClose
          style={{ transform: "none !important" }}
          isNew={true}
          isVisible={visible}
          onClose={() => setVisible(false)}
          onUpdate={() => {
            props.onClose();
            context.loadSections && context.selectedVenue?.id && context.loadSections(context.selectedVenue?.id, true);
          }}
        />
      )}
    </div>
  );
};

export default Menus;
