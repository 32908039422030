import useSWR, { ConfigInterface, responseInterface } from "swr";

import { Variant } from "./variant";
import fetchHandler from "./handler";
import { AlternateName } from "./alternate-name";
import { VENUE_OPTIONS } from "constants/endpoints";
import { Item } from "./item";

export type Option = {
  id: string;
  venueId: string;
  displayOrder: number;
  name?: string;
  title: string;
  posId?: string;
  min: number;
  max: number;
  variants: Variant[];
  alternateNames: AlternateName[];
  linkedItems?: Item[];
};

export type VenueOptionsResponse = Option[] | [];

const defaultConfig: ConfigInterface = {
  revalidateOnFocus: false,
  // initialData: [
  //   {
  //     id: "22",
  //     name: "Doshi Option name 2",
  //     title: "Haffinity option title",
  //     min: 1,
  //     max: 3,
  //     variants: [
  //       {
  //         id: "3",
  //         posId: "300053",
  //         name: "Doshi variant name",
  //         title: "Variant Haffinity title",
  //         price: 12,
  //       },
  //       {
  //         id: "23",
  //         posId: "300053",
  //         name: "Doshi variant name 2",
  //         title: "Variant Haffinity title 2",
  //         price: 10,
  //       },
  //     ],
  //   },
  //   {
  //     id: "44",
  //     name: "Doshi Option name 44",
  //     title: "Haffinity option title 44",
  //     min: 1,
  //     max: 2,
  //     variants: [
  //       {
  //         id: "3",
  //         posId: "300053",
  //         name: "do not use 3",
  //         title: "Variant Haffinity title 3",
  //         price: 11,
  //       },
  //       {
  //         id: "23",
  //         posId: "300053",
  //         name: "do not use 23",
  //         title: "Variant Haffinity title 23",
  //         price: 10,
  //       },
  //     ],
  //   },
  // ],
};

const sortAlphabetical = (items: Option[]) => {
  return items.sort((a, b) => a.title.localeCompare(b.title));
};

export const useFetchVenueOptions = (
  id: string | number,
  config: ConfigInterface = defaultConfig
): responseInterface<VenueOptionsResponse, any> => {
  return useSWR(
    VENUE_OPTIONS(id),
    async (url: string) => {
      const data = await fetchHandler(url);
      return sortAlphabetical(data);
    },
    config
  );
};
