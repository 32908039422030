import * as React from "react";
import cx from "classnames";
import * as AllIcon from "../icons";

type IconType = (...args: React.HTMLAttributes<any>[]) => JSX.Element;
type IconsType = { [key: string]: IconType };
type Props = {
  name: string;
  wrapper?: boolean;
  onClick?: () => void;
  className?: string;
  extraClassName?: string;
};

const Icon: React.FC<Props> = (props) => {
  if (!props.name) {
    return null;
  }
  const Icons: IconsType = { ...AllIcon };
  const SingleIcon = Icons[props.name];

  if (!SingleIcon) {
    return null;
  }

  return props.wrapper ? (
    <span
      onClick={() => props.onClick && props.onClick()}
      className={cx(
        "rounded-lg flex items-center justify-center",
        "cursor-pointer transition-colors duration-500",
        props.extraClassName,
        props.className,
        {
          "w-8 h-8 bg-gray-100 text-black text-lg": !props.className,
          "hover:bg-black hover:text-primary-white": !props.className,
        }
      )}
    >
      <SingleIcon />
    </span>
  ) : (
    <SingleIcon
      onClick={() => props.onClick && props.onClick()}
      className={props.className}
    />
  );
};

Icon.defaultProps = {
  wrapper: true,
};

export default Icon;
