import * as React from "react";

function SvgThumbsUpIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="1em" height="1em" viewBox="0 0 21 22" fill="none" {...props}>
      <path
        d="M6.417 20.167h-2.75a1.833 1.833 0 01-1.834-1.834v-6.416a1.833 1.833 0 011.834-1.834h2.75m6.416-1.833V4.583a2.75 2.75 0 00-2.75-2.75l-3.666 8.25v10.084h10.34a1.833 1.833 0 001.833-1.559l1.265-8.25a1.833 1.833 0 00-1.833-2.108h-5.189z"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgThumbsUpIcon;
