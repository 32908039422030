import cx from "classnames";
import * as React from "react";
import { Form, FormInstance } from "antd";
import { MoreVertical, PlusSquare, Trash2 } from "react-feather";
import { FormListFieldData, FormListOperation } from "antd/lib/form/FormList";

import Subcategory from "./Subcategory";
import Field from "components/form/Field";
import Status from "components/form/Status";
import MenuItems from "components/MenuItems";
import { Category as CategoryType } from "fetchers/menu";
import AddItem from "components/AddItemDrawer/AddItem";
import Sort, { SortableAccordionItem } from "components/common/Sort";
import removeMessage, { Add, Remove } from "components/common/removeMessage";
import { default as DropdownMenu, DropdownRef } from "components/common/DropdownMenu";
import { getImage } from "utils/image_utils";
import Thumbnail from "components/common/Thumbnail";
import { VENUE_MENUS_CATEGORY_IMAGE } from "constants/endpoints";
import { ASPECT_RATIO_PORTRAIT } from "constants/ints";

type Props = {
  category?: CategoryType;
  index: number;
  className?: string;
  form: FormInstance;
  add: Add;
  remove: Remove;
  onItemsUpdated?: () => void;
};

const Category: React.FC<Props> = (props) => {
  const menuAddRef = React.useRef<DropdownRef>(null);
  const dropdownRef = React.createRef<DropdownRef>();
  const addRef = React.useRef<(defaultValue: any) => void>(() => null);

  const getFieldName = (field: string): any[] => {
    return ["category", props.index, field];
  };

  const getFieldValue = (field: string) => {
    return props.form.getFieldValue(getFieldName(field));
  };

  const dropdownAddMenu = [
    <AddItem
      key={`category-${props.index}`}
      form={props.form}
      menuItemPath={["categories", props.index, "items"]}
      closeDropdown={() => menuAddRef.current?.close()}
      onItemsUpdated={props.onItemsUpdated}
    />,
    <div
      key={2}
      className="cursor-pointer hover:text-sky"
      onClick={() => {
        addRef.current({
          visible: true,
          items: [],
        });
        menuAddRef.current?.close();
      }}
    >
      Add subcategory
    </div>,
  ];

  return (
    <SortableAccordionItem
      id={`category-${props.index}`}
      index={props.index}
      accordionIndex={props.index}
      header={
        <Form.Item
          className={cx("flex flex-1 flex-col mb-0", props.className, {
            hidden: !!getFieldValue("isDeleted"),
          })}
        >
          <div className="flex items-center mr-5">
            <div className="flex flex-1 items-center rounded-2lg overflow-hidden" style={{ alignItems: "stretch" }}>
              <div className="bg-blue-30 rounded-2lg flex overflow-hidden">
                <Thumbnail
                  aspectRatio={ASPECT_RATIO_PORTRAIT}
                  key={`category-${props.index}`}
                  size="medium"
                  // Todo: Show error message for null values here
                  imageUri={getImage(props.form.getFieldValue(["categories", props.index, "imageUri"]))}
                  actionEndpoint={VENUE_MENUS_CATEGORY_IMAGE(
                    props.form.getFieldValue(["venueId"]),
                    props.form.getFieldValue(["id"]),
                    props.form.getFieldValue(["categories", props.index, "id"])
                  )}
                  form={props.form}
                />

                <Form.Item name={[props.index, "title"]} className="accordion-form-item">
                  <Field className="accordion-form-field" type="text" placeholder="Type category name here" />
                </Form.Item>
              </div>
              <div
                className="flex-1"
                // TODO: Implement this later and expand the category on click
                //  onClick={(e) -> {

                //  }}
              />
              <div className="ml-auto flex items-center">
                <Status
                  withFormItem
                  itemName={[props.index, "visible"]}
                  fullPath={["categories", props.index, "visible"]}
                  isVisible={props.form.getFieldValue(["categories", props.index, "visible"])}
                />

                <DropdownMenu
                  content={[
                    <div
                      className="flex items-center text-fire cursor-pointer w-full"
                      key={4}
                      onClick={() => {
                        dropdownRef.current?.close();
                        removeMessage(
                          props.add,
                          props.remove,
                          props.index,
                          props.form.getFieldValue(["categories", props.index])
                        );
                      }}
                    >
                      <Trash2 size={20} className="mr-1" /> Delete category
                    </div>,
                  ]}
                  withIcon
                  ref={dropdownRef}
                >
                  <MoreVertical size={20} />
                </DropdownMenu>

                <DropdownMenu content={dropdownAddMenu} withIcon ref={menuAddRef}>
                  <PlusSquare size={20} />
                </DropdownMenu>
              </div>
            </div>
          </div>
        </Form.Item>
      }
    >
      <>
        <MenuItems
          form={props.form}
          menuItemPath={["categories", props.index, "items"]}
          name={[props.index, "items"]}
          key={props.index}
          index={props.index}
          onItemsUpdated={props.onItemsUpdated}
        />

        <Form.List name={[props.index, "subcategories"]}>
          {(fields: FormListFieldData[], { add, remove, move }: FormListOperation) => {
            addRef.current = add;
            return (
              <Sort.Container
                items={fields.map((_, index) => ({ id: `subcategory-${index}` }))}
                onSortEnd={(oldIndex: number, newIndex: number) => move(oldIndex, newIndex)}
              >
                {fields.length > 0
                  ? fields?.map((_, subcategoryIndex) => {
                      return (
                        <Subcategory
                          key={`subcategories-${props.index}-${subcategoryIndex}`}
                          form={props.form}
                          index={subcategoryIndex}
                          categoryIndex={props.index}
                          remove={() => remove(subcategoryIndex)}
                          add={add}
                          className={cx({ hidden: false })}
                          onItemsUpdated={props.onItemsUpdated}
                        />
                      );
                    })
                  : null}
              </Sort.Container>
            );
          }}
        </Form.List>
      </>
    </SortableAccordionItem>
  );
};

export default Category;
