import * as React from "react";

import Fonts from "./Fonts";
import Colors from "./Colors";
import Elements from "./Elements";
import Buttons from "./Buttons";
import Dialogues from "./Dialogues";

const UIKit: React.FC = () => {
  return (
    <>
      <Dialogues />
      <Colors />
      <Fonts />
      <Elements />
      <Buttons />
    </>
  );
};

export default UIKit;
