import * as React from "react";
import { FormInstance } from "antd";
import { Plus } from "react-feather";

import Title from "components/common/Title";
import Button from "components/form/Button";
import MenuItems from "components/MenuItems";
import NoStructureTips from "../NoStructureTips";
import AddItem from "components/AddItemDrawer/AddItem";
import { BUTTON_ADD_PRODUCT, HIGHLIGHTS_SECTION_PRODUCTS } from "constants/strings";
import { MAX_PRODUCTS_IN_HIGHLIGHT } from "constants/ints";

type Props = {
  form: FormInstance;
  onItemsUpdated?: () => void;
};

const Structure: React.FC<Props> = (props) => {
  return (
    <div className="mb-6">
      <Title size="large" className="mb-6 w-full items-center">
        <div className="flex items-center mr-auto">{HIGHLIGHTS_SECTION_PRODUCTS}</div>
        <AddItem
          form={props.form}
          menuItemPath={["items"]}
          openDrawerElement={
            <Button color="green" size="medium" className="flex">
              {BUTTON_ADD_PRODUCT} <Plus size={24} className="ml-10" />
            </Button>
          }
          maxNumOfItems={MAX_PRODUCTS_IN_HIGHLIGHT}
          onItemsUpdated={props.onItemsUpdated}
        />
      </Title>
      <div className="mb-6">
        <MenuItems
          sortItemClassName="mb-1"
          form={props.form}
          menuItemPath={["items"]}
          name="items"
          emptyData={<NoStructureTips />}
          onItemsUpdated={props.onItemsUpdated}
        />
      </div>
    </div>
  );
};

export default Structure;
