import cx from "classnames";
import * as React from "react";
import { Input } from "antd";
import { TextAreaProps } from "antd/lib/input/TextArea";

type Props = TextAreaProps & {
  variant?: "default" | "curved";
};

const TextArea: React.FC<Props> = ({ className, variant, ...props }) => {
  return <Input.TextArea {...props} className={cx("textarea", className, variant)} />;
};

TextArea.defaultProps = {
  variant: "default",
};

export default TextArea;
