import * as React from "react";
import cx from "classnames";
import { default as AntButton, ButtonProps } from "antd/lib/button";

type Props = Omit<ButtonProps, "size" | "type"> & {
  color?: "green" | "orchid" | "red" | "yellow" | "white" | "black" | "light";
  size?: "extra-small" | "small" | "medium" | "large";
  type?: "solid" | "outline" | "icon-only";
  raised?: boolean;
};

const Button: React.FC<Props> = (props: Props) => {
  const antButtonProps: ButtonProps & { raised?: boolean } = {
    ...props,
    type: "default",
    size: "middle",
    children: undefined,
  };

  delete antButtonProps.className;
  delete antButtonProps.raised;

  return (
    <AntButton
      className={cx(
        {
          "btn-disabled": props.disabled,
          "btn-raised": props.raised,
        },
        `size-${props.size}`,
        `type-${props.type}`,
        `color-${props.color}`,
        props.className
      )}
      {...antButtonProps}
    >
      {props.children}
    </AntButton>
  );
};

Button.defaultProps = {
  size: "small",
  type: "solid",
  color: "green",
  raised: false,
};

export default Button;
