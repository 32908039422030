import * as React from "react";
import { Plus, Search, X } from "react-feather";

import VenuesAccordion from "components/common/VenuesAccordion";
import { Venue } from "fetchers/venue";
import Field from "components/form/Field";
import { useContext } from "hooks/contextProvider";
import Tabs, { TabPane } from "components/common/Tabs";
import ItemAccordionContent from "./ItemAccordionContent";
import ModifierAccordionContent from "./Modifiers/ModifierAccordionContent";
import Button from "components/form/Button";
import { MODIFIERS, PLACEHOLDER_SEARCH_PRODUCT, TAB_PRODUCTS } from "constants/strings";

const Products: React.FC = () => {
  const { state } = useContext<{ selectedVenues: Venue[] }, any>();
  const [isProductDrawerVisible, setIsProductDrawerVisible] = React.useState(false);
  const [isModifierDrawerVisible, setIsModifierDrawerVisible] = React.useState(false);
  const [query, setQuery] = React.useState("");

  return (
    <>
      <Tabs
        tabBarExtraContent={
          <Field
            name="name"
            variant="curved"
            value={query}
            prefix={<Search />}
            suffix={<X className="cursor-pointer" onClick={() => setQuery("")} />}
            placeholder={PLACEHOLDER_SEARCH_PRODUCT}
            onChange={(event) => setQuery(event.target.value)}
            className="w-auto md:w-96"
          />
        }
      >
        <TabPane tab={TAB_PRODUCTS} key="full-list">
          <VenuesAccordion
            venues={state.selectedVenues}
            contentComponent={(venue) => (
              <ItemAccordionContent
                venue={venue}
                query={query}
                isProductDrawerVisible={isProductDrawerVisible}
                onCloseProductDrawer={() => setIsProductDrawerVisible(false)}
              />
            )}
          />
          <Button
            className="fixed bottom-0 right-0 mr-6 mb-6"
            type="icon-only"
            color="green"
            size="large"
            raised
            onClick={() => setIsProductDrawerVisible(true)}
          >
            <Plus size={42} />
          </Button>
        </TabPane>
        <TabPane tab={MODIFIERS} key="modifiers">
          <VenuesAccordion
            venues={state.selectedVenues}
            contentComponent={(venue) => (
              <ModifierAccordionContent
                venue={venue}
                isModifierDrawerVisible={isModifierDrawerVisible}
                onCloseModifierDrawer={() => setIsModifierDrawerVisible(false)}
              />
            )}
          />
          <Button
            className="fixed bottom-0 right-0 mr-6 mb-6"
            type="icon-only"
            color="green"
            size="large"
            raised
            onClick={() => setIsModifierDrawerVisible(true)}
          >
            <Plus size={42} />
          </Button>
        </TabPane>
      </Tabs>
    </>
  );
};

export default Products;
