import * as React from "react";
import { Plus } from "react-feather";

import Button from "components/form/Button";
import Drawer from "components/common/Drawer";
import { BannerItem } from "fetchers/banner";
import BannerDetailsForm from "./BannerDetailsForm";
import { BUTTON_ADD_BANNER } from "constants/strings";

type Props = {
  closeDropdown?: () => void;
  onSave: (banner: BannerItem) => void;
  onDelete: (banner: BannerItem) => void;
};

const AddBannerDrawer: React.FC<Props> = (props) => {
  const [visible, setVisible] = React.useState(false);

  const handleProductDrawerToggle = () => {
    setVisible(true);
    props.closeDropdown && props.closeDropdown();
  };

  return (
    <div className="flex items-center text-sky" key={1}>
      <Button color="green" size="medium" className="flex ml-auto" onClick={handleProductDrawerToggle}>
        {BUTTON_ADD_BANNER} <Plus size={24} className="ml-10" />
      </Button>
      <Drawer
        destroyOnClose
        style={{ transform: "none !important" }}
        height="100vh"
        width="50%"
        placement="right"
        title="Banner editor"
        visible={visible}
        onClose={() => {
          setVisible(false);
        }}
      >
        <BannerDetailsForm
          onSave={(banner: BannerItem) => {
            setVisible(false);
            props?.onSave(banner);
          }}
          onDelete={(banner: BannerItem) => {
            props?.onDelete(banner);
          }}
        />
      </Drawer>
    </div>
  );
};

export default AddBannerDrawer;
