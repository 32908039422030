import * as React from "react";

import { Meh, Copy, Edit3 } from "react-feather";
import Masonry from "react-masonry-css";
import ModifierEditor from "./ModifierEditor";
import { Option } from "fetchers/option";
import { Variant } from "fetchers/variant";
import { cloneDeep } from "lodash";
import {
  NO_MODIFIERS_HEADER,
  NO_MODIFIERS_MESSAGE,
  MODIFIER_SAVE_SUCCESS,
  ERROR_SOMETHING_WENT_WRONG,
} from "constants/strings";
import { MODIFIER_POST } from "constants/endpoints";
import Card from "components/common/Card";
import Button from "components/form/Button";
import Pagination from "components/common/Pagination";
import message from "components/common/message";
import { formatCurrency } from "utils/currency_utils";
import { useModifiersStore } from "stores/modifiersStore";
import { UiState } from "services/api";
import { Modifier } from "typescript";
import { saveOption } from "services/modifiersApi";
import { MAX_VARIANTS_PER_PAGE_IN_OPTION } from "constants/ints";

type State = Option | null;

type Props = {
  uiState: UiState<Modifier>;
};

const Modifiers: React.FC<Props> = (props) => {
  const { uiState } = props;
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState<State>(null);
  const [state] = useModifiersStore();

  const [, actions] = useModifiersStore();

  const variantItemView = (item: Variant) => {
    return (
      <div key={item.id} className={"flex justify-between mb-3 ml-6"}>
        <p className="text-m">{item.title}</p>
        <p className="font-bold text-m">{formatCurrency(item.price)}</p>
      </div>
    );
  };

  const handleSelectedOption = (index: number) => {
    setSelectedOption(cloneDeep(state.items[index]));
  };

  const handleCloseModal = () => {
    setSelectedOption(null);
  };

  const duplicateOption = (index: number) => {
    if (isLoading) {
      setIsLoading(false);
    }

    const duplicatedOption = cloneDeep(state.items[index]);
    duplicatedOption.title = `Copy of ${duplicatedOption.title}`;
    duplicatedOption.id = "";

    setIsLoading(true);

    const endpoint = MODIFIER_POST(duplicatedOption.venueId);
    saveOption(endpoint, "POST", duplicatedOption)
      .then((response) => {
        actions.addItem && actions.addItem(response.data as Option);

        message({
          type: "success",
          content: MODIFIER_SAVE_SUCCESS,
          progressBar: false,
          duration: 3,
          key: 1,
        });
      })
      .catch(() => {
        message({
          type: "warning",
          content: ERROR_SOMETHING_WENT_WRONG,
          progressBar: false,
          duration: 6,
          key: 1,
        });
      })
      .finally(() => setIsLoading(false));
  };

  const editVariant = (index: number) => {
    // handleSelectedOption(index);
    setSelectedOption(cloneDeep(state.items[index]));
  };

  return (
    <>
      {uiState.isLoading && <div>Loading...</div>}
      {uiState.error && <div>{uiState.error}</div>}
      {state.items.length ? (
        <Masonry
          breakpointCols={{
            default: 4,
            1300: 3,
            950: 2,
            640: 1,
          }}
          className="masonry-grid"
          columnClassName="masonry-grid-column"
        >
          {state.items.map((option: Option, index: number) => (
            <Card key={option.id} onClick={() => handleSelectedOption(index)} className="cursor-pointer hover:shadow-m">
              <span className={"inline-flex items-center text-xl mb-4"}>{option.title}</span>
              <Pagination
                totalItems={option.variants.length}
                itemsPerPage={MAX_VARIANTS_PER_PAGE_IN_OPTION}
                itemView={(index) => {
                  if (index >= option.variants.length) return null;
                  const item = option.variants[index];
                  return variantItemView(item);
                }}
              />
              <hr />
              <span className="flex gap-6 justify-between mt-3">
                <Button
                  type="outline"
                  color="orchid"
                  size="small"
                  loading={isLoading}
                  onClick={(e) => {
                    e.stopPropagation();
                    duplicateOption(index);
                  }}
                >
                  Duplicate
                  <Copy size={20} className="ml-5" />
                </Button>
                <Button
                  type="outline"
                  color="orchid"
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    editVariant(index);
                  }}
                >
                  Edit
                  <Edit3 size={20} className="ml-5" />
                </Button>
              </span>
            </Card>
          ))}
        </Masonry>
      ) : (
        !uiState.isLoading &&
        !uiState.error && (
          <div className="bg-orchid-10 text-center p-5 rounded-2lg w-1/3 mx-auto">
            <Meh size="70" className="mb-2.5 mx-auto" />
            <p className="mb-2.5 text-m font-bold">{NO_MODIFIERS_HEADER}</p>
            <p className="text-m mb-2.5">{NO_MODIFIERS_MESSAGE}</p>
          </div>
        )
      )}
      {selectedOption && (
        <ModifierEditor
          option={selectedOption}
          isVisible={selectedOption != null}
          isNew={false}
          onClose={handleCloseModal}
        />
      )}
    </>
  );
};

export default Modifiers;
