import * as React from "react";

function SvgRightArrowCircleIcon(
  props: React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        d="M9 12l3-3m0 0L9 6m3 3H6m10.5 0a7.5 7.5 0 11-15 0 7.5 7.5 0 0115 0z"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgRightArrowCircleIcon;
