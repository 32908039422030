import * as React from "react";
import cx from "classnames";
import { Form, Input } from "antd";

type Props = {
  className?: string;
  border?: boolean;
  shadow?: boolean;
  isVisible?: boolean;
  itemName?: string | (string | number)[];
  fullPath?: string | (string | number)[];
  withFormItem?: boolean;
  isReadOnly?: boolean;
};

const Status: React.FC<Props> = (props) => {
  const [isVisibility, setIsVisibility] = React.useState(props.isVisible);

  React.useEffect(() => {
    setIsVisibility(props.isVisible);
  }, [props.isVisible, props.fullPath]);

  return (
    <label
      className={cx("inline-flex items-center rounded-full", { "cursor-pointer": props.withFormItem }, props.className)}
    >
      <span
        className={cx("rounded-full w-2 h-2 mr-2", "text-sm text-primary-black", {
          "border border-primary-white": props.border,
          "shadow-xs": props.shadow,
          "bg-pink-300": !isVisibility,
          "bg-green-20": isVisibility,
        })}
      />
      {props.withFormItem ? (
        <Form.Item name={props.itemName} valuePropName="checked" hidden>
          <Input disabled={props.isReadOnly} type="checkbox" onChange={(e) => setIsVisibility(e.target.checked)} />
        </Form.Item>
      ) : null}
      {!props.children ? (isVisibility ? "Visible" : "Hidden") : null}
      {props.children}
    </label>
  );
};

Status.defaultProps = {
  border: false,
  shadow: false,
  isVisible: false,
  withFormItem: false,
  isReadOnly: false,
};

export default Status;
