import * as React from "react";

function SvgSupportIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="1em" height="1em" viewBox="0 0 50 50" fill="none" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={5}
        d="M33.333 43.75v-4.167A8.333 8.333 0 0 0 25 31.25H10.417a8.333 8.333 0 0 0-8.334 8.333v4.167M17.708 22.917a8.333 8.333 0 1 0 0-16.667 8.333 8.333 0 0 0 0 16.667ZM35.417 22.917l4.166 4.166 8.334-8.333"
      />
    </svg>
  );
}

export default SvgSupportIcon;
