import * as React from "react";
import cx from "classnames";

export type Props = {
  className?: string;
  children?: React.ReactNode;
  size?: "small" | "medium" | "large";
};

const Label: React.FC<Props> = (props) => {
  return (
    <span
      className={cx(
        "inline-flex items-center justify-center px-1.5 py-0.5 rounded font-extrabold",
        {
          "text-xs leading-normal": props.size === "small",
          "text-s": props.size === "medium",
          "text-sm": props.size === "large",
        },
        props.className
      )}
    >
      {props.children}
    </span>
  );
};

Label.defaultProps = {
  className: "bg-sky text-primary-white",
  size: "small",
};

export default Label;
