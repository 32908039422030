import * as React from "react";
import Font from "./Font";

const Fonts: React.FC = () => {
  return (
    <>
      <section className="bg-ice-150 rounded my-4 py-5 px-4">
        <h1 className="font-bold text-2xl mb-3">Fonts</h1>
        <Font className="text-xs" size=".text.XS • 0.625rem (10px)" />
        <Font className="text-s" size=".text.S • 0.75rem (12px)" />
        <Font className="text-sm" size=".text.S-M • 0.813rem (13px)" />
        <Font className="text-m" size=".text.M • 0.938rem (15px)" />
        <Font className="text-ml" size=".text.M-L • 1rem (16px)" />
        <Font className="text-l" size=".text.L • 1.125rem (18px)" />
        <Font className="text-xl" size=".text.XL • 1.25rem (20px)" />
        <Font className="text-2xl" size=".text.2XL • 1.375rem (22px)" />
        <Font className="text-3xl" size=".text.3XL • 1.5rem (24px)" />
        <Font className="text-4xl" size=".text.4XL • 1.625rem (26px)" />
        <Font className="text-5xl" size=".text.5XL • 1.75rem (28px)" />
        <Font className="text-6xl" size=".text.6XL • 1.875rem (30px)" />
      </section>
    </>
  );
};

export default Fonts;
