import * as React from "react";
import cx from "classnames";
import { default as Popover, Props as PopoverProps } from "./Popover";

export type Props = Omit<PopoverProps, "visible" | "content"> & {
  withIcon?: boolean;
  childrenClassName?: string;
  visibleClassName?: string;
  content: string[] | React.ReactNode[] | React.ReactNode;
};

export type DropdownRef = {
  close: () => void;
};

const DropdownMenu = React.forwardRef<DropdownRef, Props>(
  (
    { children, content, className, childrenClassName, withIcon, visibleClassName, defaultVisible, ...restProps },
    ref
  ) => {
    const [visible, setVisible] = React.useState(defaultVisible ? defaultVisible : false);

    React.useImperativeHandle(ref, () => ({
      close: () => setVisible(false),
    }));

    const popoverContent = (
      <div className="popover-dropdown-menu flex flex-col">
        {content && Array.isArray(content)
          ? content.map((item: string | React.ReactNode, index: number) => (
              <span key={index} className="item">
                {item}
              </span>
            ))
          : content}
      </div>
    );

    return (
      <Popover
        content={popoverContent}
        className={cx("popover-elements", className)}
        visible={visible}
        onVisibleChange={(v) => setVisible(v)}
        getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement}
        {...restProps}
      >
        <span
          className={cx(
            "flex items-center justify-center",
            {
              "popover-dropdown-menu-with-icon": withIcon,
              "border-black-op-10": withIcon && visible,
              visibleClassName: visible && visibleClassName,
            },
            childrenClassName
          )}
          onClick={(e) => e.stopPropagation()}
        >
          {children}
        </span>
      </Popover>
    );
  }
);

DropdownMenu.defaultProps = {
  withIcon: false,
  trigger: "click",
  placement: "bottomRight",
};

DropdownMenu.displayName = "DropdownMenu";

export default DropdownMenu;
