import * as React from "react";

import Checkbox from "../../../../components/form/Checkbox";

const Checkboxes: React.FC = () => {
  const checkboxes = [
    { disabled: false, defaultChecked: false, text: "Default" },
    { disabled: false, defaultChecked: true, text: "Checked" },
    {
      disabled: false,
      indeterminate: true,
      defaultChecked: true,
      text: "Mixed",
    },
    { disabled: true, defaultChecked: false, text: "Disabled" },
  ];

  return (
    <div className="w-1/4">
      <h2 className="font-bold text-xl mb-10">Checkboxes</h2>
      {checkboxes.map((checkbox, index) => {
        return (
          <div className="flex mb-4" key={index}>
            <div className="w-5/6">{checkbox.text}</div>
            <div className="flex w-1/6 text-m text-center">
              <Checkbox
                disabled={checkbox.disabled}
                defaultChecked={checkbox.defaultChecked}
                indeterminate={!!checkbox.indeterminate}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Checkboxes;
