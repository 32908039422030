import * as React from "react";
import { Venue } from "fetchers/venue";
import Modifiers from "./Modifiers";
import ModifierEditor from "./ModifierEditor";
import { useModifiersStore } from "stores/modifiersStore";
import { getOptions } from "services/modifiersApi";
import { UiState } from "services/api";
import { Modifier } from "typescript";

type Props = {
  venue: Venue;
  isModifierDrawerVisible: boolean;
  onCloseModifierDrawer: () => void;
};

const ModifierAccordionContent: React.FC<Props> = (props) => {
  const [, actions] = useModifiersStore();
  const [uiState, setUiState] = React.useState<UiState<Modifier>>({ isLoading: false });

  React.useEffect(() => {
    setUiState({ isLoading: true });
    getOptions(props.venue.id)
      .then((response) => {
        setUiState({ isLoading: false });
        actions.updateItems && actions.updateItems(response.data);
      })
      .catch((err) => {
        console.error(err);
        setUiState({
          isLoading: false,
          error: err.message,
        });
      });
  }, []);

  return (
    <>
      <Modifiers uiState={uiState} />
      {props.isModifierDrawerVisible && (
        <ModifierEditor isNew={true} isVisible={props.isModifierDrawerVisible} onClose={props.onCloseModifierDrawer} />
      )}
    </>
  );
};

export default ModifierAccordionContent;
