import { Menu } from "fetchers/menu";
import { createStore, createHook } from "react-sweet-state";
import { sortAlphabetical } from "services/menuApi";

const MenusStore = createStore({
  initialState: {
    items: [] as Menu[],
  },
  actions: {
    addItem: (item: Menu) => ({ setState, getState }) => {
      setState({
        items: sortAlphabetical([...getState().items, item]),
      });
    },
    deleteItem: (item: Menu) => ({ setState, getState }) => {
      setState({
        items: sortAlphabetical(getState().items.filter((currentItem) => currentItem.id != item.id)),
      });
    },
    updateItems: (items: Menu[]) => ({ setState }) => {
      setState({
        items: sortAlphabetical(items),
      });
    },
  },
  name: "MenusStore",
});

export const useMenusStore = createHook(MenusStore);
