import * as React from "react";
import Sort from "components/common/Sort";
import cx from "classnames";
import Status from "components/form/Status";
import Bubble from "components/common/Bubble";
import { Section } from "fetchers/app-home-screen";

type Props = {
  id?: string | number;
  section: Section;
  className?: string;
  sortItemClassName?: string;
  useDragHandle?: boolean;
  onSectionClick?: (section: Section) => void;
};

const AppSectionItem: React.FC<Props> = (props) => {
  const handleSectionClick = (section: Section) => {
    props.onSectionClick && props.onSectionClick(section);
  };

  const getTypeName = (item: Section) => {
    if (item.sectionType === "Banner") {
      return "Banner";
    } else if (item.sectionType === "Highlight") {
      return "Highlight";
    } else {
      return "Menu";
    }
  };

  const getColor = (item: Section) => {
    if (item.sectionType === "Banner") {
      return "bg-orange-10";
    } else if (item.sectionType === "Highlight") {
      return "bg-purple-40";
    } else {
      return "bg-orchid-10";
    }
  };

  return (
    <Sort.Item
      id={props.id}
      key={props.id}
      useDragHandle={props.useDragHandle}
      className={cx(props.sortItemClassName, { hidden: false })}
      value={
        <div
          className={cx("flex w-full items-center cursor-pointer", props.className)}
          onClick={() => handleSectionClick(props.section)}
        >
          <div className="flex flex-1 items-center bg-primary-white rounded-2lg overflow-hidden shadow">
            <div className="flex-col p-2">
              <div className="text-m">{props.section.sectionItem.title}</div>
              <div className="flex-row mt-0.5">
                <Bubble className={getColor(props.section)}>
                  <span className="truncate max-w-48">{getTypeName(props.section)}</span>
                </Bubble>
                <Status
                  className="ml-2"
                  withFormItem
                  isVisible={props.section.sectionItem.visibility}
                  isReadOnly={true}
                />
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
};

AppSectionItem.defaultProps = {
  sortItemClassName: "ml-8 mb-1",
};

export default AppSectionItem;
