import * as React from "react";
import { StickyContainer } from "react-sticky";
import { useHistory } from "react-router-dom";

import TopBar from "./TopBar";
import SideBar from "./SideBar";
import { Venue } from "fetchers/venue";
import { ContextProvider } from "hooks/contextProvider";
import { getAccessToken } from "services/localStorage";

type Props = {
  children: React.ReactNode;
};

const Layout: React.FC<Props> = (props) => {
  const { children } = props;
  const history = useHistory();
  const [isHidden, setHideSidebar] = React.useState(true);
  const [isLoaded, setIsLoaded] = React.useState(false);

  React.useEffect(() => {
    if (!getAccessToken()) {
      history.push("/login");
    }
    setIsLoaded(true);
  }, []);

  const handleToggleSidebar = () => {
    setHideSidebar(!isHidden);
    document.body.classList.toggle("shown-sidebar");
  };

  // TODO: If more actions needed move this into another file
  type State = { selectedVenues: Venue[] };
  type Action =
    | { type: "SELECT_VENUE"; payload: Venue }
    | { type: "SET_VENUES"; payload: Venue[] }
    | { type: "DESELECT_VENUE"; payload: Venue }
    | { type: "not_available"; payload: any };

  const reducer = (state: State, action: Action) => {
    switch (action.type) {
      case "SELECT_VENUE":
        return {
          ...state,
          selectedVenues: state.selectedVenues.some((venue) => venue.id === action.payload.id)
            ? state.selectedVenues
            : [...state.selectedVenues, action.payload],
        };

      case "SET_VENUES":
        return {
          ...state,
          venues: [...action.payload],
          selectedVenues: [...action.payload.slice(0, 3)],
        };

      case "DESELECT_VENUE":
        return {
          ...state,
          selectedVenues: state.selectedVenues.filter((venue: Venue) => venue.id !== action.payload.id),
        };

      default: {
        throw new Error(`Unhandled action type: ${action.type}`);
      }
    }
  };

  return isLoaded ? (
    <div className="md:flex bg-ice-50">
      <SideBar onToggleSidebar={() => handleToggleSidebar()} hidden={isHidden} />
      <StickyContainer className="overflow-y-scroll w-full">
        <div className="w-full h-screen relative md:p-3 pt-0">
          <ContextProvider initialState={{ venues: [], selectedVenues: [] }} reducer={reducer}>
            <TopBar onToggleSidebar={() => handleToggleSidebar()} />
            {children}
          </ContextProvider>
        </div>
      </StickyContainer>
    </div>
  ) : null;
};

export default Layout;
