import * as React from "react";
import cx from "classnames";
import { Tooltip as AntTooltip, TooltipProps } from "antd";

export type Props = TooltipProps & {
  size?: "small" | "medium" | "large";
};

const Tooltip: React.FC<Props> = (props: Props) => {
  const { size, title, children, className, ...resetProps } = props;

  const antTooltipTitle = <span className={`size-${size}`}>{title}</span>;

  return (
    <AntTooltip title={antTooltipTitle} className={cx(`size-${size}`, className)} {...resetProps}>
      {children}
    </AntTooltip>
  );
};

Tooltip.defaultProps = {
  size: "medium",
};

export default Tooltip;
