import React from "react";
import cx from "classnames";

type Props = {
  size?: "small" | "medium" | "large";
  children: React.ReactNode;
  className?: string;
};
const Title: React.FC<Props> = (props) => {
  return (
    <span
      className={cx(
        "inline-flex items-center font-semibold",
        {
          "text-sm": props.size === "small",
          "text-m": props.size === "medium",
          "text-xl": props.size === "large",
        },
        props.className
      )}
    >
      {props.children}
    </span>
  );
};

Title.defaultProps = {
  size: "small",
};

export default Title;
