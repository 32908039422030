import cx from "classnames";
import * as React from "react";
import { Form, FormInstance } from "antd";
import { MoreVertical, Trash2 } from "react-feather";
import { FormListFieldData, FormListOperation } from "antd/lib/form/FormList";

import Sort from "components/common/Sort";
import Status from "components/form/Status";
import Avatar from "components/common/Avatar";
import removeMessage from "components/common/removeMessage";
import DropdownMenu, { DropdownRef } from "components/common/DropdownMenu";
import { BUTTON_REMOVE_PRODUCT, EDIT_PRODUCT_DETAILS, VIEW_PRODUCT_DETAILS } from "constants/strings";
import { Item } from "fetchers/item";
import EditCreateProductDrawer from "components/ProductDetails/EditCreateProduct";
import ProductDetailsDrawer from "components/ProductDetails/ProductDetailsDrawer";
import { getImage } from "utils/image_utils";
import { formatCurrency } from "utils/currency_utils";

type Props = {
  key?: string | number;
  index?: string | number;
  className?: string;
  sortItemClassName?: string;
  form: FormInstance;
  menuItemPath: (string | number)[];
  name: (string | number)[] | string;
  emptyData?: React.ReactNode;
  onItemsUpdated?: () => void;
};

const MenuItems: React.FC<Props> = (props) => {
  const menuItemsRef = React.createRef<DropdownRef>();
  const [showEditProductDetails, setShowEditProductDetails] = React.useState(false);
  const [showViewProductDetails, setShowViewProductDetails] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState<Item | null>(null);

  const getMenuItemPath = (menuItemIndex: number | string) => {
    return [...props.menuItemPath, menuItemIndex];
  };

  const getTitle = (index: number | string) => {
    return (
      props.form.getFieldValue([...getMenuItemPath(index), "name"]) ||
      props.form.getFieldValue([...getMenuItemPath(index), "title"])
    );
  };

  const getItemPrice = (index: number | string) => {
    return props.form.getFieldValue([...getMenuItemPath(index), "price"]);
  };

  return (
    <>
      <Form.List name={props.name}>
        {(fields: FormListFieldData[], { remove, move, add }: FormListOperation) => {
          return (
            <Sort.Container
              items={fields.map((_, index) => ({ id: `menu-item${props.index ? "-" + props.index : ""}-${index}` }))}
              onSortEnd={(oldIndex: number, newIndex: number) => {
                move(oldIndex, newIndex);
              }}
            >
              {fields.length > 0
                ? fields?.map((_, menuItemIndex) => {
                    return (
                      <Sort.Item
                        index={menuItemIndex}
                        useDragHandle
                        id={`menu-item${props.index ? "-" + props.index : ""}-${menuItemIndex}`}
                        key={`menu-item${props.index ? "-" + props.index : ""}-${menuItemIndex}`}
                        className={cx(props.sortItemClassName, { hidden: false })}
                        value={
                          <div
                            className={cx("flex w-full items-center cursor-pointer", props.className)}
                            onClick={(e) => {
                              e.stopPropagation();
                              const item = props.form.getFieldValue([...getMenuItemPath(menuItemIndex)]);
                              setSelectedItem(item);
                              setShowViewProductDetails(true);
                            }}
                          >
                            <div className="flex flex-1 items-center bg-primary-white rounded-2lg overflow-hidden shadow">
                              {/* @TODO: get menu item image */}
                              <Avatar
                                size="small"
                                imgClassName="rounded-2lg"
                                className="flex mr-4"
                                src={getImage(
                                  props.form.getFieldValue([...getMenuItemPath(menuItemIndex), "imageUri"])
                                )}
                              />
                              <div className="text-m font-bold mr-4">{getTitle(menuItemIndex)}</div>
                              <div className="text-m font-bold mr-4">{formatCurrency(getItemPrice(menuItemIndex))}</div>

                              <div className="ml-auto flex items-center">
                                <Status
                                  withFormItem
                                  isReadOnly={true}
                                  itemName={[menuItemIndex, "visible"]}
                                  fullPath={[...getMenuItemPath(menuItemIndex), "visible"]}
                                  isVisible={props.form.getFieldValue([...getMenuItemPath(menuItemIndex), "visible"])}
                                />
                                <DropdownMenu
                                  content={[
                                    <div
                                      key={0}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        const item = props.form.getFieldValue([...getMenuItemPath(menuItemIndex)]);
                                        setSelectedItem(item);
                                        setShowViewProductDetails(true);
                                      }}
                                      className="flex items-center cursor-pointer w-full"
                                    >
                                      {VIEW_PRODUCT_DETAILS}
                                    </div>,
                                    <div
                                      key={1}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        const item = props.form.getFieldValue([...getMenuItemPath(menuItemIndex)]);
                                        setSelectedItem(item);
                                        setShowEditProductDetails(true);
                                      }}
                                      className="flex items-center cursor-pointer w-full"
                                    >
                                      {EDIT_PRODUCT_DETAILS}
                                    </div>,
                                    <div
                                      key={2}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        menuItemsRef.current?.close();
                                        removeMessage(
                                          add,
                                          remove,
                                          menuItemIndex,
                                          props.form.getFieldValue([...getMenuItemPath(menuItemIndex)])
                                        );
                                        props.onItemsUpdated && props.onItemsUpdated();
                                      }}
                                      className="flex items-center text-fire cursor-pointer w-full"
                                    >
                                      <Trash2 size={20} className="mr-1" />
                                      {BUTTON_REMOVE_PRODUCT}
                                    </div>,
                                  ]}
                                  withIcon
                                  ref={menuItemsRef}
                                >
                                  <MoreVertical size={20} />
                                </DropdownMenu>
                              </div>
                            </div>
                          </div>
                        }
                      />
                    );
                  })
                : props.emptyData
                ? props.emptyData
                : null}
            </Sort.Container>
          );
        }}
      </Form.List>
      {selectedItem && (
        <EditCreateProductDrawer
          key={selectedItem.id}
          destroyOnClose
          style={{ transform: "none !important" }}
          item={selectedItem}
          isNew={false}
          isVisible={showEditProductDetails}
          onClose={() => {
            setShowEditProductDetails(false);
          }}
        />
      )}
      {selectedItem && (
        <ProductDetailsDrawer
          destroyOnClose
          style={{ transform: "none !important" }}
          item={selectedItem}
          isVisible={showViewProductDetails}
          onClose={() => setShowViewProductDetails(false)}
        />
      )}
    </>
  );
};

MenuItems.defaultProps = {
  sortItemClassName: "pl-8 mb-1",
};

export default MenuItems;
