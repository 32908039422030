import * as React from "react";

function SvgArrowCircleDownIcon(
  props: React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg width="1em" height="1em" viewBox="0 0 23 23" fill="none" {...props}>
      <path
        d="M7.333 11.5l4.167 4.167m0 0l4.167-4.167M11.5 15.667V7.333M1.083 11.5c0-5.753 4.664-10.417 10.417-10.417S21.917 5.747 21.917 11.5 17.253 21.917 11.5 21.917 1.083 17.253 1.083 11.5z"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgArrowCircleDownIcon;
