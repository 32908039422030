import cx from "classnames";
import * as React from "react";
import { Form, FormInstance } from "antd";
import { MoreVertical, PlusSquare, Trash2 } from "react-feather";

import Field from "components/form/Field";
import Status from "components/form/Status";
import MenuItems from "components/MenuItems";
import { SortableAccordionItem } from "components/common/Sort";
import AddItem from "components/AddItemDrawer/AddItem";
import removeMessage, { Add, Remove } from "components/common/removeMessage";
import { default as DropdownMenu, DropdownRef } from "components/common/DropdownMenu";

type Props = {
  index: number;
  className?: string;
  form: FormInstance;
  add: Add;
  remove: Remove;
  categoryIndex: number;
  onItemsUpdated?: () => void;
};

const Subcategory: React.FC<Props> = (props) => {
  const menuAddRef = React.createRef<DropdownRef>();
  const dropdownRef = React.createRef<DropdownRef>();

  const dropdownAddMenu = [
    <AddItem
      key={`subcategory-${props.index}`}
      form={props.form}
      menuItemPath={["categories", props.categoryIndex, "subcategories", props.index, "items"]}
      closeDropdown={() => menuAddRef.current?.close()}
    />,
  ];

  return (
    <SortableAccordionItem
      id={`subcategory-${props.index}`}
      index={props.index}
      className="pl-8"
      accordionIndex={props.index}
      header={
        <div className={cx("w-full flex", props.className)}>
          <div className="bg-blue-30 rounded-2lg flex overflow-hidden h-9">
            <Form.Item name={[props.index, "title"]} className="accordion-form-item">
              <Field className="accordion-form-field" type="text" placeholder="Type subcategory here" />
            </Form.Item>
          </div>
          <div className="ml-auto flex items-center mr-5">
            <Status
              withFormItem
              itemName={[props.index, "visible"]}
              fullPath={["categories", props.categoryIndex, "subcategories", props.index, "visible"]}
              isVisible={props.form.getFieldValue([
                "categories",
                props.categoryIndex,
                "subcategories",
                props.index,
                "visible",
              ])}
            />
            <DropdownMenu
              content={[
                /* <div key={1} onClick={duplicateSubcategory}>
                  Duplicate this subcategory
                    </div>,
                    <div className="flex items-center text-sky cursor-pointer" key={2} onClick={changeSubcategoryVisibility}>
                      <Eye size={20} className="mr-1" /> Change status to {!getFieldValue("visibility") ? "Visible" : "Hidden"}
                    </div>, */
                <div
                  className="flex items-center text-fire cursor-pointer w-full"
                  key={3}
                  onClick={() => {
                    dropdownRef.current?.close();
                    removeMessage(
                      props.add,
                      props.remove,
                      props.index,
                      props.form.getFieldValue(["categories", props.categoryIndex, "subcategories", props.index])
                    );
                    props.onItemsUpdated && props.onItemsUpdated();
                  }}
                >
                  <Trash2 size={20} className="mr-1" /> Delete subcategory
                </div>,
              ]}
              withIcon
              ref={dropdownRef}
            >
              <MoreVertical size={20} />
            </DropdownMenu>

            <DropdownMenu content={dropdownAddMenu} withIcon ref={menuAddRef}>
              <PlusSquare size={20} />
            </DropdownMenu>
          </div>
        </div>
      }
    >
      <MenuItems
        form={props.form}
        className="mb-1"
        menuItemPath={["categories", props.categoryIndex, "subcategories", props.index, "items"]}
        name={[props.index, "items"]}
        key={`${props.categoryIndex}-${props.index}`}
        index={`${props.categoryIndex}-${props.index}`}
        onItemsUpdated={props.onItemsUpdated}
      />
    </SortableAccordionItem>
  );
};

export default Subcategory;
