import * as React from "react";
import { Popover as AntTooltip, PopoverProps } from "antd";

export type Props = PopoverProps;

const Popover: React.FC<Props> = (props: Props) => {
  const { children, ...resetProps } = props;
  return <AntTooltip {...resetProps}>{children}</AntTooltip>;
};

export default Popover;
