import * as React from "react";

function SvgOrdersIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="1em" height="1em" viewBox="0 0 23 22" fill="none" {...props}>
      <path
        d="M1.5 16l10 5 10-5m-20-5l10 5 10-5m-10-10l-10 5 10 5 10-5-10-5z"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgOrdersIcon;
