import { DrawerProps } from "antd";
import { Form as AntForm } from "antd";
import { HIGHLIGHTS_SECTION_DRAWER_HEADER, SELECT_VENUE_WARNING } from "constants/strings";
import DrawerHeader from "pages/Menus/DrawerHeader";
import * as React from "react";
import { VENUE_HIGHLIGHTS_POST, VENUE_HIGHLIGHTS_PATCH } from "constants/endpoints";
import { SUCCESS_BANNER_DELETED, ERROR_SOMETHING_WENT_WRONG, ERROR_BANNER_DELETED } from "constants/strings";
import message from "components/common/message";
import HighlightsSectionForm from "./HighlightsSectionForm";
import ModalDelete from "components/ModalDelete";
import { Highlights } from "fetchers/highlights";
import Drawer from "components/common/Drawer";
import Button from "components/form/Button";
import { CheckCircle, Trash2 } from "react-feather";
import { deleteHighlights, saveHighlights } from "services/highlightsApi";

type Props = DrawerProps & {
  highlights?: Highlights;
  isNew?: boolean;
  venueId?: string;
  isVisible: boolean;
  onClose?: () => void;
  onUpdate?: () => void;
};

const HighlightsSectionDrawer: React.FC<Props> = (props) => {
  const { highlights, isNew, isVisible, venueId, onClose, ...restProps } = props;
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isHighlightVisible, setIsHighlightVisible] = React.useState(props.highlights?.visibility ?? true);
  const [form] = AntForm.useForm();

  const handleDelete = () => {
    setShowDeleteModal(false);

    if (props.isNew) {
      return;
    }

    if (!props?.venueId || !props?.highlights?.id) {
      return;
    }

    setIsLoading(true);

    deleteHighlights(props.venueId, props.highlights)
      .then(() => {
        message({
          type: "success",
          content: SUCCESS_BANNER_DELETED,
          progressBar: false,
          duration: 3,
          key: 1,
        });
        props.onUpdate && props.onUpdate();
        props.onClose && props.onClose();
      })
      .catch(() => {
        message({
          type: "warning",
          content: ERROR_BANNER_DELETED,
          progressBar: false,
          duration: 6,
          key: 1,
        });
      })
      .finally(() => setIsLoading(false));
  };

  const handleSave = (data: Highlights & { venueId: string }) => {
    let endpoint;

    if (isLoading) {
      setIsLoading(false);
    }

    if (!data?.venueId || !data?.items) {
      message({
        type: "warning",
        content: !data?.items ? "Please add some products" : SELECT_VENUE_WARNING,
        progressBar: false,
        duration: 3,
        key: 1,
      });
      return;
    }

    if (props.isNew) {
      endpoint = VENUE_HIGHLIGHTS_POST(data.venueId);
    } else if (props.highlights?.id) {
      endpoint = VENUE_HIGHLIGHTS_PATCH(data.venueId, props.highlights.id);
    } else {
      message({
        type: "warning",
        content: "Highlight doesn't exist id: " + props.highlights?.id,
        progressBar: false,
        duration: 3,
        key: 1,
      });
      return;
    }

    setIsLoading(true);

    data?.items.forEach((item, index) => {
      item.displaySequence = index;
    });

    data.visibility = isHighlightVisible;

    // TODO: We need to remove `displaySequence` after backend removes it.
    // and maybe `visibility` also.
    saveHighlights(endpoint, props.isNew ? "POST" : "PATCH", data)
      .then(() => {
        message({
          type: "success",
          content: "Highlights section has been saved successfully.",
          progressBar: false,
          duration: 3,
          key: 1,
        });
        props.onUpdate && props.onUpdate();
        props.onClose && props.onClose();
      })
      .catch(() => {
        message({
          type: "warning",
          content: ERROR_SOMETHING_WENT_WRONG,
          progressBar: false,
          duration: 6,
          key: 1,
        });
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <Drawer
        onClose={onClose}
        visible={isVisible}
        key={highlights?.id}
        title={
          <DrawerHeader
            isNew={isNew}
            isVisible={isHighlightVisible}
            title={HIGHLIGHTS_SECTION_DRAWER_HEADER}
            onVisibilityChange={(isVisible) => setIsHighlightVisible(isVisible)}
          />
        }
        footer={
          <div className="flex gap-6 justify-between">
            <Button
              size="large"
              color="red"
              htmlType="button"
              onClick={() => setShowDeleteModal(true)}
              disabled={props.isNew}
            >
              Delete
              <Trash2 size={24} className="ml-10" />
            </Button>

            <Button size="large" color="black" onClick={() => form.submit()} htmlType="submit">
              Publish
              <CheckCircle size={24} className="ml-10" />
            </Button>
          </div>
        }
        {...restProps}
      >
        <HighlightsSectionForm
          highlights={highlights}
          isNew={isNew}
          form={form}
          venueId={venueId}
          closeDrawer={() => onClose && onClose()}
          onDelete={() => setShowDeleteModal(true)}
          onSave={handleSave}
        />
      </Drawer>
      <ModalDelete
        visible={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onClose={() => setShowDeleteModal(false)}
        onOk={handleDelete}
      />
    </>
  );
};

HighlightsSectionDrawer.displayName = "HighlightsSectionDrawer";

export default HighlightsSectionDrawer;
