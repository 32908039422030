import axios from "axios";
import * as React from "react";
import { UploadFile } from "antd/lib/upload/interface";
import { CheckCircle, UploadCloud } from "react-feather";
import { UploadRequestOption } from "rc-upload/lib/interface";
import { wrapFile, previewImage } from "antd/lib/upload/utils";
import { default as Upload, DraggerProps, RcFile } from "antd/lib/upload";

import Button from "./form/Button";
import message from "./common/message";
import { default as Tabs, TabPane } from "./common/Tabs";
import Modal, { Props as ModalProps } from "./common/Modal";
import ImageCrop from "./common/ImageCrop";

const Dragger = Upload.Dragger;

type Props = {
  draggerProps: Omit<DraggerProps, "action"> & { action: DraggerProps["action"]; filename?: string };
  modalProps?: Omit<ModalProps, "children" | "title">;
  selectedImage?: string | null;
  aspectRatio: number;
  activeTab?: "upload" | "stored";
  onSave?: (imageUri: string | undefined) => void;
  onClose?: () => void;
};

type State = {
  fileList: UploadFile[];
  storedFiles: UploadFile[];
  selectedImage: Props["selectedImage"];
  activeTab: Props["activeTab"];
};

const UploadModal: React.FC<Props> = ({
  modalProps,
  draggerProps,
  selectedImage,
  activeTab,
  onSave,
  onClose,
  aspectRatio,
}) => {
  const [state, setState] = React.useState<State>({
    fileList: draggerProps.fileList ? draggerProps.fileList : [],
    storedFiles: [],
    selectedImage,
    activeTab,
  });

  const { customRequest, ...resetDraggerProps } = draggerProps;

  const defaultCustomRequest = async (options: UploadRequestOption) => {
    const { action, data, file, headers, withCredentials, onError, onSuccess } = options;
    const formData = new FormData();

    if (data) {
      Object.keys(data).forEach((key) => {
        formData.append(key, (data as any)[key]);
      });
    }

    formData.append(draggerProps.filename ? draggerProps.filename : "image", file);

    const newFile = wrapFile(file as RcFile);

    newFile.status = "uploading";

    newFile.thumbUrl = await previewImage(file as RcFile).then((dataURL) => dataURL);

    updateFileList(newFile);

    await axios
      .put(action.includes("http") ? action : `${process.env.REACT_APP_API_HOST}${action}`, formData, {
        withCredentials,
        headers,
        onUploadProgress: ({ total, loaded }) => {
          newFile.percent = Math.round((loaded / total) * 100);
          updateFileList(newFile);
          // onProgress && onProgress({ percent: Math.round((loaded / total) * 100) })
        },
      })
      .then(({ data: { imageURI }, request }) => {
        // Change file status to done and selectedImage
        newFile.status = "done";
        newFile.thumbUrl = imageURI;
        setState((prevState) => ({ ...prevState, selectedImage: newFile.uid }));
        updateFileList(newFile);
        onSuccess && onSuccess(imageURI, request);
      })
      .catch(({ response }) => {
        // Remove File from fileList
        setState((prevState) => {
          const fileList = [...prevState.fileList];
          const removedFileIndex = findFileIndex(newFile, fileList);

          if (removedFileIndex !== -1) {
            fileList.splice(removedFileIndex, 1);
          }

          return {
            ...prevState,
            fileList,
          };
        });

        // TODO: check backend error message schema
        const content = response?.error ? response?.error : "Internal Server Error. Sorry something went wrong.";

        message({
          type: "warning",
          content: content,
          progressBar: false,
          duration: 20,
          key: newFile.uid,
        });
        onError && onError(response);
      });
  };

  const findFileIndex = (file: UploadFile, fileList: UploadFile[]) => {
    const matchKey = file.uid !== undefined ? "uid" : "name";
    return fileList.findIndex((item) => item[matchKey] === file[matchKey]);
  };

  const updateFileList = (file: UploadFile) => {
    setState((prevState) => {
      const nextFileList = [...prevState.fileList];
      const fileIndex = findFileIndex(file, nextFileList);

      if (fileIndex === -1) {
        nextFileList.unshift(file);
      } else {
        nextFileList[fileIndex] = file;
      }

      return { ...prevState, fileList: nextFileList };
    });
  };

  const handleOnSave = () => {
    const index = state.fileList.findIndex((file: UploadFile) => file.uid === state.selectedImage);
    onSave && onSave(state.fileList[index].thumbUrl);
  };

  return (
    <Modal {...modalProps} onClose={onClose} onCancel={onClose} title="Image manager">
      <div className="p-5 pt-2">
        <Tabs
          activeKey={state.activeTab}
          className="overflow-visible relative"
          onChange={(activeKey) =>
            setState((currentState) => ({ ...currentState, activeTab: activeKey as Props["activeTab"] }))
          }
        >
          <TabPane tab="Upload" key="upload">
            <ImageCrop aspectRatio={aspectRatio}>
              <Dragger
                customRequest={customRequest ? customRequest : defaultCustomRequest}
                listType="picture-card"
                showUploadList={{
                  showDownloadIcon: false,
                  showPreviewIcon: false,
                  showRemoveIcon: true,
                }}
                maxCount={1}
                {...resetDraggerProps}
              >
                <div className="flex flex-col items-center justify-center p-5 bg-orchid-10 hover:bg-orchid-20">
                  <UploadCloud size={70} className="mb-2.5" />
                  <div className="text-m">
                    <p className="font-bold mb-2.5">Drag and drop your file into this space</p>
                    <p>Supported files: .jpg .jpeg .png .gif</p>
                  </div>
                  <Button
                    type="outline"
                    size="medium"
                    color="black"
                    className="mt-2.5 text-black hover:text-primary-white"
                  >
                    Choose file
                  </Button>
                </div>
              </Dragger>
            </ImageCrop>
          </TabPane>
        </Tabs>

        <div className="flex">
          <Button
            color="black"
            size="large"
            className="border-primary-white mt-10 ml-auto"
            onClick={handleOnSave}
            disabled={!state.selectedImage}
          >
            Save
            <CheckCircle size={24} className="ml-10" />
          </Button>
        </div>
      </div>
    </Modal>
  );
};

UploadModal.defaultProps = {
  draggerProps: {
    name: "files",
    maxCount: 1,
    accept: ".png, .jpg, .jpeg",
    action: "",
    fileList: [],
    multiple: false,
    showUploadList: {
      showDownloadIcon: false,
      showPreviewIcon: false,
      showRemoveIcon: true,
    },
    filename: "image",
  },
  selectedImage: null,
  activeTab: "upload",
};

export default UploadModal;
