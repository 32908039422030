import * as React from "react";

function SvgHealthIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 18" fill="none" {...props}>
      <path
        d="M18.333 9H15l-2.5 7.5-5-15L5 9H1.667"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgHealthIcon;
