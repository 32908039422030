import * as React from "react";

function SvgCalendarIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 20" fill="none" {...props}>
      <path
        d="M12.333 1.667V5M5.667 1.667V5M1.5 8.333h15m-13.333-5h11.666c.92 0 1.667.747 1.667 1.667v11.667c0 .92-.746 1.666-1.667 1.666H3.167c-.92 0-1.667-.746-1.667-1.666V5c0-.92.746-1.667 1.667-1.667z"
        stroke="#EEE"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgCalendarIcon;
