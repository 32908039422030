import Tabs, { TabPane } from "components/common/Tabs";
import { Venue } from "fetchers/venue";
import { AppSectionsContextProvider } from "hooks/AppSectionsContextProvider";
import { useContext } from "hooks/contextProvider";
import * as React from "react";

import Manage from "./Manage";

const App: React.FC = () => {
  const { state } = useContext<{ selectedVenues: Venue[] }, any>();

  return (
    <>
      <Tabs className="md:-mx-3 md:px-3 md:-mb-3 md:pb-3">
        <TabPane tab="Manage" key="Manage">
          <div className="grid md:grid-cols-3 md:gap-6">
            <AppSectionsContextProvider
              selectedVenue={state.selectedVenues.length ? state.selectedVenues[0] : undefined}
            >
              <Manage />
            </AppSectionsContextProvider>
          </div>
        </TabPane>
        {/* <TabPane tab="Insights" key="Insights">
          <Insight />
        </TabPane> */}
      </Tabs>
    </>
  );
};

export default App;
