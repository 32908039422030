import * as React from "react";
import { Bookmark, CornerRightUp } from "react-feather";

const NoStructureTips: React.FC = () => (
  <div className="flex items-start">
    <div className="bg-orange-10 text-center p-5 rounded-2lg flex-1 mr-6">
      <Bookmark size="70" className="mx-auto mb-2.5" />
      <p className="mb-2.5 text-m font-bold">Add banners in this group</p>
      <p className="text-m mb-7">
        Banners are amazing when to promote news, events, information or just <br />
        your social pages. Creativity is the only limit. Here are some tips: <br />
        1 - Choose a meaningful title for the banner group. <br />
        2 - Add up to 8 banners to your group. <br />3 - Add an image and a nice title to each banner.
      </p>
      <img src="/img/app-banner.png" className="mx-auto mt-auto" />
    </div>
    <div className="bg-yellow-30 text-center p-5 rounded-2lg">
      <CornerRightUp size="70" className="mb-2.5 mx-auto" />
      <p className="mb-2.5 text-m font-bold">Add a banner</p>
      <p className="text-m">
        Begin by adding a banner <br />
        into this group
      </p>
    </div>
  </div>
);

export default NoStructureTips;
