import { IMAGE_ENDPOINT } from "constants/endpoints";

export const getImage = (
  path: string | null | undefined,
  placeholder = "/img/image_placeholder_portrait.jpg"
): string => {
  if (path == null) {
    return placeholder;
  } else if (isValidHttpUrl(path)) {
    return path;
  } else {
    return IMAGE_ENDPOINT(path);
  }
};

function isValidHttpUrl(url: string) {
  let urlObj;
  try {
    urlObj = new URL(url);
  } catch (_) {
    return false;
  }
  return urlObj.protocol === "http:" || urlObj.protocol === "https:";
}
