import * as React from "react";

function SvgCustomersIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 18" fill="none" {...props}>
      <path
        d="M14.167 16.5v-1.667a3.333 3.333 0 00-3.334-3.333H4.167a3.333 3.333 0 00-3.334 3.333V16.5m18.334 0v-1.667a3.333 3.333 0 00-2.5-3.225m-3.334-10a3.333 3.333 0 010 6.459m-2.5-3.234a3.333 3.333 0 11-6.666 0 3.333 3.333 0 016.666 0z"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgCustomersIcon;
