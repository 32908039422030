import * as React from "react";

import { Venue } from "fetchers/venue";
import Menus from "./Menus";
import EditCreateMenuDrawer from "components/EditCreateMenu";
import { useMenusStore } from "stores/menusStore";
import { getMenus } from "services/menuApi";
import { UiState } from "services/api";
import { Menu } from "fetchers/menu";

type Props = {
  venue: Venue;
  isMenuDrawerVisible: boolean;
  onCloseMenuDrawer: () => void;
};

const MenuAccordionContent: React.FC<Props> = (props) => {
  const [, actions] = useMenusStore();
  const [uiState, setUiState] = React.useState<UiState<Menu>>({ isLoading: false });

  React.useEffect(() => {
    setUiState({ isLoading: true });
    getMenus(props.venue.id)
      .then((response) => {
        setUiState({ isLoading: false });
        actions.updateItems && actions.updateItems(response.data);
      })
      .catch((err) => {
        console.error(err);
        setUiState({
          isLoading: false,
          error: err.message,
        });
      });
  }, []);

  return (
    <>
      <Menus venue={props.venue} uiState={uiState} />
      {props.isMenuDrawerVisible && (
        <EditCreateMenuDrawer
          destroyOnClose
          style={{ transform: "none !important" }}
          isNew={true}
          isVisible={props.isMenuDrawerVisible}
          onClose={props.onCloseMenuDrawer}
        />
      )}
    </>
  );
};

export default MenuAccordionContent;
