import * as React from "react";
import { BookOpen, CornerRightUp } from "react-feather";

const NoCategories: React.FC = () => (
  <div className="flex">
    <div className="bg-orchid-10 text-center p-5 rounded-2lg flex-1 mr-6">
      <BookOpen size="70" className="mx-auto mb-2.5" />
      <p className="mb-2.5 text-m font-bold">Create your digital menu</p>
      <p className="text-m">Make it look awesome!</p>
      <p className="text-m">Here some tips for you to ensure the best visual impact.</p>
      <p className="text-m">
        1 - Use <span className="font-bold">not less</span> than three categories.
      </p>
      <p className="text-m">
        2 - Upload <span className="font-bold">one image</span> per category.
      </p>
      <p className="text-m">3 - Keep improving the look of products.</p>
    </div>
    <div className="bg-orchid-30 text-center p-5 rounded-2lg">
      <CornerRightUp size="70" className="mb-2.5 mx-auto" />
      <p className="mb-2.5 text-m font-bold">Add a category</p>
      <p className="text-m">Begin by adding a category.</p>
      <p className="text-m">
        Use not <span className="font-bold">less than</span> three categories.
      </p>
    </div>
  </div>
);

export default NoCategories;
