import * as React from "react";

import Label from "../../../../components/common/Label";

const Labels: React.FC = () => {
  return (
    <div className="w-1/4">
      <h2 className="font-bold text-xl mb-2">Status</h2>
      <h3 className="font-bold text-l mb-4">
        Coulor, text, icons are always a variable
      </h3>

      <div className="flex mb-4">
        <div className="w-2/6">S</div>
        <div className="flex w-4/6 text-m text-center">
          <div className="w-1/2">
            <Label>LABEL</Label>
          </div>
        </div>
      </div>

      <div className="flex mb-4">
        <div className="w-2/6">M</div>
        <div className="flex w-4/6 text-m text-center">
          <div className="w-1/2">
            <Label size="medium">LABEL</Label>
          </div>
        </div>
      </div>

      <div className="flex mb-4">
        <div className="w-2/6">L</div>
        <div className="flex w-4/6 text-m text-center">
          <div className="w-1/2">
            <Label size="large">LABEL</Label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Labels;
