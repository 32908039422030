import * as React from "react";

import { Venue } from "fetchers/venue";
import Accordion from "components/common/Accordion";

type Props = {
  venues: Venue[] | undefined;
  contentComponent: (venue: Venue) => React.ReactElement<{ venue: Venue }>;
};

const VenuesAccordion: React.FC<Props> = (props) => {
  const getItems = (venues: Venue[]) =>
    venues.map((venue) => ({
      header: venue.name,
      content: props.contentComponent(venue),
    }));

  if (!props.venues) {
    return null;
  }

  /**
   * If we have a single venue, don't show the Accordion
   */
  return props.venues.length == 1 ? (
    <div className="mt-4">{getItems(props.venues)[0].content}</div>
  ) : (
    <Accordion
      items={getItems(props.venues)}
      expandAll={props.venues.length <= 1}
      defaultActiveKey={[0]}
      accordion={props.venues.length > 1}
    />
  );
};

export default VenuesAccordion;
