import * as React from "react";
import { ChevronRight } from "react-feather";

import { Item } from "fetchers/item";
import Card from "components/common/Card";
import Label from "components/common/Label";
import Status from "components/form/Status";
import Avatar from "components/common/Avatar";
import { getImage } from "utils/image_utils";
import { formatCurrency } from "utils/currency_utils";
import ProductDetailsDrawer from "components/ProductDetails/ProductDetailsDrawer";

type Props = {
  item: Item;
};

const ItemAccordionCard: React.FC<Props> = (props) => {
  const [visible, setVisible] = React.useState(false);

  return (
    <>
      <Card
        variant="large"
        className="mb-1 md:mb-2 h-20 flex overflow-hidden hover:shadow-s cursor-pointer"
        onClick={() => setVisible(true)}
      >
        <Avatar src={getImage(props.item.imageUri)} className="flex h-full" size="large" imgClassName="object-cover" />
        <div className="flex flex-1 items-center justify-between ml-2.5 md:ml-5 text-sm max-h-21 ">
          <div className="flex  flex-col space-y-1.5 justify-center">
            <div className="text-m font-bold">
              {props.item.bundledItems && (
                <Label className="mr-1.5 bg-sky text-primary-white">
                  C<span className="hidden sm:block">OMBO</span>
                </Label>
              )}
              <div className="max-w-40 md:max-w-52 truncate">{props.item.title || props.item.name}</div>
            </div>
            <div className="font-bold">{formatCurrency(props.item.price)}</div>
          </div>
          {/*
          <Bubble className="bg-blue-30 uppercase hidden xl:block ml-5">new</Bubble>
          <Bubble className="bg-red-10 hidden xl:block ml-5">
            <AlertTriangle size="15" />
          </Bubble>
           <div className="space-x-12 hidden lg:flex text-sm ml-auto">
            <div className="flex flex-col justify-center">
              <p className="mb-1">Orders</p>
              {/* @TODO: implement api /}
              <div className="font-bold">200</div>
            </div>
            <div className="flex flex-col justify-center">
              <p className="mb-1">Sales</p>
              {/* @TODO: implement api /}
              <div className="font-bold">$12,550</div>
            </div>
            <div className="flex flex-col justify-center">
              <p className="mb-1">Views</p>
              {/* @TODO: implement api /}
              <div className="font-bold">2K</div>
            </div>
            <div className="flex flex-col  min-w-36 justify-center">
              <p className="mb-1">Latest POS update</p>
              {/* @TODO: implement api /}
              <div className="font-bold">12/01/2021 - 12:00 PM</div>
            </div>
          </div> */}
          <Status className="ml-auto lg:ml-12 hidden sm:flex" isVisible={props.item.visible} />
        </div>

        <div className="w-10 h-full flex items-center justify-center cursor-pointer">
          <ChevronRight />
        </div>
      </Card>

      <ProductDetailsDrawer
        destroyOnClose
        style={{ transform: "none !important" }}
        item={props.item}
        isVisible={visible}
        onClose={() => setVisible(false)}
      />
    </>
  );
};

export default ItemAccordionCard;
