import * as React from "react";
import { Form, InputNumber } from "antd";
import { CheckCircle, Trash2 } from "react-feather";

import Field from "components/form/Field";
import Drawer from "components/common/Drawer";
import { Option } from "fetchers/option";
import Button from "components/form/Button";
import Variants from "./Variants";
import {
  ERROR_SOMETHING_WENT_WRONG,
  MODIFIER_EDITOR,
  MODIFIER_TITLE,
  MODIFIER_SAVE_SUCCESS,
  SELECT_VENUE_WARNING,
  SUCCESS_MODIFIER_DELETED,
  ERROR_MODIFIER_DELETED,
  NO_VARIANTS_WARNING,
} from "constants/strings";
import { MODIFIER_PATCH, MODIFIER_POST } from "constants/endpoints";
import message from "components/common/message";
import VenueSelect from "components/form/VenueSelect";
import ModalDelete from "components/ModalDelete";
import FormItemLabel from "components/common/FormItemLabel";
import LinkedItems from "./LinkedItems";
import { deleteOption, saveOption } from "services/modifiersApi";
import { useModifiersStore } from "stores/modifiersStore";

type Props = {
  option?: Option;
  isNew?: boolean;
  isVisible: boolean;
  onClose: (() => void) | undefined;
};

const ModifierEditor: React.FC<Props> = (props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [form] = Form.useForm();
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);

  const [, actions] = useModifiersStore();

  const handleFormFinish = (option: Option) => {
    let endpoint;

    if (isLoading) {
      setIsLoading(false);
    }

    if (!option?.venueId) {
      message({
        type: "warning",
        content: SELECT_VENUE_WARNING,
        progressBar: false,
        duration: 3,
        key: 1,
      });
      return;
    }

    if (option?.variants.length == 0) {
      message({
        type: "warning",
        content: NO_VARIANTS_WARNING,
        progressBar: false,
        duration: 3,
        key: 1,
      });
      return;
    }

    if (props.isNew) {
      endpoint = MODIFIER_POST(option.venueId);
    } else if (props.option?.id) {
      endpoint = MODIFIER_PATCH(option.venueId, props.option.id);
    } else {
      message({
        type: "warning",
        content: "Modifier doesn't exist id: " + props.option?.id,
        progressBar: false,
        duration: 3,
        key: 1,
      });
      return;
    }

    option?.variants.forEach((item, index) => {
      item.displaySequence = index;
    });

    setIsLoading(true);

    saveOption(endpoint, props.isNew ? "POST" : "PATCH", option)
      .then((response) => {
        if (props.isNew) {
          actions.addItem && actions.addItem(response.data as Option);
        } else {
          actions.updateItems && actions.updateItems(response.data as Option[]);
        }
        message({
          type: "success",
          content: MODIFIER_SAVE_SUCCESS,
          progressBar: false,
          duration: 3,
          key: 1,
        });
        props.onClose && props.onClose();
      })
      .catch(() => {
        message({
          type: "warning",
          content: ERROR_SOMETHING_WENT_WRONG,
          progressBar: false,
          duration: 6,
          key: 1,
        });
      })
      .finally(() => setIsLoading(false));
  };

  const handleDelete = () => {
    setShowDeleteModal(false);

    if (props.isNew) {
      return;
    }

    if (!props?.option?.venueId || !props?.option?.id) {
      return;
    }

    setIsLoading(true);

    deleteOption(props.option)
      .then(() => {
        actions.deleteItem && props.option && actions.deleteItem(props.option);
        message({
          type: "success",
          content: SUCCESS_MODIFIER_DELETED,
          progressBar: false,
          duration: 3,
          key: 1,
        });
        props.onClose && props.onClose();
      })
      .catch(() => {
        message({
          type: "warning",
          content: ERROR_MODIFIER_DELETED,
          progressBar: false,
          duration: 6,
          key: 1,
        });
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <Drawer
        visible={props.isVisible}
        width="40%"
        destroyOnClose
        placement="right"
        onClose={props.onClose}
        title={MODIFIER_EDITOR}
        footer={
          <div className="flex gap-6 justify-between">
            <Button
              size="large"
              color="red"
              htmlType="button"
              onClick={() => setShowDeleteModal(true)}
              disabled={props.option == null}
            >
              Delete
              <Trash2 size={24} className="ml-10" />
            </Button>

            <Button size="large" color="black" htmlType="submit" onClick={() => form.submit()} loading={isLoading}>
              Publish
              <CheckCircle size={24} className="ml-10" />
            </Button>
          </div>
        }
        style={{ transform: "none !important" }}
      >
        <Form form={form} initialValues={props.option} onFinish={handleFormFinish} className="p-6">
          <VenueSelect disabled={!props.isNew} venueId={props?.option?.venueId} />
          <LinkedItems items={props.option?.linkedItems} className={"mt-2"} />
          <div className="flex mt-1">
            <Form.Item
              className="mb-0 w-3/5"
              label={<FormItemLabel>{MODIFIER_TITLE}</FormItemLabel>}
              name="title"
              rules={[
                {
                  required: true,
                  message: "Please enter title!",
                },
              ]}
            >
              <Field className="border-0" placeholder="Type Something..." type="text" />
            </Form.Item>

            <div className="flex mb-0 ml-4 w-2/5">
              <Form.Item
                className="mb-0 w-1/2"
                label={<FormItemLabel>Min</FormItemLabel>}
                name="min"
                initialValue={1}
                rules={[
                  {
                    required: true,
                    message: "Min and Max are invalid!",
                    validator: (_, value) => {
                      if (form.getFieldValue("max") < value) {
                        return Promise.reject();
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <InputNumber name="min" min={1} max={10} />
              </Form.Item>
              <Form.Item
                className="mb-0 pl-2 w-1/2"
                label={<FormItemLabel>Max</FormItemLabel>}
                name="max"
                initialValue={1}
                rules={[
                  {
                    required: true,
                    message: "Min and Max are invalid!",
                    validator: (_, value) => {
                      if (form.getFieldValue("min") > value) {
                        return Promise.reject();
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <InputNumber name="max" min={1} max={10} />
              </Form.Item>
            </div>
          </div>
          <Variants form={form} />
        </Form>
      </Drawer>

      <ModalDelete
        visible={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onClose={() => setShowDeleteModal(false)}
        onOk={handleDelete}
      />
    </>
  );
};

export default ModifierEditor;
