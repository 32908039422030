import { Item } from "fetchers/item";
import { createStore, createHook } from "react-sweet-state";
import { sortAlphabetical } from "services/itemApi";

const ItemsStore = createStore({
  initialState: {
    items: [] as Item[],
  },
  actions: {
    addItem: (item: Item) => ({ setState, getState }) => {
      setState({
        items: sortAlphabetical([...getState().items, item]),
      });
    },
    deleteItem: (item: Item) => ({ setState, getState }) => {
      setState({
        items: sortAlphabetical(getState().items.filter((currentItem) => currentItem.id != item.id)),
      });
    },
    updateItems: (items: Item[]) => ({ setState }) => {
      setState({
        items: sortAlphabetical(items),
      });
    },
  },
  name: "ItemsStore",
});

export const useItemsStore = createHook(ItemsStore);
