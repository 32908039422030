import React from "react";
import { FormInstance } from "antd";

import InfoTooltip from "components/common/InfoTooltip";
import ModifiersCardWithDrawer from "../ModifiersCardWithDrawer";
import { Option } from "fetchers/option";
import OptionAccordion from "./OptionAccordion";
import { MODIFIERS, TOOLTIP_PRODUCT_MODIFIERS } from "constants/strings";

type Props = {
  options?: Option[];
  form: FormInstance;
};

const ModifiersCard: React.FC<Props> = (props) => {
  return (
    <ModifiersCardWithDrawer
      form={props.form}
      options={props.options}
      title={
        <span className="flex items-center">
          {MODIFIERS} <InfoTooltip className="ml-2" title={TOOLTIP_PRODUCT_MODIFIERS} />
        </span>
      }
    >
      <div className="w-full">
        <OptionAccordion form={props.form} name="options" />
      </div>
    </ModifiersCardWithDrawer>
  );
};

export default ModifiersCard;
