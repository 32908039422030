import * as React from "react";
import Drawer, { Props as DrawerProps } from "./Drawer";

type Props = DrawerProps & {
  button?: React.ReactNode;
  children: React.ReactNode;
};

export type DrawerRef = {
  close: () => void;
};

const DrawerWithButton = React.forwardRef<DrawerRef, Props>((props, ref) => {
  const { button, onClose, ...restProps } = props;
  const [visible, setVisible] = React.useState(false);

  const handleClose = () => {
    setVisible(false);
  };

  const showDrawer = (e: React.MouseEvent<HTMLDivElement>) => {
    setVisible(true);
    onClose && onClose(e);
  };

  React.useImperativeHandle(ref, () => ({
    close: () => setVisible(false),
  }));

  return (
    <>
      <span onClick={showDrawer}>{button}</span>
      <Drawer onClose={handleClose} visible={visible} {...restProps} />
    </>
  );
});

DrawerWithButton.displayName = "DrawerWithButton";

export default DrawerWithButton;
