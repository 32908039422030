import * as React from "react";
import cx from "classnames";
import { Section } from "fetchers/app-home-screen";
import DragAndDrop from "components/common/DragAndDrop";
import AppSectionItem from "../AppSectionItem";
import { Sections } from "../AvailableSections/AvailableSectionItems";

type Props = {
  dragAndDropSectionId: string;
  key?: string | number;
  className?: string;
  sections: Sections;
  sortItemClassName?: string;
  emptyData?: React.ReactNode;
  onSectionClick: (section: Section) => void;
};

const AppSectionItems: React.FC<Props> = (props) => {
  const handleSectionClick = (section: Section) => {
    props.onSectionClick(section);
  };

  return (
    <DragAndDrop.Section
      id={props.dragAndDropSectionId}
      items={props.sections.map((section: Section) => section.dragDropId)}
    >
      {props.sections.length > 0
        ? props.sections?.map((section: Section) => {
            return (
              <AppSectionItem
                id={section.dragDropId}
                key={section.dragDropId}
                useDragHandle={true}
                className={cx(props.sortItemClassName, { hidden: false })}
                section={section}
                sortItemClassName={props.sortItemClassName}
                onSectionClick={(section) => handleSectionClick(section)}
              />
            );
          })
        : props.emptyData
        ? props.emptyData
        : null}
    </DragAndDrop.Section>
  );
};

AppSectionItems.defaultProps = {
  sortItemClassName: "ml-8 mb-1",
};

export default AppSectionItems;
