import * as React from "react";

import Status from "components/form/Status";

const Statuses: React.FC = () => {
  return (
    <div className="w-1/4">
      <h2 className="font-bold text-xl mb-2">Status</h2>
      <h3 className="font-bold text-l mb-4">Coulor, text, icons are always a variable</h3>
      <div className="flex mb-4">
        <div className="w-2/6" />
        <div className="flex w-4/6 text-m text-center">
          <div className="w-1/2">Text ON</div>
          <div className="w-1/2">Text OFF</div>
        </div>
      </div>

      <div className="flex mb-4">
        <div className="w-2/6">Flat</div>
        <div className="flex w-4/6 text-m text-center">
          <div className="w-1/2">
            <Status />
          </div>
          <div className="w-1/2">
            <Status isVisible />
          </div>
        </div>
      </div>

      <div className="flex mb-4">
        <div className="w-2/6">+ Shadow</div>
        <div className="flex w-4/6 text-m text-center">
          <div className="w-1/2">
            <Status shadow isVisible />
          </div>
          <div className="w-1/2">
            <Status shadow />
          </div>
        </div>
      </div>

      <div className="flex mb-4">
        <div className="w-2/6">+ Border</div>
        <div className="flex w-4/6 text-m text-center">
          <div className="w-1/2">
            <Status border shadow isVisible />
          </div>
          <div className="w-1/2">
            <Status border shadow />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Statuses;
