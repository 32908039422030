import * as React from "react";

function SvgLiveIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 15" fill="none" {...props}>
      <path
        d="M13.817 3.602c.502.512.9 1.12 1.172 1.79a5.619 5.619 0 010 4.225 5.526 5.526 0 01-1.172 1.79m-7.634-.009a5.526 5.526 0 01-1.172-1.79 5.618 5.618 0 010-4.225c.272-.67.67-1.278 1.172-1.79M16.364 1A9.294 9.294 0 0119 7.5a9.294 9.294 0 01-2.636 6.5M3.636 14A9.294 9.294 0 011 7.5c0-2.438.948-4.776 2.636-6.5M11.8 7.5c0 1.016-.806 1.839-1.8 1.839S8.2 8.516 8.2 7.5s.806-1.839 1.8-1.839 1.8.823 1.8 1.839z"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgLiveIcon;
