import cx from "classnames";
import * as React from "react";
import { MoreVertical } from "react-feather";

import Bubble from "components/common/Bubble";
import { useContext } from "hooks/contextProvider";
import { useFetchVenues, Venue } from "fetchers/venue";
import { default as DropdownMenu } from "components/common/DropdownMenu";

const Venues: React.FC = () => {
  const { data } = useFetchVenues();
  const { dispatch, state } = useContext<any, any>();

  React.useEffect(() => {
    if (data?.length) {
      dispatch({ type: "SET_VENUES", payload: data });
    }
  }, [data]);

  const handleVenueClick = (venue: Venue) => {
    const isSelected = state.selectedVenues.some((v: Venue) => v.id === venue.id);

    if (isSelected) {
      dispatch({ type: "DESELECT_VENUE", payload: venue });
      return;
    }

    dispatch({ type: "SELECT_VENUE", payload: venue });
  };

  const dropdownItems = data?.length ? (
    <>
      {" "}
      {data?.map((venue: Venue, index: number) => (
        <Bubble
          className={cx(
            state.selectedVenues.some((v: Venue) => v.id === venue.id) ? "bg-blue-30" : "bg-ice-150",
            {
              "mb-2": data.length - 1 > index,
            },
            "cursor-pointer hover:shadow-xs"
          )}
          key={venue.id}
          onClick={() => handleVenueClick(venue)}
        >
          {venue.name}
        </Bubble>
      ))}
    </>
  ) : (
    []
  );

  return (
    <>
      {state.venues.length > 1 && ( // If it has multiple venues show venue selector
        <span className="flex items-center">
          <DropdownMenu content={dropdownItems}>
            <MoreVertical size={20} />
          </DropdownMenu>
          <span className="flex space-x-1.5">
            {state.selectedVenues.slice(0, 3).map((venue: Venue, index: number) => (
              <Bubble
                key={index}
                className="bg-ice-150"
                showCloseIcon={state.selectedVenues.length > 1}
                onClose={() => dispatch({ type: "DESELECT_VENUE", payload: venue })}
              >
                <span className="truncate max-w-48">{venue.name}</span>
              </Bubble>
            ))}

            {state.selectedVenues?.length > 3 ? (
              <Bubble className="bg-ice-150 ml-2 last:px-2">+{state.selectedVenues?.length - 3}</Bubble>
            ) : null}
          </span>
        </span>
      )}
    </>
  );
};

export default Venues;
