import { AxiosResponse, Method } from "axios";
import { Banner } from "fetchers/banner";
import { VENUE_BANNERS_DELETE } from "../constants/endpoints";
import api from "./api";

export const saveBanner = async (
  endpoint: string,
  method: Method,
  data?: Banner
): Promise<AxiosResponse<Banner[] | Banner>> => {
  return await api({
    url: endpoint,
    method: method,
    data: data,
  });
};

export const deleteBanner = (venueId: string, banner: Banner): Promise<AxiosResponse> => {
  if (!venueId || !banner?.id) {
    throw new Error("Missing Venue ID or Banner Id. ");
  }

  return api({
    url: VENUE_BANNERS_DELETE(venueId, banner.id),
    method: "DELETE",
  });
};
