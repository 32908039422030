import * as React from "react";
import { Plus } from "react-feather";

import Card from "./Card";
import Button from "components/form/Button";
import ModalWithButton, { Props as ModalProps } from "components/common/ModalWithButton";

type Props = Omit<ModalProps, "button"> & {
  title: React.ReactNode;
  className?: string;
  modalTitle?: string;
  modalContent?: React.ReactNode;
};

const CardWithModal: React.FC<Props> = (props) => {
  const CardTitle = (
    <div className="flex">
      <span className="flex items-center">{props.title}</span>
      <ModalWithButton
        centered
        footer={props.footer}
        className="dietary-modal"
        title={props.modalTitle}
        button={
          <Button className="absolute top-7 right-7" type="icon-only" color="green" size="small">
            <Plus size={21} />
          </Button>
        }
      >
        {props.modalContent}
      </ModalWithButton>
    </div>
  );

  return (
    <Card title={CardTitle} className={props.className}>
      <div className="flex flex-row space-x-6">{props.children}</div>
    </Card>
  );
};

export default CardWithModal;
