import { AxiosResponse, Method } from "axios";
import { Banner } from "fetchers/banner";
import { Highlights } from "fetchers/highlights";
import { VENUE_HIGHLIGHTS_DELETE } from "../constants/endpoints";
import api from "./api";

export const saveHighlights = async (
  endpoint: string,
  method: Method,
  data?: Highlights
): Promise<AxiosResponse<Banner[] | Banner>> => {
  return await api({
    url: endpoint,
    method: method,
    data: data,
  });
};

export const deleteHighlights = (venueId: string, highlights: Highlights): Promise<AxiosResponse> => {
  if (!venueId || !highlights?.id) {
    throw new Error("Missing Venue ID or Highlight Id. ");
  }

  return api({
    url: VENUE_HIGHLIGHTS_DELETE(venueId, highlights.id),
    method: "DELETE",
  });
};
