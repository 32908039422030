import { Option } from "fetchers/option";
import { createStore, createHook } from "react-sweet-state";
import { sortAlphabetical } from "services/modifiersApi";

const ModifiersStore = createStore({
  // value of the store on initialisation
  initialState: {
    items: [] as Option[],
  },
  // actions that trigger store mutation
  actions: {
    addItem: (item: Option) => ({ setState, getState }) => {
      setState({
        items: sortAlphabetical([...getState().items, item]),
      });
    },
    deleteItem: (item: Option) => ({ setState, getState }) => {
      setState({
        items: sortAlphabetical(getState().items.filter((currentItem) => currentItem.id != item.id)),
      });
    },
    updateItems: (items: Option[]) => ({ setState }) => {
      setState({
        items: sortAlphabetical(items),
      });
    },
  },
  name: "ModifiersStore",
});

export const useModifiersStore = createHook(ModifiersStore);
