import cx from "classnames";
import * as React from "react";
import { Input, InputProps } from "antd";

type Props = InputProps & {
  variant?: "default" | "curved";
};

const Field: React.FC<Props> = ({ className, variant, ...props }) => {
  return <Input prefix={<span />} {...props} className={cx(className, variant)} />;
};

Field.defaultProps = {
  variant: "default",
};

export default Field;
