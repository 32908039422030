import * as React from "react";

import { NEW_MENU } from "constants/strings";

type Props = {
  title?: React.ReactNode | string;
  isNew?: boolean;
  isVisible?: boolean;
};

const CardHeader: React.FC<Props> = (props) => {
  return (
    <div className="w-full flex items-center justify-between mb-1">
      <span className="text-xl">{props.title}</span>
    </div>
  );
};

CardHeader.defaultProps = {
  title: NEW_MENU,
  isNew: false,
  isVisible: false,
};

export default CardHeader;
