import React, { useState } from "react";

type Props = {
  totalItems: number;
  itemsPerPage: number;
  itemView: (index: number) => React.ReactNode;
};

const Pagination: React.FC<Props> = (props) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const totalPages = Math.ceil(props.totalItems / props.itemsPerPage);

  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };

  const renderPagination = () => {
    if (props.totalItems <= props.itemsPerPage) {
      return null;
    }
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <div key={i}>
          <button
            onClick={(e) => {
              e.stopPropagation();
              onPageChange(i);
            }}
            className="p-1 focus:outline-none"
          >
            <svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" height="10px" width="10px">
              <circle cx="6" cy="6" r="6" fill={currentPage == i ? "black" : "lightgrey"} />
            </svg>
          </button>
        </div>
      );
    }
    return <div className={"flex justify-center"}>{pages}</div>;
  };

  const renderItems = () => {
    const indexies = [];
    for (let i = 0; i <= props.totalItems - 1; i++) {
      indexies.push(i);
    }
    const start = (currentPage - 1) * props.itemsPerPage;
    const end = start + props.itemsPerPage;
    return indexies.slice(start, end).map((index) => {
      return props.itemView(index);
    });
  };

  return (
    <div>
      {renderItems()}
      {renderPagination()}
    </div>
  );
};

export default Pagination;
