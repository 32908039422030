import cx from "classnames";
import * as React from "react";
import { ChevronDown, ChevronUp } from "react-feather";
import { default as AntSelect, SelectProps, SelectValue, OptionProps } from "antd/lib/select";

export type { OptionProps };

type Props = Omit<SelectProps<SelectValue>, "suffixIcon"> & {
  variant?: "default" | "curved";
};

const Select: React.FC<Props> = ({ className, variant, ...props }) => {
  const { onDropdownVisibleChange, ...restProps } = props;
  const [isOpen, setIsOpen] = React.useState<boolean>(props.defaultOpen ? props.defaultOpen : false);

  const handleDropDownVisible = (open: boolean) => {
    setIsOpen(open);

    if (onDropdownVisibleChange) {
      onDropdownVisibleChange(open);
    }
  };

  return (
    <AntSelect
      className={cx("select", className, variant)}
      suffixIcon={isOpen ? <ChevronUp size="19" /> : <ChevronDown size="19" />}
      {...restProps}
      onDropdownVisibleChange={handleDropDownVisible}
    />
  );
};

Select.defaultProps = {
  variant: "default",
};

export default Select;

export const Option = AntSelect.Option;
