import * as React from "react";
import cx from "classnames";

type Props = {
  size?: "big" | "small" | "medium" | "large" | "x-large" | "xx-large" | "xxx-large" | "free";
  imgClassName?: string;
  className?: string;
  src?: string;
  style?: React.CSSProperties;
};
const Avatar: React.FC<Props> = (props) => {
  return (
    <figure
      className={cx(
        {
          "w-96 h-54 rounded-6lg overflow-hidden": props.size === "xxx-large", // 388px x 216px
          "w-48 h-64 rounded-6lg overflow-hidden": props.size === "xx-large", // 192px x 256px
          "w-24 h-32 rounded-3lg overflow-hidden": props.size === "x-large", //  96px x 128px
          "w-15 h-20 rounded-2lg overflow-hidden": props.size === "large", //  60px x 80px
          "w-9 h-12 rounded-2lg overflow-hidden": props.size === "medium", //  36px x 48px
          "w-7.5 h-10 rounded-2lg overflow-hidden": props.size === "small", //  30px x 40px
        },
        props.className
      )}
      style={props.style}
    >
      <img
        className={cx(props.imgClassName, {
          "rounded-2xl": props.size === "big",
          "rounded-xl": props.size === "medium",
          rounded: props.size === "small",
        })}
        src={props.src}
      />
    </figure>
  );
};

Avatar.defaultProps = {
  imgClassName: "object-contain",
};

export default Avatar;
