export const LOGIN = "/accounts/login/";
export const LOGOUT = "/accounts/logout/";
export const REFRESH_TOKEN = "/accounts/token/refresh/";
export const IMAGE_ENDPOINT = (path: string | undefined): string => `${process.env.REACT_APP_API_HOST}${path}`;

export const VENUES = "/workspace/venues/";
export const VENUE = (id: string | number): string => `/workspace/venues/${id}/`;
export const VENUE_IMAGE = (venueId: string | number): string => `/workspace/venues/${venueId}/image/`;

// Product Endpoints
export const VENUE_ITEMS = (id: string | number): string => `/workspace/venues/${id}/items/`;
export const VENUE_ITEM_POST = (venueId: string | number): string => `/workspace/venues/${venueId}/items/`;
export const VENUE_ITEM_PATCH = (venueId: string | number, itemId: string): string =>
  `/workspace/venues/${venueId}/items/${itemId}/`;
export const VENUE_ITEM_DELETE = (venueId: string | number, itemId: string): string =>
  `/workspace/venues/${venueId}/items/${itemId}/`;
export const VENUE_ITEM_IMAGE = (venueId: string | number, itemId: string): string =>
  `/workspace/venues/${venueId}/items/${itemId}/image/`;

// Menu Endpoints
export const VENUE_MENUS = (id: string | number): string => `/workspace/venues/${id}/menus/`;
export const VENUE_MENUS_POST = (id: string | number): string => `/workspace/venues/${id}/menus/`;
export const VENUE_MENUS_PATCH = (id: string | number, menuId: string | number): string =>
  `/workspace/venues/${id}/menus/${menuId}/`;
export const VENUE_MENUS_DELETE = (venueId: string | number, menuId: string | number): string =>
  `/workspace/venues/${venueId}/menus/${menuId}/`;
export const VENUE_MENUS_CATEGORY_IMAGE = (venueId: string | number, menuId: string, categoryId: string): string =>
  `/workspace/venues/${venueId}/menus/${menuId}/category/${categoryId}/image/`;

// Banner Endpoints
export const VENUE_BANNERS_GET = (venueId: string | number): string => `/workspace/venues/${venueId}/banners/`;
export const VENUE_BANNERS_POST = (venueId: string | number): string => `/workspace/venues/${venueId}/banners/`;
export const VENUE_BANNERS_DELETE = (venueId: string | number, bannerId: string | number): string =>
  `/workspace/venues/${venueId}/banners/${bannerId}/`;
export const VENUE_BANNERS_PATCH = (venueId: string | number, bannerId: string | number): string =>
  `/workspace/venues/${venueId}/banners/${bannerId}/`;

// Modifier Endpoints
export const VENUE_OPTIONS = (venueId: string | number): string => `/workspace/venues/${venueId}/options/`;
export const MODIFIER_POST = (venueId: string | number): string => `/workspace/venues/${venueId}/options/`;
export const MODIFIER_PATCH = (venueId: string | number, optionId: string | number): string =>
  `/workspace/venues/${venueId}/options/${optionId}/`;
export const MODIFIER_DELETE = (venueId: string | number, optionId: string | number): string =>
  `/workspace/venues/${venueId}/options/${optionId}/`;

// Highlight Endpoints
export const VENUE_HIGHLIGHTS_GET = (venueId: string | number): string => `/workspace/venues/${venueId}/highlights/`;
export const VENUE_HIGHLIGHTS_POST = (venueId: string | number): string => `/workspace/venues/${venueId}/highlights/`;
export const VENUE_HIGHLIGHTS_DELETE = (venueId: string | number, highlightId: string | number): string =>
  `/workspace/venues/${venueId}/highlights/${highlightId}/`;
export const VENUE_HIGHLIGHTS_PATCH = (venueId: string | number, highlightId: string | number): string =>
  `/workspace/venues/${venueId}/highlights/${highlightId}/`;

// App Section Endpoint
export const VENUE_APP_SECTIONS_GET_PATCH = (venueId: string | number): string =>
  `/workspace/venues/${venueId}/app-homepage/`;
