import * as React from "react";

function SvgCrossIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width="1rem"
      height="1rem"
      viewBox="0 0 14 14"
      fill="currentColor"
      {...props}
    >
      <path
        className="st0"
        d="M1,13L13,1 M1,1L13,13"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgCrossIcon;
