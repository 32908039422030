import * as React from "react";
import { FormInstance } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { CheckCircle, Search, X } from "react-feather";

import Field from "components/form/Field";
import Checkbox from "components/form/Checkbox";
import Accordion from "components/common/Accordion";
import { Option, useFetchVenueOptions } from "fetchers/option";
import { formatCurrency } from "utils/currency_utils";
import Drawer from "components/common/Drawer";
import Button from "components/form/Button";
import { ADD_MODIFIER, PLACEHOLDER_SEARCH_MODIFIER } from "constants/strings";

type Props = {
  venueId: number | string;
  visible?: boolean;
  options?: Option[];
  form: FormInstance;
  onClose: () => void;
};

const AddModifiersDrawer: React.FC<Props> = (props) => {
  const { data } = useFetchVenueOptions(props.venueId);
  const currentOptions = React.useMemo(() => props.form.getFieldValue("options"), []);
  const [selectedOptions, setSelectedOptions] = React.useState<Option[]>(currentOptions || []);
  const [query, setQuery] = React.useState("");

  const handleSelectedOption = (e: CheckboxChangeEvent, id: number | string) => {
    if (!data) {
      return;
    }

    if (e.target.checked) {
      const item = data.find((item) => item.id == id);
      if (item) {
        setSelectedOptions((items) => [...items, { ...item }]);
      }
    } else {
      const item = data.find((item) => item.id == id);
      if (item) {
        setSelectedOptions((items) => items.filter((i) => i.id != item.id));
      }
    }
  };

  const handleAddOption = () => {
    props.form.setFieldsValue({
      options: selectedOptions,
    });

    props.onClose();
  };

  return (
    <>
      <Drawer
        onClose={props.onClose}
        visible={props.visible}
        title={ADD_MODIFIER}
        width="40%"
        destroyOnClose
        placement="right"
        className="dietary-modal"
        footer={
          <div className="w-full flex justify-end py-4 mt-auto">
            <Button size="large" color="black" onClick={handleAddOption}>
              Add
              <CheckCircle size={24} className="ml-10" />
            </Button>
          </div>
        }
      >
        <div className="flex items- px-7 py-5 min-h-full flex-col">
          <Field
            variant="curved"
            prefix={<Search />}
            value={query}
            suffix={<X className="cursor-pointer" onClick={() => setQuery("")} />}
            placeholder={PLACEHOLDER_SEARCH_MODIFIER}
            onChange={(event) => setQuery(event.target.value)}
            className="mt-0 mb-9"
          />
          {data?.length ? (
            <Accordion
              className="pl-12 bg-transparent"
              expandAll={true}
              accordion={false}
              items={data
                .filter((item) => item.title.toLowerCase().includes(query.toLowerCase()))
                .map((option: Option) => {
                  return {
                    header: (
                      <div className="relative flex items-center">
                        <Checkbox
                          id={option.id}
                          checked={!!selectedOptions?.find((selectedItem) => selectedItem.id == option.id)}
                          className="absolute -left-12 leading-0"
                          onChange={(e) => handleSelectedOption(e, option.id)}
                        />
                        {option.title}
                      </div>
                    ),
                    content: option.variants.map((variant, index) => {
                      return (
                        <div key={index} className="w-full flex justify-between px-2">
                          <p className="text-m">{variant.title}</p>
                          <p className="text-m font-bold">{formatCurrency(variant.price)}</p>
                        </div>
                      );
                    }),
                  };
                })}
            />
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </Drawer>
    </>
  );
};

export default AddModifiersDrawer;
