import Button from "components/form/Button";
import * as React from "react";
import { CheckCircle } from "react-feather";

type Props = {
  isLoading: boolean;
  onSave: () => void;
};

const AppHomepageFooter: React.FC<Props> = (props) => {
  return (
    <div>
      <div className={"bg-gradient-to-t from-white fixed bottom-0 w-full h-21"}>
        <Button
          size="large"
          color="black"
          htmlType="submit"
          onClick={props.onSave}
          className="bottom-4 right-4 fixed"
          loading={props.isLoading && { delay: 1000 }}
          disabled={props.isLoading}
        >
          Save
          <CheckCircle size={24} className="ml-10" />
        </Button>
      </div>
    </div>
  );
};

export default AppHomepageFooter;
