import * as React from "react";
import { FormInstance } from "antd";

import NoStructureTips from "../NoStructureTips";
import Title from "components/common/Title";
import AddBannerDrawer from "components/AddBannerDrawer";
import BannerItems from "components/BannerItems";
import BannerDrawer from "../BannerDrawer";
import { BannerItem } from "fetchers/banner";
import { BANNER_SECTION_BANNERS } from "constants/strings";

type Props = {
  form: FormInstance;
};

const Structure: React.FC<Props> = (props) => {
  const [visible, setVisible] = React.useState(false);
  const [selectedBanner, setSelectedBanner] = React.useState<BannerItem | null>(null);
  // Index is needed for new items which cannot be indexed by itemId.
  const [selectedBannerIndex, setSelectedBannerIndex] = React.useState<number | null>(null);

  return (
    <div className="mb-6">
      <Title size="large" className="mb-6 w-full items-center">
        <div className="flex items-center mr-auto">{BANNER_SECTION_BANNERS}</div>
        <AddBannerDrawer
          onSave={(values) => {
            const bannerItems = props.form.getFieldValue("bannerItems") || [];
            bannerItems.push(values);
            props.form.setFieldsValue({
              bannerItems: [...bannerItems],
            });
          }}
          onDelete={(banner) => {
            const bannerItems = props.form.getFieldValue("bannerItems") || [];
            bannerItems.remove(banner);
            props.form.setFieldsValue({
              bannerItems: [...bannerItems],
            });
          }}
        />
      </Title>

      <div className="mb-6">
        <BannerItems
          sortItemClassName="mb-1"
          form={props.form}
          bannerItemPath={["bannerItems"]}
          name="bannerItems"
          emptyData={<NoStructureTips />}
          onBannerItemClick={(index) => {
            const bannerItems = props.form.getFieldValue("bannerItems");
            setSelectedBanner(bannerItems[index]);
            setSelectedBannerIndex(index);
            setVisible(true);
          }}
        />
        {selectedBanner && (
          <BannerDrawer
            destroyOnClose
            style={{ transform: "none !important" }}
            banner={selectedBanner}
            isVisible={visible}
            onClose={() => setVisible(false)}
            onSave={(banner) => {
              const bannerItems = props.form.getFieldValue("bannerItems") || [];
              if (Array.isArray(bannerItems)) {
                if (
                  selectedBannerIndex != null &&
                  selectedBannerIndex >= 0 &&
                  selectedBannerIndex < bannerItems.length
                ) {
                  bannerItems[selectedBannerIndex] = banner;
                }

                props.form.setFieldsValue({
                  bannerItems: [...bannerItems],
                });
                setVisible(false);
              }
            }}
            onDelete={(banner) => {
              const bannerItems = props.form.getFieldValue("bannerItems") || [];
              if (Array.isArray(bannerItems)) {
                const updatedBannerItems = bannerItems.filter((item) => item !== banner);

                props.form.setFieldsValue({
                  bannerItems: [...updatedBannerItems],
                });
                setVisible(false);
              }
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Structure;
