import cx from "classnames";
import * as React from "react";
import { X } from "react-feather";
import { Drawer as AntDrawer, DrawerProps } from "antd";

export type Props = DrawerProps;

const Drawer: React.FC<Props> = (props) => {
  const { title, ...restProps } = props;

  return (
    <AntDrawer
      destroyOnClose={true}
      footer={props.footer}
      title={
        <div className={cx("flex items-center rounded-t-2.5xl bg-primary-black", "px-9 py-2.5 text-primary-white")}>
          <X
            size={24}
            className="cursor-pointer mr-6"
            onClick={(e: React.MouseEvent<Element>) =>
              restProps.onClose && restProps.onClose(e as React.MouseEvent<HTMLDivElement>)
            }
          />
          {title}
        </div>
      }
      {...restProps}
    />
  );
};

Drawer.defaultProps = {
  headerStyle: {},
  placement: "bottom",
  closable: false,
  height: "92vh",
};

export default Drawer;
