import * as React from "react";
import { Form as AntForm, Form, FormInstance, InputNumber } from "antd";
import { Trash2 } from "react-feather";
import { Plus } from "react-feather";

import Field from "components/form/Field";
import Button from "components/form/Button";

import Sort from "components/common/Sort";
import { Variant } from "fetchers/variant";
import removeMessage from "components/common/removeMessage";
import { FormListFieldData, FormListOperation } from "antd/lib/form/FormList";
import { LABEL_PRICE, VARIANT_TITLE } from "constants/strings";
import FormItemLabel from "components/common/FormItemLabel";

type Props = {
  form: FormInstance;
};

const Variants: React.FC<Props> = (props) => {
  const defaultVariant: Variant = {
    displaySequence: 0,
    price: 0,
  };

  return (
    <AntForm.List name="variants" initialValue={[defaultVariant]}>
      {(fields: FormListFieldData[], { add, move, remove }: FormListOperation) => {
        return (
          <>
            <Sort.Container
              items={fields.map((_, index) => ({ id: `variant-${index}` }))}
              onSortEnd={(oldIndex: number, newIndex: number) => move(oldIndex, newIndex)}
            >
              {fields.length > 0 ? (
                fields?.map((_, index) => {
                  return (
                    <Sort.Item
                      id={`variant-${index}`}
                      key={index}
                      index={index}
                      useDragHandle
                      className="items-center"
                      handleClassName="transform translate-y-1/2"
                      value={
                        <div className="flex flex-1 items-center justify-between">
                          <div className="flex flex-1 items-center">
                            <Form.Item
                              className="mb-0 w-3/5"
                              label={<FormItemLabel>{VARIANT_TITLE}</FormItemLabel>}
                              name={[index, "title"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter title!",
                                },
                              ]}
                            >
                              <Field className="border-0 mb-0" placeholder="Type the title..." type="text" />
                            </Form.Item>
                            <Form.Item
                              className="ml-4 mb-0 w-2/6"
                              label={<FormItemLabel>{LABEL_PRICE}</FormItemLabel>}
                              name={[index, "price"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter price!",
                                },
                              ]}
                            >
                              <InputNumber placeholder="Type the price..." />
                            </Form.Item>
                          </div>
                          <div
                            className="transform translate-y-1/2 -mt-2 rounded-sm p-2 bg-gray-50 cursor-pointer hover:text-red-400"
                            key={4}
                            onClick={() => {
                              removeMessage(
                                add,
                                remove,
                                index,
                                props.form.getFieldValue(["variants", index]),
                                "Variant deleted"
                              );
                            }}
                          >
                            <Trash2 />
                          </div>
                        </div>
                      }
                    />
                  );
                })
              ) : (
                <p>You don&apos;t have any variants. Add some variants.</p>
              )}
            </Sort.Container>
            <Button color="green" size="medium" className="flex mr-auto mt-4" onClick={() => add(defaultVariant)}>
              Add Variant <Plus size={24} className="ml-10" />
            </Button>
          </>
        );
      }}
    </AntForm.List>
  );
};

export default Variants;
