import * as React from "react";
import AntdImgCrop from "antd-img-crop";
import { IMAGE_QUALITY } from "constants/ints";

export type Props = {
  aspectRatio: number;
  children: JSX.Element;
};

const ImageCrop: React.FC<Props> = (props) => {
  return (
    <AntdImgCrop
      aspect={props.aspectRatio}
      quality={IMAGE_QUALITY}
      showReset={true}
      rotationSlider={true}
      modalTitle={" "}
    >
      {props.children}
    </AntdImgCrop>
  );
};

export default ImageCrop;
