import * as React from "react";

function SvgInfoIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="1em" height="1em" viewBox="0 0 15 15" fill="none" {...props}>
      <path
        d="M7.5 10V7.5m0-2.5h.006m6.244 2.5a6.25 6.25 0 11-12.5 0 6.25 6.25 0 0112.5 0z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgInfoIcon;
