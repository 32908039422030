export default [
  // {
  //   to: "/",
  //   icon: "HealthIcon",
  //   text: "Health",
  // },
  {
    to: "/",
    icon: "MenusIcon",
    text: "Menus",
  },
  {
    to: "/products",
    icon: "ProductsIcon",
    text: "Products",
  },
  {
    to: "/app",
    icon: "SmartphoneIcon",
    text: "App",
  },
  // {
  //   to: "/customers",
  //   icon: "CustomersIcon",
  //   text: "Customers",
  // },
  // {
  //   to: "/live-activity",
  //   icon: "LiveIcon",
  //   text: "Live Activity",
  // },
  // {
  //   to: "/settings",
  //   icon: "ProductsIcon",
  //   text: "Settings",
  // },
  // {
  //   to: "/towers",
  //   icon: "ProductsIcon",
  //   text: "Towers",
  // },
  {
    to: "/help",
    icon: "SupportIcon",
    text: "Help",
  },
];
