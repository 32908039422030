import * as React from "react";

function SvgAttentionIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="1em" height="1em" viewBox="0 0 15 14" fill="none" {...props}>
      <path
        d="M7.5 5.125v2.5m0 2.5h.006M6.431 1.912L1.137 10.75a1.25 1.25 0 001.07 1.875h10.587a1.25 1.25 0 001.069-1.875L8.569 1.912a1.25 1.25 0 00-2.138 0z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgAttentionIcon;
