import * as React from "react";

function SvgTickIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="1em" height="1em" viewBox="0 0 13 10" fill="none" {...props}>
      <path
        d="M11.667 1L4.333 8.333 1 5"
        stroke="#fff"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgTickIcon;
