import * as React from "react";
import { StoreValue } from "rc-field-form/lib/interface";

import Button from "components/form/Button";
import message, { globalMessages } from "components/common/message";
import { ITEM_DELETED } from "constants/strings";

export type Add = (defaultValue?: StoreValue, insertIndex?: number) => void;
export type Remove = (index: number | number[]) => void;

const removeMessage = (
  add: Add,
  remove: Remove,
  itemIndex: number,
  item: Record<string, unknown>,
  removedMessage = ITEM_DELETED
): void => {
  remove(itemIndex);
  const messageKey = `remove-item-${new Date().getTime()}`;

  message({
    type: "warning",
    content: (
      <div className="flex items-center">
        <div className="mr-4">
          <div className="font-bold text-m mb-1">{removedMessage}</div>
        </div>
        <Button
          type="outline"
          size="small"
          color="white"
          onClick={() => {
            add(item, itemIndex);
            globalMessages.destroy(messageKey);
          }}
        >
          Undo
        </Button>
      </div>
    ),
    progressBar: false,
    duration: 3,
    key: messageKey,
  });
};

export default removeMessage;
