import * as React from "react";

function SvgProductsIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 12" fill="none" {...props}>
      <path
        d="M5.667 1H16.5M5.667 6H16.5M5.667 11H16.5M1.5 1h.008M1.5 6h.008M1.5 11h.008"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgProductsIcon;
