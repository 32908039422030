import * as React from "react";
import { Eye, EyeOff, MoreVertical } from "react-feather";

import Status from "components/form/Status";
import DropdownMenu, { DropdownRef } from "components/common/DropdownMenu";
import { NEW_MENU } from "constants/strings";

type Props = {
  title?: React.ReactNode | string;
  isNew?: boolean;
  isVisible?: boolean;
  hideOverflowMenu?: boolean;
  onVisibilityChange?: (isVisible: boolean) => void;
};

const DrawerHeader: React.FC<Props> = (props) => {
  const menuItems = [
    <div
      className="flex items-center text-sky"
      key={1}
      onClick={() => {
        dropdownMenuRef.current?.close();
        props.onVisibilityChange && props.onVisibilityChange(!props.isVisible);
      }}
    >
      {props.isVisible ? <EyeOff size={20} className="mr-1" /> : <Eye size={20} className="mr-1" />} Change status to{" "}
      {props.isVisible ? "Hidden" : "Visible"}
    </div>,
  ];

  const dropdownMenuRef = React.createRef<DropdownRef>();

  return (
    <div className="w-full flex items-center justify-between" onClick={(e) => e.stopPropagation()}>
      <span className="text-xl">{props.title}</span>

      <div className="flex">
        <Status className="text-sm text-primary-white" isVisible={props.isVisible} />
        {!props.isNew && !props.hideOverflowMenu && (
          <DropdownMenu content={menuItems} withIcon className="mr-0" ref={dropdownMenuRef}>
            <MoreVertical size={20} />
          </DropdownMenu>
        )}
      </div>
    </div>
  );
};

DrawerHeader.defaultProps = {
  title: NEW_MENU,
  isNew: false,
  isVisible: false,
};

export default DrawerHeader;
