import { Form } from "antd";
import * as React from "react";

import Select from "components/form/Select";
import { useFetchVenues, Venue } from "fetchers/venue";
import FormItemLabel from "components/common/FormItemLabel";
import { LABEL_VENUE } from "constants/strings";

type Props = {
  disabled?: boolean;
  venueId?: string;
};

const VenueSelect: React.FC<Props> = (props) => {
  const { data, error } = useFetchVenues();

  if (error) return <div>failed to load</div>;
  if (!data) return <div>loading...</div>;

  const venueOptions = data.map((venue: Venue) => ({
    label: venue.name,
    value: venue.id,
  }));

  return (
    <div className="flex-1">
      <Form.Item
        className="mb-0"
        initialValue={props.venueId}
        name="venueId"
        label={<FormItemLabel>{LABEL_VENUE}</FormItemLabel>}
        rules={[
          {
            required: true,
            message: "Please select a venue!",
          },
        ]}
      >
        <Select
          showSearch
          placeholder="Select a venue"
          optionFilterProp="children"
          filterOption={(input, option) =>
            ((option?.label as unknown) as string).toLowerCase().includes(input.toLowerCase())
          }
          options={venueOptions}
          className="max-w-full"
          disabled={props.disabled}
        />
      </Form.Item>
    </div>
  );
};

export default VenueSelect;
