import * as React from "react";
import Title from "../../../../components/common/Title";
import InfoTooltip from "../../../../components/common/InfoTooltip";

const Titles: React.FC = () => {
  return (
    <div className="w-1/4">
      <h2 className="font-bold text-xl mb-10">Title with info</h2>

      <div className="flex mb-4">
        <div className="w-1/6">S</div>
        <div className="flex w-5/6 text-m text-center">
          <Title>Title section</Title>
        </div>
      </div>

      <div className="flex mb-4">
        <div className="w-1/6">M</div>
        <div className="flex w-5/6 text-m text-center">
          <Title size="medium">
            Title section
            <InfoTooltip
              title="Generic tooltip paragraph font. Make sure the tooltip background
              has the background blur property."
              className="ml-2"
            />
          </Title>
        </div>
      </div>

      <div className="flex mb-4">
        <div className="w-1/6">L</div>
        <div className="flex w-5/6 text-m text-center">
          <Title size="large">
            Title section
            <InfoTooltip
              title="Generic tooltip paragraph font. Make sure the tooltip background
              has the background blur property."
              className="ml-2"
            />
          </Title>
        </div>
      </div>
    </div>
  );
};

export default Titles;
