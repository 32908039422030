import cx from "classnames";
import * as React from "react";
import { XCircle } from "react-feather";

export type OptionalProps = {
  className?: string;
  size?: "small" | "medium";
  border?: boolean;
  shadow?: boolean;
  showCloseIcon?: boolean;
  onClose?: () => void;
  onClick?: () => void;
};

type Props = OptionalProps & {
  children: React.ReactNode;
};

const Bubble: React.FC<Props> = (props) => {
  return (
    <div
      className={cx(
        "inline-flex items-center rounded-full px-3 py-1",
        {
          "text-sm h-5.5": props.size === "small",
          "text-m h-25px": props.size === "medium",
          "border-2": props.border,
          "shadow-xs": props.shadow,
        },
        props.className
      )}
      onClick={props.onClick}
    >
      {props.children}
      {props.showCloseIcon && (
        <XCircle size={12} className="ml-2 cursor-pointer" onClick={() => props.onClose && props.onClose()} />
      )}
    </div>
  );
};

Bubble.defaultProps = {
  className: "bg-sky",
  size: "small",
  border: false,
  shadow: false,
};

export default Bubble;
