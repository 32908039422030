import * as React from "react";
import { Plus } from "react-feather";
import { Form as AntForm, FormInstance } from "antd";
import { FormListOperation, FormListFieldData } from "antd/lib/form/FormList";

import Category from "./Category";
import Sort from "components/common/Sort";
import NoCategories from "../NoCategories";
import Title from "components/common/Title";
import Button from "components/form/Button";
import { BUTTON_ADD_CATEGORY, MENU_STRUCTURE } from "constants/strings";

type Props = {
  form: FormInstance;
  onItemsUpdated?: () => void;
};

const Structure: React.FC<Props> = (props) => {
  // TODO work on the default category
  const defaultCategory = {
    displaySequence: 0,
    items: [],
    name: null,
    visible: true,
  };

  return (
    <div className="mb-6">
      <AntForm.List name="categories">
        {(fields: FormListFieldData[], { add, remove, move }: FormListOperation) => {
          fields.map((_, index) => {
            return { id: index };
          });
          return (
            <>
              <Title size="large" className="mb-6 w-full items-center">
                {MENU_STRUCTURE}
                <Button color="green" size="medium" className="flex ml-auto" onClick={() => add(defaultCategory)}>
                  {BUTTON_ADD_CATEGORY} <Plus size={24} className="ml-10" />
                </Button>
              </Title>

              <Sort.Container
                items={fields.map((_, index) => ({ id: `category-${index}` }))}
                onSortEnd={(oldIndex: number, newIndex: number) => move(oldIndex, newIndex)}
              >
                {fields.length > 0 ? (
                  fields?.map((_, index) => {
                    return (
                      <Category
                        key={index}
                        index={index}
                        form={props.form}
                        remove={remove}
                        add={add}
                        onItemsUpdated={props.onItemsUpdated}
                      />
                    );
                  })
                ) : (
                  <NoCategories />
                )}
              </Sort.Container>
            </>
          );
        }}
      </AntForm.List>
    </div>
  );
};

export default Structure;
