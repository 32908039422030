import { DrawerProps } from "antd";
import BannerDetailsForm from "components/AddBannerDrawer/BannerDetailsForm";
import Drawer from "components/common/Drawer";
import { BANNER_EDITOR } from "constants/strings";
import { BannerItem } from "fetchers/banner";
import * as React from "react";

type Props = DrawerProps & {
  banner?: BannerItem;
  isVisible: boolean;
  onClose?: () => void;
  onSave: (banner: BannerItem) => void;
  onDelete: (banner: BannerItem) => void;
};

const BannerDrawer: React.FC<Props> = (props) => {
  const { banner, isVisible, onClose, onSave, onDelete, ...restProps } = props;

  return (
    <Drawer
      height="100vh"
      width="50%"
      placement="right"
      key={banner?.id}
      title={BANNER_EDITOR}
      visible={isVisible}
      onClose={onClose}
      {...restProps}
    >
      <BannerDetailsForm bannerItem={banner} onSave={onSave} onDelete={onDelete} />
    </Drawer>
  );
};

BannerDrawer.displayName = "BannerDrawer";

export default BannerDrawer;
