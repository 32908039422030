import * as React from "react";
import { AlertTriangle, CheckCircle, Info, XCircle } from "react-feather";
import { default as antMessage, ArgsProps as MessageArgsProps, MessageType } from "antd/lib/message";

import Progress from "./Progress";

type ArgsProps = Omit<MessageArgsProps, "type" | "icon" | "key"> & {
  type: "success" | "warning" | "info";
  progressBar?: boolean;
  onCloseClick?: () => void;
  key: number | string;
};

export const globalMessages = antMessage;

export default function message({
  content,
  progressBar,
  duration,
  type,
  key,
  onCloseClick,
  ...restProps
}: ArgsProps): MessageType {
  const defaultDuration = duration == 0 || duration != null ? duration : 1.5;
  const defaultProgressBar = progressBar != undefined ? progressBar : false;

  let icon;
  switch (type) {
    case "info":
      icon = <Info size="30" />;
      break;
    case "warning":
      icon = <AlertTriangle size="30" />;
      break;
    default:
      icon = <CheckCircle size="30" />;
  }

  const handleClose = function () {
    onCloseClick && onCloseClick();
    restProps.onClose && restProps.onClose();
    antMessage.destroy(key);
  };

  const messageContent = (
    <div className="flex">
      <div className={`icon-wrapper type-${type}`}>{icon}</div>
      <div className="content">
        <div className="row">
          <div className="text-left">{content}</div>
          {duration != 0 && <XCircle size={15} className="ml-4 min-w-4 cursor-pointer" onClick={handleClose} />}
        </div>
        {defaultProgressBar && <Progress duration={defaultDuration * 1000} />}
      </div>
    </div>
  );

  return antMessage[type]({
    content: messageContent,
    duration: defaultDuration,
    showIcon: false,
    key: key,
    ...restProps,
  });
}
