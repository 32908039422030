import * as React from "react";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  DragOverEvent,
  useDroppable,
  DragStartEvent,
} from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import cx from "classnames";

export type DragAndDropContainerProps = {
  children?: React.ReactNode;
  onDragOver?: (e: DragOverEvent) => void;
  onDragEnd?: (e: DragEndEvent) => void;
  onDragStart?: (e: DragStartEvent) => void;
};

export const Container: React.FC<DragAndDropContainerProps> = (props) => {
  const sensors = useSensors(
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(PointerSensor, {
      activationConstraint: {
        // Require pointer to move by 5 pixels before activating draggable
        // Allows nested onClicks/buttons/interactions to be accessed
        distance: 5,
      },
    })
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragOver={props.onDragOver}
      onDragEnd={props.onDragEnd}
      onDragStart={props.onDragStart}
    >
      {props.children}
    </DndContext>
  );
};

export type DragAndDropSectionProps = {
  id: string;
  items: (string | number)[];
  listClassName?: string;
  children?: React.ReactNode;
};

export const Section: React.FC<DragAndDropSectionProps> = (props) => {
  const { setNodeRef } = useDroppable({ id: props.id });
  // console.log("Drag drop items: " + props.items)
  return (
    <SortableContext id={props.id} items={props.items} strategy={verticalListSortingStrategy}>
      <ul className={cx("w-full", props.listClassName)} ref={setNodeRef}>
        {props.children}
      </ul>
    </SortableContext>
  );
};

export default {
  Section,
  Container,
};
