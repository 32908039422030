import * as React from "react";
import Status from "./Statuses";
import Labels from "./Labels";
import Checkboxes from "./Checkboxes";
import Titles from "./Titles";

const Statuses: React.FC = () => {
  return (
    <>
      <div className="flex">
        <Status />
        <Labels />
        <Checkboxes />
        <Titles />
      </div>
    </>
  );
};

export default Statuses;
