import * as React from "react";
import { Form, FormInstance } from "antd";

import Field from "components/form/Field";
import Button from "components/form/Button";
import Bubble from "components/common/Bubble";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import Checkbox from "components/form/Checkbox";
import CardWithModal from "./CardWithModal";
import { DIETARY_REQUIREMENTS } from "constants/strings";

const data = [
  { name: "vegan", label: "Vegan", description: "Only plants. No meat, fish, eggs or dairy products." },
  { name: "vegetarian", label: "Vegetarian", description: "Plants, dairy products and eggs. No meat or fish" },
  { name: "Ovo-Vegetarian", label: "Ovo-Vegetarian", description: "Plants and eggs. No meat, fish or dairy products." },
  {
    name: "Lacto-Vegetarian",
    label: "Lacto-Vegetarian",
    description: "Plants and dairy products. No meat, fish or eggs.",
  },
  { name: "Pescetarian", label: "Pescetarian", description: "Plants, dairy products, eggs and fish. No meat." },
  {
    name: "Pollotarian",
    label: "Pollotarian",
    description: "Plants and poultry. No meat, fish, eggs or dairy products.",
  },
  { name: "gluten-free", label: "Gluten free", description: "No wheat, barley, rye or triticale." },
  { name: "Kosher", label: "Kosher", description: "Only food that is permissible in accordance to Jewish law." },
  { name: "Halal", label: "Halal", description: "Only food that is permissible in accordance to Islamic law" },
  { name: "Low Carbs", label: "Low Carbs" },
  { name: "High proteins", label: "High proteins" },
  { name: "Low/No Sodium", label: "Low/No Sodium" },
  { name: "Lactose free", label: "Lactose free" },
  { name: "Diabetic friendly", label: "Diabetic friendly" },
  { name: "Nut free", label: "Nut free" },
  { name: "Garlic free", label: "Garlic free" },
  { name: "Spicy free", label: "Spicy free" },
  { name: "contains-alcohol", label: "Contains Alcohol" },
  { name: "contains-nuts", label: "Contains Nuts" },
  { name: "spicy", label: "Spicy" },
];

type Props = {
  form: FormInstance;
  items?: string[];
  className?: string;
  onRemoveItem?: (index: number) => void;
};

type State = {
  selectedItems: string[];
  checkboxes: string[];
};

const DietaryCard: React.FC<Props> = (props) => {
  const [state, setState] = React.useState<State>({
    selectedItems: props.items || [],
    checkboxes: props.items || [],
  });

  const handleRemoveItem = (index: number) => {
    setState((state) => ({
      ...state,
      selectedItems: state.checkboxes.filter((_, i) => index !== i),
      checkboxes: state.checkboxes.filter((_, i) => index !== i),
    }));
  };

  const handleAddCheckboxes = () => {
    setState((state) => ({
      ...state,
      selectedItems: state.checkboxes,
    }));
  };

  React.useEffect(() => {
    props.form.setFieldsValue({
      dietary: state.selectedItems,
    });
  }, [state.selectedItems]);

  const getModalContent = () => {
    const handleChange = (event: CheckboxChangeEvent) => {
      setState((state) => ({
        ...state,
        checkboxes: event.target.checked
          ? [...state.checkboxes, event.target.value]
          : state.checkboxes.filter((item) => item !== event.target.value),
      }));
    };

    return (
      <div className="p-4 modal-dialog-height-overflow">
        {data.map((item, index) => (
          <span key={index} className="checkbox-wrapper flex-col items-start">
            <Checkbox
              value={item.name}
              checked={state.checkboxes.includes(item.name)}
              onChange={(e) => handleChange(e)}
            >
              <span className="checkbox-text">{item.label}</span>
            </Checkbox>
            <span className="ml-9 text-black-op-50 text-xs">{item.description}</span>
          </span>
        ))}
      </div>
    );
  };

  return (
    <CardWithModal
      modalTitle="Dietaries"
      className={props.className}
      modalContent={getModalContent()}
      title={<span className="flex items-center">{DIETARY_REQUIREMENTS}</span>}
      footer={({ handleClose }) => (
        <div className="flex justify-end">
          <Button
            color="black"
            className="m-4"
            size="large"
            onClick={() => {
              handleAddCheckboxes();
              handleClose();
            }}
          >
            Add
          </Button>
        </div>
      )}
    >
      <div className="flex flex-row flex-wrap">
        {state.selectedItems.map((item, index) => (
          <Bubble
            key={index}
            size="medium"
            className="whitespace-nowrap bg-grey-10 text-ms cursor-pointer my-2 mr-5"
            onClose={() => handleRemoveItem(index)}
          >
            {item}
          </Bubble>
        ))}

        <Form.Item name="dietary" hidden>
          <Field />
        </Form.Item>
      </div>
    </CardWithModal>
  );
};

export default DietaryCard;
