import * as React from "react";
import { Form as AntForm, FormInstance } from "antd";

import Settings from "./Settings";
import Structure from "./Structure";
import { Highlights } from "fetchers/highlights";

type Props = {
  highlights?: Highlights;
  isNew?: boolean;
  form: FormInstance<any>;
  venueId?: string;
  closeDrawer?: () => void;
  onDelete?: () => void;
  onSave?: (data: Highlights & { venueId: string }) => void;
};

const HighlightsSectionForm: React.FC<Props> = (props) => {
  const { form } = props;
  const [isVenueSelectionDisabled, setIsVenueSelectionDisabled] = React.useState(!props.isNew);

  const initialValues = {
    ...(props.highlights as Highlights),
  };

  return (
    <>
      <AntForm
        className="bg-primary-white flex-1 px-8 pt-8 flex flex-col h-full"
        form={form}
        onFinish={props.onSave}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        initialValues={{
          ...initialValues,
          items: initialValues.items?.map(
            (item: any) => ({ ...item.item, displaySequence: item.displaySequence } ?? [])
          ),
        }}
      >
        <Settings isNew={props.isNew} venueId={props.venueId} isVenueSelectionDisabled={isVenueSelectionDisabled} />
        <Structure
          form={form}
          onItemsUpdated={() => {
            setIsVenueSelectionDisabled(!props.isNew || form.getFieldValue(["items"]).length > 0);
          }}
        />
      </AntForm>
    </>
  );
};

export default HighlightsSectionForm;
