import * as React from "react";
import ColorBox from "./ColorBox";

const Colors: React.FC = () => {
  return (
    <>
      <section className="bg-ice-150 rounded my-4 py-5 px-4">
        <h1 className="font-bold text-2xl mb-3">Colors</h1>
        <h2 className="font-bold text-xl mb-2">Branding Primary</h2>
        <div className="flex mb-10">
          <ColorBox className="bg-black" name="Black" code="#000000" />
          <ColorBox className="bg-primary-white" name="White" code="#FFFFFF" />
        </div>
        <div className="flex mb-10">
          <h3 className="text-m font-bold">Black_OP</h3>
          <div className="flex flex-wrap ml-4">
            <ColorBox className="bg-black-op-10" name="10" code="Opacity 0.1" />
            <ColorBox className="bg-black-op-20" name="20" code="Opacity 0.2" />
            <ColorBox className="bg-black-op-30" name="30" code="Opacity 0.3" />
            <ColorBox className="bg-black-op-40" name="40" code="Opacity 0.4" />
            <ColorBox className="bg-black-op-50" name="50" code="Opacity 0.5" />
            <ColorBox className="bg-black-op-60" name="60" code="Opacity 0.6" />
            <ColorBox className="bg-black-op-70" name="70" code="Opacity 0.7" />
            <ColorBox className="bg-black-op-80" name="80" code="Opacity 0.8" />
            <ColorBox className="bg-black-op-90" name="90" code="Opacity 0.9" />
          </div>
        </div>
        <div className="flex mb-10">
          <h3 className="text-m font-bold">White_OP</h3>
          <div className="flex flex-wrap ml-4">
            <ColorBox className="bg-white-op-10" name="10" code="Opacity 0.1" />
            <ColorBox className="bg-white-op-20" name="20" code="Opacity 0.2" />
            <ColorBox className="bg-white-op-30" name="30" code="Opacity 0.3" />
            <ColorBox className="bg-white-op-40" name="40" code="Opacity 0.4" />
            <ColorBox className="bg-white-op-50" name="50" code="Opacity 0.5" />
            <ColorBox className="bg-white-op-60" name="60" code="Opacity 0.6" />
            <ColorBox className="bg-white-op-70" name="70" code="Opacity 0.7" />
            <ColorBox className="bg-white-op-80" name="80" code="Opacity 0.8" />
            <ColorBox className="bg-white-op-90" name="90" code="Opacity 0.9" />
          </div>
        </div>

        <h2 className="font-bold text-xl mb-2">UI Supportive</h2>
        <div className="flex mb-10">
          <ColorBox className="bg-fire" name="Fire" code="#E94C3D" />
          <ColorBox className="bg-sky" name="Sky" code="#20A1FE" />
          <ColorBox className="bg-charcoal" name="Charcoal" code="#9F9F9F" />
        </div>

        <h2 className="font-bold text-xl mb-2">UI Branding Secondary</h2>
        <div className="flex mb-10">
          <h3 className="text-m font-bold w-1/12">WHITE</h3>
          <div className="ml-4">
            <ColorBox
              className="bg-primary-white"
              name="White"
              code="#FFFFFF"
            />
            <ColorBox className="bg-white-10" name="10" code="10" />
            <ColorBox className="bg-white-20" name="20" code="#FFEEEE" />
            <ColorBox className="bg-white-30" name="30" code="#FFEEFF" />
          </div>
        </div>

        <div className="flex mb-10">
          <h3 className="text-m font-bold w-1/12">YELLOW</h3>
          <div className="ml-4">
            <ColorBox className="bg-yellow-10" name="10" code="#FFFFCC" />
            <ColorBox className="bg-yellow-20" name="20" code="#FFFFDD" />
            <ColorBox className="bg-yellow-30" name="30" code="#FFEECC" />
            <ColorBox className="bg-yellow-40" name="40" code="#FFEEDD" />
          </div>
        </div>

        <div className="flex mb-10">
          <h3 className="text-m font-bold w-1/12">ORANGE</h3>
          <div className="ml-4">
            <ColorBox className="bg-orange-10" name="10" code="#FFDDCC" />
            <ColorBox className="bg-orange-20" name="20" code="#FFDDDD" />
            <ColorBox className="bg-orange-30" name="30" code="#FFCCCC" />
            <ColorBox className="bg-orange-40" name="40" code="#FFCCDD" />
          </div>
        </div>

        <div className="flex mb-10">
          <h3 className="text-m font-bold w-1/12">RED</h3>
          <div className="ml-4">
            <ColorBox className="bg-red-10" name="10" code="#FFAACC" />
            <ColorBox className="bg-red-20" name="20" code="#FFBBCC" />
            <ColorBox className="bg-red-30" name="30" code="#FFAADD" />
            <ColorBox className="bg-red-40" name="40" code="#FFBBDD" />
          </div>
        </div>

        <div className="flex mb-10">
          <h3 className="text-m font-bold w-1/12">FUCHSIA</h3>
          <div className="ml-4">
            <ColorBox className="bg-fuchsia-10" name="10" code="#FFAAEE" />
            <ColorBox className="bg-fuchsia-20" name="20" code="#FFBBEE" />
            <ColorBox className="bg-fuchsia-30" name="30" code="#FFAAFF" />
            <ColorBox className="bg-fuchsia-40" name="40" code="#FFBBFF" />
          </div>
        </div>

        <div className="flex mb-10">
          <h3 className="text-m font-bold w-1/12">PURPLE</h3>
          <div className="ml-4">
            <ColorBox className="bg-purple-10" name="10" code="#CCAAFF" />
            <ColorBox className="bg-purple-20" name="20" code="#CCBBFF" />
            <ColorBox className="bg-purple-30" name="30" code="#CCAAEE" />
            <ColorBox className="bg-purple-40" name="40" code="#CCBBEE" />
          </div>
        </div>

        <div className="flex mb-10">
          <h3 className="text-m font-bold w-1/12">VIOLET</h3>
          <div className="ml-4">
            <ColorBox className="bg-violet-10" name="10" code="#CCAADD" />
            <ColorBox className="bg-violet-20" name="20" code="#CCBBDD" />
            <ColorBox className="bg-violet-30" name="30" code="#CCAACC" />
            <ColorBox className="bg-violet-40" name="40" code="#CCBBCC" />
          </div>
        </div>

        <div className="flex mb-10">
          <h3 className="text-m font-bold w-1/12">GREY</h3>
          <div className="ml-4">
            <ColorBox className="bg-grey-10" name="10" code="#CCCCCC" />
            <ColorBox className="bg-grey-20" name="20" code="#CCCCDD" />
            <ColorBox className="bg-grey-30" name="30" code="#CCDDDD" />
            <ColorBox className="bg-grey-40" name="40" code="#CCDDCC" />
          </div>
        </div>

        <div className="flex mb-10">
          <h3 className="text-m font-bold w-1/12">ORCHID</h3>
          <div className="ml-4">
            <ColorBox className="bg-orchid-10" name="10" code="#CCDDFF" />
            <ColorBox className="bg-orchid-20" name="20" code="#CCDDEE" />
            <ColorBox className="bg-orchid-30" name="30" code="#CCCCFF" />
            <ColorBox className="bg-orchid-40" name="40" code="#CCCCEE" />
          </div>
        </div>

        <div className="flex mb-10">
          <h3 className="text-m font-bold w-1/12">BLUE</h3>
          <div className="ml-4">
            <ColorBox className="bg-blue-10" name="10" code="#CCFFFF" />
            <ColorBox className="bg-blue-20" name="20" code="#CCFFEE" />
            <ColorBox className="bg-blue-30" name="30" code="#CCEEFF" />
            <ColorBox className="bg-blue-40" name="40" code="#CCEEEE" />
          </div>
        </div>

        <div className="flex">
          <h3 className="text-m font-bold w-1/12">GREEN</h3>
          <div className="ml-4">
            <ColorBox className="bg-green-10" name="10" code="#CCFFDD" />
            <ColorBox className="bg-green-20" name="20" code="#CCFFCC" />
            <ColorBox className="bg-green-30" name="30" code="#CCEEDD" />
            <ColorBox className="bg-green-40" name="40" code="#CCEECC" />
          </div>
        </div>
      </section>
    </>
  );
};

export default Colors;
