import * as React from "react";
import { UploadFile } from "antd/lib/upload/interface";
import { CheckCircle, UploadCloud } from "react-feather";
import { default as Upload, DraggerProps, RcFile } from "antd/lib/upload";

import Button from "./form/Button";
import { default as Tabs, TabPane } from "./common/Tabs";
import Modal, { Props as ModalProps } from "./common/Modal";
import ImageCrop from "./common/ImageCrop";

const Dragger = Upload.Dragger;

type Props = {
  draggerProps: Omit<DraggerProps, "action"> & { filename?: string };
  modalProps?: Omit<ModalProps, "children" | "title">;
  selectedImage?: string | null;
  activeTab?: "upload" | "stored";
  aspectRatio: number;
  onSave?: (image: string | undefined) => void;
};

type State = {
  fileList: UploadFile[];
  storedFiles: UploadFile[];
  selectedImage: Props["selectedImage"];
  activeTab: Props["activeTab"];
};

/**
 * This is similar to UploadModal. Except, the image is not automatically uploaded to the backend but kept as base64.
 * The caller to this component does the uploading.
 * @param param0
 * @returns
 */
const UploadModalLocal: React.FC<Props> = ({
  modalProps,
  draggerProps,
  selectedImage,
  activeTab,
  onSave,
  aspectRatio,
}) => {
  const [state, setState] = React.useState<State>({
    fileList: draggerProps.fileList ? draggerProps.fileList : [],
    storedFiles: [],
    selectedImage,
    activeTab,
  });

  const { ...resetDraggerProps } = draggerProps;

  const handleOnSave = async () => {
    const image = state.fileList[0];
    if (image) {
      onSave && onSave(image.url);
    }
  };

  const beforeUpload = (file: RcFile) => {
    if (!file) return false;

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setState((currentState) => ({
        ...currentState,
        fileList: [{ url: reader.result } as UploadFile],
      }));
    };

    return false;
  };

  return (
    <Modal {...modalProps} title="Image manager">
      <div className="p-5 pt-2">
        <Tabs
          activeKey={state.activeTab}
          className="overflow-visible relative"
          onChange={(activeKey) =>
            setState((currentState) => ({ ...currentState, activeTab: activeKey as Props["activeTab"] }))
          }
        >
          <TabPane tab="Upload" key="upload">
            <ImageCrop aspectRatio={aspectRatio}>
              <Dragger
                fileList={state.fileList}
                listType="picture-card"
                showUploadList={{
                  showDownloadIcon: false,
                  showPreviewIcon: false,
                  showRemoveIcon: false,
                }}
                maxCount={1}
                beforeUpload={beforeUpload}
                {...resetDraggerProps}
              >
                <div className="flex flex-col items-center justify-center p-5 bg-orchid-10 hover:bg-orchid-20">
                  <UploadCloud size={70} className="mb-2.5" />
                  <div className="text-m">
                    <p className="font-bold mb-2.5">Drag and drop your file into this space</p>
                    <p>Supported files: .jpg .jpeg .png .gif</p>
                  </div>
                  <Button
                    type="outline"
                    size="medium"
                    color="black"
                    className="mt-2.5 text-black hover:text-primary-white"
                  >
                    Choose file
                  </Button>
                </div>
              </Dragger>
            </ImageCrop>
          </TabPane>
        </Tabs>

        <div className="flex">
          <Button
            color="black"
            size="large"
            className="border-primary-white mt-10 ml-auto"
            onClick={handleOnSave}
            disabled={state.fileList.length == 0}
          >
            Save
            <CheckCircle size={24} className="ml-10" />
          </Button>
        </div>
      </div>
    </Modal>
  );
};

UploadModalLocal.defaultProps = {
  draggerProps: {
    name: "files",
    maxCount: 1,
    accept: ".png, .jpg, .jpeg",
    fileList: [],
    multiple: false,
    showUploadList: {
      showDownloadIcon: false,
      showPreviewIcon: false,
      showRemoveIcon: true,
    },
    filename: "image",
  },
  selectedImage: null,
  activeTab: "upload",
};

export default UploadModalLocal;
