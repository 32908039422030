import * as React from "react";

function SvgCrossCircleIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="1em" height="1em" viewBox="0 0 15 15" fill="none" {...props}>
      <path
        d="M9.375 5.625l-3.75 3.75m0-3.75l3.75 3.75M13.75 7.5a6.25 6.25 0 11-12.5 0 6.25 6.25 0 0112.5 0z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgCrossCircleIcon;
