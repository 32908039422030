import * as React from "react";

function SvgArrowCircleLeftIcon(
  props: React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg width="1em" height="1em" viewBox="0 0 23 23" fill="none" {...props}>
      <path
        d="M11.5 7.333L7.333 11.5m0 0l4.167 4.167M7.333 11.5h8.334M11.5 1.083c5.753 0 10.417 4.664 10.417 10.417S17.253 21.917 11.5 21.917 1.083 17.253 1.083 11.5 5.747 1.083 11.5 1.083z"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgArrowCircleLeftIcon;
