import * as React from "react";
import { CornerRightUp, Star } from "react-feather";

const NoStructureTips: React.FC = () => (
  <div className="flex items-start">
    <div className="bg-orchid-40 text-center p-5 rounded-2lg flex-1 mr-6">
      <Star size="70" className="mx-auto mb-2.5" />
      <p className="mb-2.5 text-m font-bold">Create your highlight group</p>
      <p className="text-m mb-7">
        Create a group of products you wish to showcase in the mobile app homepage. We recommend to choose between 3 and
        8 items for a better user experience.
      </p>
      <div className="grid grid-cols-3 gap-5">
        <div className="flex flex-col">
          <p className="mb-2.5 text-m font-bold">Standard Highlight</p>
          <p className="mb-5 text-sm">
            It will show your selected products in an interactive slider using the Haffinity recommended size. You will
            see this component on the app homepage, as shown in the example below.
          </p>
          <img src="/img/app-standard-highlight.png" className="mx-auto mt-auto" />
        </div>
        <div className="flex flex-col">
          <p className="mb-2.5 text-m font-bold">Large Highlight</p>
          <p className="mb-5 text-sm">
            It will show your selected products in a large slider for a higher visibility. You will see this component
            on the app homepage, as shown in the example below.
          </p>
          <img src="/img/app-large-highlight.png" className="mx-auto mt-auto" />
        </div>
        <div className="flex flex-col">
          <p className="mb-2.5 text-m font-bold">Extra large Highlight</p>
          <p className="mb-5 text-sm">
            It will show your selected products in the larger slider available to maximise the visual impact. You will
            see this component on the app homepage, as shown in the example below.
          </p>
          <img src="/img/app-extra-large-highlight.png" className="mx-auto mt-auto" />
        </div>
      </div>
    </div>
    <div className="bg-purple-40 text-center p-5 rounded-2lg">
      <CornerRightUp size="70" className="mb-2.5 mx-auto" />
      <p className="mb-2.5 text-m font-bold">Add some products</p>
      <p className="text-m">
        Begin by adding some products. <br /> We recommend adding <span className="font-bold">minimum</span> <br />{" "}
        three products.
      </p>
    </div>
  </div>
);

export default NoStructureTips;
