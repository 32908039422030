import * as React from "react";

function SvgThumbsDownIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="1em" height="1em" viewBox="0 0 21 22" fill="none" {...props}>
      <path
        d="M14.583 1.833h2.448a2.118 2.118 0 012.136 1.834v6.416a2.118 2.118 0 01-2.136 1.834h-2.448M8.167 13.75v3.667a2.75 2.75 0 002.75 2.75l3.666-8.25V1.833H4.243A1.833 1.833 0 002.41 3.392l-1.265 8.25a1.833 1.833 0 001.833 2.108h5.189z"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgThumbsDownIcon;
