import * as React from "react";
import { Form, FormInstance } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

import Field from "components/form/Field";
import Button from "components/form/Button";
import Bubble from "components/common/Bubble";
import Checkbox from "components/form/Checkbox";
import CardWithModal from "./CardWithModal";
import { default as Tabs, TabPane } from "components/common/Tabs";

const data = [
  {
    title: "Dairy",
    items: [
      { name: "Dairy (generic)", label: "Dairy (generic)" },
      { name: "Milk", label: "Milk" },
      { name: "Milk powder", label: "Milk powder" },
      { name: "Cheese", label: "Cheese" },
      { name: "Butter", label: "Butter" },
      { name: "Margarine", label: "Margarine" },
      { name: "Yogurt", label: "Yogurt" },
      { name: "Cream", label: "Cream" },
      { name: "Ice cream", label: "Ice cream" },
    ],
  },
  {
    title: "Tree nuts",
    items: [
      { name: "Tree nuts (generic)", label: "Tree nuts (generic)" },
      { name: "Peanuts", label: "Peanuts" },
      { name: "Brazil nuts", label: "Brazil nuts" },
      { name: "Almonds", label: "Almonds" },
      { name: "Cashews", label: "Cashews" },
      { name: "Macadamia nuts", label: "Macadamia nuts" },
      { name: "Pistachios", label: "Pistachios" },
      { name: "Pine nuts", label: "Pine nuts" },
      { name: "Walnuts", label: "Walnuts" },
    ],
  },
  {
    title: "Animal products",
    items: [
      { name: "Eggs", label: "Eggs" },
      { name: "Red meat", label: "Red meat" },
      { name: "Pork meat", label: "Pork meat" },
      { name: "Chicken meat", label: "Chicken meat" },
      { name: "Fish", label: "Fish" },
    ],
  },
  {
    title: "Seafood",
    items: [
      { name: "Shellfish (generic)", label: "Shellfish (generic)" },
      { name: "Shrimp", label: "Shrimp" },
      { name: "Prawns", label: "Prawns" },
      { name: "Crayfish", label: "Crayfish" },
      { name: "Fish", label: "Fish" },
      { name: "Lobster", label: "Lobster" },
      { name: "Squid", label: "Squid" },
      { name: "Scallops", label: "Scallops" },
    ],
  },
  {
    title: "Others",
    items: [
      { name: "Wheat", label: "Wheat" },
      { name: "Linseed", label: "Linseed" },
      { name: "Sesame seeds", label: "Sesame seeds" },
      { name: "Peach", label: "Peach" },
      { name: "Banana", label: "Banana" },
      { name: "Avocado", label: "Avocado" },
      { name: "Kiwi fruit", label: "Kiwi fruit" },
      { name: "Passion fruit", label: "Passion fruit" },
      { name: "Celery", label: "Celery" },
      { name: "Garlic", label: "Garlic" },
      { name: "Mustard", label: "Mustard" },
      { name: "Aniseed", label: "Aniseed" },
      { name: "Chamomile", label: "Chamomile" },
    ],
  },
];

type Props = {
  items?: string[];
  form: FormInstance;
  className?: string;
};

const AllergensCard: React.FC<Props> = (props) => {
  const [state, setState] = React.useState({
    selectedItems: props.items || [],
    checkboxes: props.items || [],
  });

  const handleRemoveItem = (index: number) => {
    setState((state) => ({
      ...state,
      selectedItems: state.checkboxes.filter((_, i) => index !== i),
      checkboxes: state.checkboxes.filter((_, i) => index !== i),
    }));
  };

  const handleAddCheckboxes = () => {
    setState((state) => ({
      ...state,
      selectedItems: state.checkboxes,
    }));
  };

  React.useEffect(() => {
    props.form.setFieldsValue({
      allergens: state.selectedItems,
    });
  }, [state.selectedItems]);

  const getModalContent = () => {
    const handleChange = (event: CheckboxChangeEvent) => {
      setState((state) => ({
        ...state,
        checkboxes: event.target.checked
          ? [...state.checkboxes, event.target.value]
          : state.checkboxes.filter((item) => item !== event.target.value),
      }));
    };

    return (
      <Tabs className="p-4">
        {data.map((item, index) => (
          <TabPane key={index} tab={item.title} className="flex flex-col modal-dialog-height-overflow">
            {item.items.map((item, index) => (
              <span key={index} className="checkbox-wrapper">
                <Checkbox
                  value={item.name}
                  checked={state.checkboxes.includes(item.name)}
                  onChange={(e) => handleChange(e)}
                >
                  <span className="checkbox-text">{item.label}</span>
                </Checkbox>
              </span>
            ))}
          </TabPane>
        ))}
      </Tabs>
    );
  };

  return (
    <CardWithModal
      modalTitle="Allergens"
      className={props.className}
      modalContent={getModalContent()}
      title={<span className="flex items-center">Allergens</span>}
      footer={({ handleClose }) => (
        <div className="flex justify-end">
          <Button
            color="black"
            className="m-4"
            size="large"
            onClick={() => {
              handleAddCheckboxes();
              handleClose();
            }}
          >
            Add
          </Button>
        </div>
      )}
    >
      <div className="flex flex-row flex-wrap">
        {state.selectedItems.map((item, index) => (
          <Bubble
            key={index}
            size="medium"
            className="whitespace-nowrap bg-grey-10 text-ms cursor-pointer my-2 mr-5"
            onClose={() => handleRemoveItem(index)}
          >
            {item}
          </Bubble>
        ))}

        <Form.Item name="allergens" hidden>
          <Field />
        </Form.Item>
      </div>
    </CardWithModal>
  );
};

export default AllergensCard;
